.wishlist-options-list {
    margin: $wishlist-options-list__margin;

    &__option {
        margin: $wishlist-options-list-option__margin;
    }

    &__option-label:after {
        content: $wishlist-options-list-option-label-after__content;
    }
}
