@if $checkout-form__grid-enabled == true {
    #shipping-new-address-form {
        .street > .control {
            display: block;
        }

        & > fieldset.street {
            display: block;
            grid-column: span 2;
        }

        .tig-postnl-validation-message:empty {
            margin-bottom: 0;
        }
    }

    .billing-address-form {
        .tig-postnl-validation-message:empty {
            margin-bottom: 0;
        }
    }
}

@if $checkout-form__grid-enabled--payment == true {
    .billing-address-form .fieldset.address {
        .street > .control {
            display: block;
        }

        & > fieldset.street {
            display: block;
            grid-column: span 2;
        }
    }
}
