.newsletter-subscribe {
    &__form {
        display: block;
    }

    &__submit {
        background: $color--blue;
        font-weight: 600;
        height: 3.5rem;
        padding: 0;
        width: 100%;

        &:hover {
            background: lighten($color--blue, 5%);
        }
    }
}
