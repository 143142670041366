$cart--margin-mobile: 0 0 2rem 0 !default;
$cart--margin: 0 0 4rem 0 !default;

$cart-continue-shopping--margin: 0 !default;
$cart-continue-shopping--width: 100% !default;

$cart-items-summary-gap-width: 8rem !default;
$cart-items-summary-gap-width--xl: 12rem !default;

$cart-summary-container--width: 35rem !default;
$cart-summary-container--width--xl: 40rem !default;
$cart-summary-container--position: sticky !default;
$cart-summary-container--top: 1rem !default;
$cart-summary-container--margin: 0 0 0 0 !default;
$cart-summary-container--margin-mobile: 2rem 0 0 0 !default;

$cart-items--width: calc((100% - #{$cart-summary-container--width}) - #{$cart-items-summary-gap-width}) !default;
$cart-items--width--xl: calc(
    (100% - #{$cart-summary-container--width--xl}) - #{$cart-items-summary-gap-width--xl}
) !default;

$cart-crosssell--margin: 0 0 0 0 !default;
$cart-crosssell--margin-mobile: 0 0 0 0 !default;
