@if $checkout-form__grid-enabled == true {
    #shipping-new-address-form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: $checkout-form__grid-gap;

        .street > .control {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: $checkout-form__grid-gap;
        }

        & > div,
        & > fieldset {
            grid-column: 1 / -1;
        }

        & > fieldset {
            margin-bottom: 0;
        }

        div[name='shippingAddress.firstname'],
        div[name='shippingAddress.lastname'],
        div[name='shippingAddress.postcode'],
        div[name='shippingAddress.city'],
        .streetname {
            grid-column: span 2;
        }

        .house-number,
        .house-number-extension {
            grid-column: span 1;
        }
    }
}

@if $checkout-form__grid-enabled--payment == true {
    .billing-address-form .fieldset.address {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: $checkout-form__grid-gap;

        & > div,
        & > fieldset {
            grid-column: 1 / -1;
        }

        & > fieldset {
            margin-bottom: 0;
            padding-top: 0;
        }

        & > fieldset > .label {
            display: none;
        }

        div[name$='.firstname'],
        div[name$='.lastname'],
        div[name$='.postcode'],
        div[name$='.city'],
        div[name$='.street.0'],
        .streetname {
            grid-column: span 2;
        }

        .street > .control {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: $checkout-form__grid-gap;
        }

        .house-number,
        .house-number-extension {
            grid-column: span 1;
        }

        // By default there is a max-width on input- and select boxes
        .field input,
        .field select {
            max-width: none;
        }
    }
}