.cookie-consent {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.cookie-consent-segment {
  &__inner {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }

  &__title {
    display: block;
    font-weight: 700;
  }

  &__open {
    cursor: pointer;

    &.open {
      margin-bottom: 1.5rem;
      transform: rotate(180deg);
    }
  }
}