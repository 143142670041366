.checkout-container {
    .opc-block-summary .title {
        display: block;
    }
    .field .control._with-tooltip input {
        width: 100%;
    }

    .opc-wrapper .note {
        color: $color--default;
    }

    .actions-toolbar {
        text-align: left;

        .action-update {
            margin-bottom: 1rem;
        }
    }

    .shipping-information .action-edit {
        background-color: $color--dark-blue;
        font-weight: $font-weight__default;
        height: 4rem;
        padding: 1rem;
        margin-left: 0.5rem;

        &:hover {
            background-color: $color--white;
            border: 1px solid $color--dark-blue;
            color: $color--dark-blue;
        }
    }
}

@include min-screen($screen__l) {
    .checkout-container {
        .actions-toolbar {
            text-align: right;
        }
    }
}
