.shipping-information {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px !important;
    text-align: center;

    .shipping-information__inner {
        padding: 12px !important;
    }

    .shipping-information__inner p {
        margin: 0;
    }
}
