.contact {
    &__icon {
        fill: $color--white;
        height: 0.5rem;
        transform: rotate(180deg);
        vertical-align: middle;
    }

    &__item {
        a:hover span,
        a:active span,
        a:focus span {
            text-decoration: underline;
        }
    }
}
