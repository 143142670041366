$category-filter__background-color: transparent !default;
$category-filter__border-radius: 0.8rem !default;
$category-filter__padding: 1.5rem 1.5rem 0.5rem 1.5rem !default;

$category-filter-current__color: $text__color !default;
$category-filter-current__font-weight: $font-weight__bold !default;
$category-filter-current__margin: 0 0 1rem 0 !default;
$category-filter-current__padding: 0 1rem 0 5rem !default;

$category-filter-sub__padding: 0 1rem 0 7rem !default;
$category-filter-sub__font-weight: normal !default;
$category-filter-sub__text-decoration: none !default;

$category-filter-link__color: $text__color-link !default;
$category-filter-link__color--hover: $text__color-link--hover !default;
$category-filter-link__margin: 0 0 1rem 0 !default;

$category-tree-link__text-decoration: underline !default;
