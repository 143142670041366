.cart-discount {
    &__toggle {
        display: inline-flex;
        font-size: $font-size__xxl;
    }

    &__toggle-icon {
        fill: $color--blue;
        transform: rotate(270deg);
    }
}

@include min-screen($screen__m) {
    .cart-discount {
        margin: 0 0 4rem 0;
    }
}
