$shipping-address-item__border: 1px solid $color--grey !default;
$shipping-address-item__border-radius: $border-radius__default !default;
$shipping-address-item__padding: 1.5rem 1.5rem 1.5rem 6rem !default;
$shipping-address-item__margin-bottom: 1rem !default;

$shipping-address-item-hover__background: transparent !default;
$shipping-address-item-hover__border: 1px solid $color--black !default;

$shipping-address-item-selected__background: transparent !default;
$shipping-address-item-selected__border: 1px solid $color--black !default;
