$footer__border-top: 0;
$footer__line-height: 2rem;

$footer-top__padding: 0;

$footer-primary__background-color: $color--dark-blue;
$footer-primary__color: $color--white;
$footer-primary__padding: 0.5rem 0 1rem 0;
$footer-primary__font-weight: 300;

$footer-link__color: $color--white;

$footer-block-container__max-width: 25%;

$footer-block__margin: 1rem 0;

$footer-block-title__font-size: 1.8rem;
$footer-block-title__font-weight: 600;
$footer-block-title__margin--desktop: 2rem 0 0.5rem 0;
