.product-reviews-summary {
    $self: &;

    align-items: center;
    display: flex;

    &__rating-summary {
        margin-right: 1rem;
    }

    &__add-review {
        display: inline-block;
        margin-left: 1rem;
    }
}

.product-page__header {
    .product-reviews-summary__full-view {
        display: none;
    }
}

#reviews {
    .product-reviews-summary__short-view {
        display: none;
    }

    .product-reviews-summary__full-view {
        display: block;
    }
}
