$product-grid__display--mobile: flex !default;
$product-grid__flex-direction--mobile: column !default;
$product-grid__margin--mobile: 0 0 1rem 0 !default;

$product-grid__display: grid !default;
$product-grid__grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr)) !default;
$product-grid__grid-template-columns--vertical: repeat(auto-fill, minmax(100%, 1fr)) !default;
$product-grid__grid-gap: 2rem !default;
$product-grid__grid-gap--vertical: 1rem !default;
$product-grid__margin: 0 0 0 0 !default;
