.product-grid {
    grid-auto-flow: row;

    &__actions {
        text-align: center;
    }

    &__button {
        margin: 3.4rem 0 0 0;
    }
}
