.checkout-products {
    margin: $checkout-products__margin;

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-bottom: $checkout-products-item__border;

        &:last-child {
            border-bottom: $checkout-products-item__border--last-child;
        }
    }
}
