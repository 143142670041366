.homepage {
    &__item,
    &__category-list {
        @include page-alignment();
    }

    &__banner,
    &__secondary-banners {
        max-width: 100%;
        padding: 0;
    }

    &__secondary-banners {
        background: $color--light-blue;
    }

    &__product-list {
        padding-top: 2rem;
        padding-bottom: 6rem;
    }

    @include min-screen($screen__m) {
        .usps {
            display: none;
        }
    }

    @include max-screen($screen__m) {
        .usps {
            max-height: 15rem;
            padding: 0.5rem 0 2rem 0;

            &__item {
                padding: 0.8rem 0;
            }

            &__label {
                line-height: 1.5;
            }
        }
    }
}
