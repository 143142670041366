.cart-discount {
    $self: &;

    background: $cart-discount__background;
    border-bottom: $cart-discount--border-bottom;
    border-top: $cart-discount--border-top;
    padding: $cart-discount__padding;
    margin: $cart-discount--margin;

    &__toggle {
        color: $cart-discount__toggle--color;
        display: $cart-discount__toggle--display;
        align-items: $cart-discount__toggle--align-items;
        margin: $cart-discount__toggle--margin;
        font-weight: $cart-discount__toggle--font-weight;

        &:hover,
        &:focus {
            color: $cart-discount__toggle--color;
            outline: none;
            text-decoration: none;
        }
    }

    &__toggle-icon {
        align-items: center;
        background-color: $cart-discount-toggle-icon__background-color;
        border-radius: $cart-discount-toggle-icon__border-radius;
        color: $cart-discount-toggle-icon__color;
        display: inline-flex;
        justify-content: center;
        height: $cart-discount-toggle-icon__height;
        margin-left: $cart-discount-toggle-icon__margin-left;
        margin-right: $cart-discount-toggle-icon__margin-right;
        width: $cart-discount-toggle-icon__width;
        pointer-events: none;

        &--plus {
            display: inline;
        }

        &--minus {
            display: none;
        }
    }

    &.active {
        #{$self}__toggle-icon {
            &--plus {
                display: none;
            }

            &--minus {
                display: inline;
            }
        }
    }

    &__content {
        display: none;
    }

    &__fieldset {
        align-items: $cart-discount-fieldset__align-items;
        display: $cart-discount-fieldset__display;
        justify-content: $cart-discount-fieldset__justify-content;
        padding: $cart-discount-fieldset__padding;
    }

    &__input {
        margin-right: $cart-discount-input__margin-right;
        max-width: $cart-discount-input__max-width;
    }
}

@include min-screen($screen__m) {
    .cart-discount {
        $self: &;

        // To override...
        #{$self} &__button.action {
            margin-bottom: 0;
        }

        &__input {
            margin-right: $cart-discount-input__margin-right--desktop;
        }
    }
}
