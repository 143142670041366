/**
  * General button settings
 */
$button__padding: 0 3rem;

/*
 * Primary button settings
 */
$button-primary__background: $color--orange;
$button-primary__border-radius: 3px;
$button-primary__padding: 2.5rem 3rem;
$button-primary-hover__background: $color--dark-orange;

/*
 * Secondary button settings
 */
$button-secondary__background: $color--blue;
$button-secondary__border-radius: 3px;
$button-secondary__padding: $button__padding;
$button-secondary-hover__background: $color--dark-blue;

$button-secondary__font: $font-family__default;
$button-secondary__height: 6rem;

/**
 * Primary button settings
 */
$button__background: $color--orange;
$button__border-radius: 0.3rem;
$button__height: 6rem;
$button-hover__background: $color--dark-orange;
