.block {
    background: $block__background;
    border: $block__border;
    border-radius: $border-radius__default;
    margin-bottom: $block__margin-bottom;
    padding: $block__padding;

    .block-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $block-title__margin-bottom;
    }

    .block-title strong {
        font-size: $block-title__font-size;
        font-weight: $block-title__font-weight;
        line-height: $block-title__line-height;
    }

    &--highlight {
        background: $block__background--highlight;
        border: $block__border--highlight;
        color: $block__color--highlight;

        a {
            display: inline-block;
            margin: $block-link__margin--highlight;
        }
    }

    &--space-between {
        display: flex;
        justify-content: space-between;
    }

    .field {
        margin: $block-field__margin;
    }

    .box,
    .addresses .item {
        border-bottom: $block-box__border-bottom;
        margin-bottom: $block-box__margin-bottom;
        padding-bottom: $block-box__padding-bottom;

        &:last-child {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .box-title {
    }

    .box-content {
    }

    .box-actions,
    .addresses .actions {
        margin-top: $block-action__margin-top;

        a + a {
            border-left: $block-action-separator__border;
            margin-left: $block-action-separator__spacing;
            padding-left: $block-action-separator__spacing;
        }

        a.delete {
            color: $block-action-delete__color;
        }
    }

    &__back {
        margin-top: $block-back__margin-top;
    }
}
