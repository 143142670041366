.social {
    $self: &;
    padding: $social__padding;

    &__title {
        color: $social-title__color;
        font-size: $social-title__font-size;
        line-height: $social-title__line-height;
        font-weight: $social-title__font-weight;
        margin: $social-title__margin;
        text-align: $social-title__text-align;
    }

    &__list {
        display: flex;
        justify-content: $social-list__justify-content;
    }

    &__item {
        align-items: center;
        background: $social-item__background;
        border-radius: $social-item__border-radius;
        display: flex;
        height: $social-item__height;
        justify-content: center;
        margin-right: $social-item__spacing;
        width: $social-item__width;
        position: $social-item__position;

        &:hover {
            background: $social-item__background--hover;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon {
        fill: $social-icon__fill;
        height: auto;
        width: $social-icon__size;
        position: $social-icon__position;
        bottom: $social-icon__bottom;

        #{$self}__item:hover & {
            fill: $social-icon__fill--hover;
        }
    }
}

@include min-screen($screen__m) {
    .social {
        &__title {
            text-align: $social-title__text-align--desktop;
        }

        &__list {
            justify-content: $social-list__justify-content--desktop;
        }
    }
}
