.product-page {
    &__content-secondary {
        grid-column: 2;
        grid-row: 3;
    }

    &__summary {
        display: flex;
        flex-direction: column;

        .h1 {
            line-height: 3.5rem;
        }
    }

    &__add-to-cart {
        .table.grouped {
            .control .qty {
                width: 120px;
            }
        }
    }

    &__block {
        border-bottom: none;
        padding-bottom: 0;
        margin-top: 0;
    }

    &__price {
        .price-final_price {
            font-size: 3.5rem;
            #product-price-50063 {
                padding-bottom: 0.5rem;
            }
        }
        strong {
            color: rgb(19, 162, 61);
        }
    }
}

.product-page {
    &__title--small-screen {
        order: 1;
    }
    &__gallery {
        order: 2;
    }
    &__stock {
        order: 3;
    }
    &__add-to-cart {
        order: 4;
    }
    &__add-to {
        order: 5;
    }
}

.product-attributes {
    &__block {
        margin-top: 0;
    }
}

.product-stock-status {
    display: flex;
    align-items: center;

    .font-bold {
        font-weight: bold;
    }
}

.product-stock-status.available {
    svg {
        fill: $color--green;
    }

    span {
        color: $color--green;
        padding-left: 0.5rem;
    }
}

.product-stock-status.backorder {
    svg {
        background-color: $color--blue;
        fill: white;
        padding: 3px;
        border-radius: 999px;
        transform: rotate(180deg);
    }

    span {
        color: $color--blue;
        padding-left: 0.5rem;
    }
}

.product-stock-status.unavailable {
    svg {
        background-color: $color--red;
        fill: white;
        padding: 3px;
        border-radius: 999px;
    }

    span {
        color: $color--red;
        padding-left: 0.5rem;
    }
    .catalog-product-view .amshopby-option-link {
        margin-top: 4rem;
    }
}

@include min-screen($screen__xl) {
    .product-page {
        &__summary {
            /* prevent pagejump after galery load on dekstop */
            min-height: 30rem;
        }
    }
}

@include max-screen($screen__m) {
    .catalog-product-view .page-title--catalog-product-view {
        display: none;
    }

    .product-page {
        &__gallery {
            order: 3;
        }
        &__stock {
            order: 2;
        }
    }
}
