.ec-gtm-cookie-directive {
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: inline-flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}

.ec-gtm-cookie-directive > div {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 30px;
    color: #000;
    max-height: 94vh;
    max-width: 90vw;
    overflow-y: auto;
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.19);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.19);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background-clip: padding-box;
}

.ec-gtm-cookie-directive > div > div {
    float: left;
    min-width: 300px;
}

.ec-gtm-cookie-directive > div > div > div:before,
.ec-gtm-cookie-directive > div > div > div:after {
    content: " ";
    display: table;
}

.ec-gtm-cookie-directive > div > div > div:after {
    clear: both;
}

.ec-gtm-cookie-directive > div > div > div h4 {
    margin-top: 0;
}

.ec-gtm-cookie-directive > div > div > div p {
    font-size: 1.3rem;
    line-height: 1.6;
}

.ec-gtm-cookie-directive > div > div > div a {
    cursor: pointer;
    color: #8e8e8e;
}

.ec-gtm-cookie-directive > div > div > div a.action {
    font-size: 1.4rem;
    height: 4rem;
    margin-bottom: 0.4rem;
    padding: 0 2rem;
}

.ec-gtm-cookie-directive > div > div > div a.action.accept,
.ec-gtm-cookie-directive > div > div > div a.action.accept-all,
.ec-gtm-cookie-directive > div > div > div a.action.accept:hover,
.ec-gtm-cookie-directive > div > div > div a.action.accept-all:hover {
    color: #fff !important;
}

.ec-gtm-cookie-directive > div > div > div a.action.decline {
    border: 1px solid #c5c5c5;
    background: #fff;
    color: #454545;
    font-weight: 400;
}

.ec-gtm-cookie-directive > div > div > div a.action.decline:hover {
    background-color: #f0f1f3;
}

.ec-gtm-cookie-directive-segments {
    background: #fff;
    margin: 5px 0 10px;
}

.ec-gtm-cookie-directive-segments > div {
    background-color: rgb(240, 241, 243);
    border-radius: 0.375rem;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin-bottom: 0.5rem;
    padding: 0.75rem;

    .col-span-11 {
        grid-column: span 11 / span 11;
    }

    .col-span-12 {
        grid-column: span 12 / span 12;
    }
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"] {
    display: none;
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"] + label {
    display: block;
    border: 0;
    font-size: 1.3rem;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"] + label:after {
    background-color: #fff;
    border: 1px solid #2e8bc1 !important;
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"]:checked + label:after {
    background-color: #2e8bc1;
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"] + label:before {
    background: none;
    border: 0 !important;
    content: '';
}

.ec-gtm-cookie-directive-segments > div input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
}

.ec-gtm-cookie-directive-note > div {
    display: block;
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 0 60px 5px 35px;
}

.ec-gtm-cookie-directive > div > div > div .ec-gtm-cookie-directive-note-toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 2.4rem;
    width: 2.4rem;

    svg {
        pointer-events: none;
    }
}

.ec-gtm-cookie-directive > div > div > div .ec-gtm-cookie-directive-note {
    display: none;
}

.ec-gtm-uuid-wrapper {
    display: inline;
    float: right;
    height: 0;
    opacity: 0;
    width: 0;
}

.ec-gtm-cookie-directive .action.refuse {
    display: block;
    margin-top: 1rem;
}

.ec-gtm-cookie-directive .action.refuse:hover {
    text-decoration: underline;
}

main .ec-gtm-cookie-directive {
    position: relative;
}

main .ec-gtm-cookie-directive > div {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    max-width: 100%;
    padding: 0;
}

main .ec-gtm-cookie-directive-segments {
    margin: 10px 0 20px 0;
    background: none;
    padding: 0;
}

@media (min-width: 768px) {
    .ec-gtm-cookie-directive > div {
        max-width: 600px;
    }

    .ec-gtm-cookie-directive > div > div {
        min-width: 500px;
    }

    main .ec-gtm-cookie-directive {
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
    .ec-gtm-cookie-directive > div > div {
        float: left;
        min-width: 200px;
    }
}
