.shipping-information {
    &__title {
        font-size: $shipping-information-title__font-size;
        font-weight: $shipping-information-title__font-weight;
        margin: $shipping-information-title__margin;
    }

    &__content {
        margin: $shipping-information-content__margin;
    }

    &__edit {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    /**
     * Make a telephone number in the shipping information not a link,
     * because it's the phone number of the customer
     **/
    a[href^='tel:'] {
        color: $color--default;
        cursor: default;
        pointer-events: none;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: currentColor;
        }
    }
}
