.text-block {
    background-color: $text-block__background;
    padding: $text-block__padding;
}

@include min-screen($screen__m) {
    .text-block {
        padding: $text-block__padding--desktop;
    }
}
