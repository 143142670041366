.product-slider {
    $self: &;

    &__title {
        margin: 2.5rem 0 2rem 0;
    }

    &__items {
        margin-top: 1rem;
        width: 100%;
    }

    &__item {
        padding: 1rem 2rem;
        width: 100%;

        &:not(:first-child) {
            border-left: 1px solid $color--light-grey;
        }
    }

    &__additional-items {
        margin-top: 4rem;
    }
}
