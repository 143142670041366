$header-item__background: $color--white;
$header-item__border-radius: 0;
$header-item__height: 3.6rem;
$header-item__width: 2.3rem;
$header-item__height--desktop: 5.6rem;
$header-item__width--desktop: 5.3rem;
$header-item__margin: 0 0 0 1rem;
$header-item__margin--desktop: 0 0 0 2rem;

$header-item-icon__fill: $color--blue;
$header-item-icon__height: 2.2rem;
$header-item-icon__height--desktop: 3rem;

$header-item-label__show: false;

$header-item-qty__background: $color--orange;
$header-item-qty__border: 2px solid $color--white;
$header-item-qty__border-radius: 50%;
$header-item-qty__color: $color--white;
$header-item-qty__font-size: $font-size__default;
$header-item-qty__font-weight: $font-weight__bold;
$header-item-qty__height: 3.1rem;
$header-item-qty__offset-right: -1.75rem;
$header-item-qty__offset-top: -1rem;
$header-item-qty__position: absolute;

$header-item-qty__font-size--desktop: $font-size__default;
$header-item-qty__font-weight--desktop: $font-weight__bold;
$header-item-qty__height--desktop: 3.1rem;
$header-item-qty__offset-right--desktop: -1rem;
$header-item-qty__offset-top--desktop: -0.2rem;
