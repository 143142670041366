.header-item {
    $self: &;

    &__link {
        align-items: center;
        background: transparent;
        border-radius: $header-item__border-radius;
        display: flex;
        height: $header-item__height;
        justify-content: flex-end;
        margin: $header-item__margin;
        position: relative;
        width: $header-item__width;

        &:before {
            display: none !important;
        }
    }

    &__icon {
        fill: $header-item-icon__fill;
        height: $header-item-icon__height;
        width: auto;
    }

    &--minicart {
        #{$self}__number {
            display: block;
            text-align: center;
        }
    }

    &__qty {
        align-items: center;
        background: $header-item-qty__background;
        border: $header-item-qty__border;
        border-radius: $header-item-qty__border-radius;
        color: $header-item-qty__color;
        display: flex;
        font-size: $header-item-qty__font-size;
        font-weight: $header-item-qty__font-weight;
        height: $header-item-qty__height;
        justify-content: center;
        position: $header-item-qty__position;
        right: $header-item-qty__offset-right;
        top: $header-item-qty__offset-top;
        width: $header-item-qty__height;

        &.empty {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .header-item {
        &__link {
            height: $header-item__height--desktop;
            justify-content: center;
            padding-top: 1rem;
            width: $header-item__width--desktop;
        }

        &__qty {
            font-size: $header-item-qty__font-size--desktop;
            font-weight: $header-item-qty__font-weight--desktop;
            height: $header-item-qty__height--desktop;
            right: $header-item-qty__offset-right--desktop;
            top: $header-item-qty__offset-top--desktop;
            width: $header-item-qty__height--desktop;

            &.empty {
                display: none;
            }
        }

        &__icon {
            height: $header-item-icon__height--desktop;
        }
    }
}
