$short-summary__font-size: 2rem !default;
$short-summary__padding: 2rem 0 !default;

$short-summary-label__color: $color--default !default;

$short-summary-price__color: $color--dark-blue !default;

$short-summary-show-more__border-radius: 50% !default;
$short-summary-show-more__background: $color--dark-blue !default;
$short-summary-show-more__color: $color--white !default;
$short-summary-show-more__font-size: 1.5rem !default;
$short-summary-show-more__font-weight: $font-weight__bold !default;
$short-summary-show-more__height: 2rem !default;
$short-summary-show-more__width: 2rem !default;
