.data.table.table-totals,
.data.table.totals,
.data.table.table-order-items tfoot {
    border-bottom: $table-totals__border-bottom;

    th,
    td,
    tbody tr:first-child td {
        border-bottom: $table-totals__border-bottom;
        font-weight: $table-totals__font-weight;
        padding: 0 0 $table-totals__spacing 0;
    }

    td,
    .amount {
        color: $table-totals-price__color;
        text-align: right;
    }

    td .price.free {
        color: $table-totals-price__color-free;
    }

    tr:nth-last-child(2) {
        th,
        td {
            padding-bottom: $table-totals__spacing * 3;
        }
    }

    .grand.totals,
    .grand_total {
        th,
        td {
            padding-top: $table-totals__spacing * 3;
            border-top: $table-totals-grand-total__border-top;
            font-size: $table-totals-grand-total__font-size;
        }
    }
}

.data.table.table-order-items {
    border-bottom: 0;

    tfoot .subtotal th,
    tfoot .subtotal td {
        padding-top: $table-totals__spacing * 3;
    }
}
