$checkout-container-block__padding--desktop: 0;
$checkout-container-block__border: none;

$checkout-container-block-sidebar__border: none;
$checkout-container-block-sidebar__border--desktop: none;
$checkout-container-block-sidebar__background: $color--light-blue;
$checkout-container-block-sidebar__background--desktop: $color--light-blue;

$checkout-container-block-sidebar__border-radius: 0.3rem;
$checkout-container-block-sidebar__border-radius--desktop: 0.3rem;

$checkout-container-block__spacing--desktop: 3rem;

$checkout-container-title__font-size: 2.4rem;
