$authentication-login-button__background-color: transparent;
$authentication-login-button__color: $color--black;
$authentication-login-button__font-weight: $font-weight__default;
$authentication-login-button__border-radius: 0.4rem;

$authentication-login-button__background-color--active: transparent;
$authentication-login-button-icon__fill: $color--black;

.authentication-login-button {
    $self: &;

    align-items: center;
    background: $authentication-login-button__background-color;
    color: $authentication-login-button__color;
    // display: flex;
    display: none;
    font-weight: $authentication-login-button__font-weight;
    padding: 0.2rem;
    position: absolute;
    right: 0;
    top: -6rem;
    z-index: 2;

    &:hover,
    &:active,
    &:focus {
        background: $authentication-login-button__background-color--active;
    }

    #{$self}__label {
        margin-right: 1rem;
        padding: 0 1rem;
    }

    #{$self}__icon-wrapper {
        align-items: center;
        border-radius: $authentication-login-button__border-radius;
        display: flex;
        height: 3.8rem;
        justify-content: center;
        width: 3.8rem;
    }

    #{$self}__icon-login {
        fill: $authentication-login-button-icon__fill;
        height: 2.4rem;
        margin-right: 0;
        width: auto;
    }
}

@include min-screen($screen__m) {
    .authentication-login-button {
        $self: &;

        top: -7rem;
    }
}
