/**
 * Default Product List: .product-list,
 * Algolia Product List: .ais-hits
 */

$product-list__items-per-row-calculation--xxl: $product-list__items-per-row--xxl - 1;

.product-list,
.ais-hits {
    display: flex;
    flex-direction: $product-list__direction--xs;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    &__item,
    &--item {
        width: calc(
            (100% - (#{$product-list__gap-x} * (#{$product-list__items-per-row--s} - 1))) / #{$product-list__items-per-row--s}
        );
        margin-bottom: $product-list__gap-y;
        margin-right: $product-list__gap-x;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    &--full-width {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;

        .product-list__item,
        .product-list--item {
            min-width: 20rem;
            padding: $product-list-item__padding;
            scroll-snap-align: start;
            width: calc(
                (100% - (#{$product-list__gap-x} * (#{$product-list__items-per-row-calculation--xxl}))) / #{$product-list__items-per-row--xxl}
            );

            &:nth-child(#{$product-list__items-per-row-calculation--xxl}n) {
                margin-right: $product-list__gap-x;
            }

            &:nth-child(#{$product-list__items-per-row--xxl}n) {
                margin-right: 0;
            }
        }
    }
}

@include min-screen($screen__s) {
    $product-list__items-per-row-calculation--m: $product-list__items-per-row--m - 1;

    .product-list,
    .ais-hits {
        flex-direction: $product-list__direction--s;

        &__item,
        &--item {
            margin-bottom: $product-list__gap-y__s;
            margin-right: $product-list__gap-x__s;
            width: calc(
                (100% - (#{$product-list__gap-x__s} * (#{$product-list__items-per-row-calculation--m}))) / #{$product-list__items-per-row--m}
            );

            &:nth-child(#{$product-list__items-per-row-calculation--m}n) {
                margin-right: $product-list__gap-x__s;
            }

            &:nth-child(#{$product-list__items-per-row--m}n) {
                margin-right: 0;
            }
        }
    }
}

@include min-screen($screen__l) {
    $product-list__items-per-row-calculation--l: $product-list__items-per-row--l - 1;

    .product-list,
    .ais-hits {
        flex-direction: row;

        &__item,
        &--item {
            margin-bottom: $product-list__gap-y__l;
            width: calc(
                (100% - (#{$product-list__gap-x__l} * (#{$product-list__items-per-row-calculation--l}))) / #{$product-list__items-per-row--l}
            );

            &:nth-child(n) {
                margin-right: $product-list__gap-x__l;
            }

            &:nth-child(#{$product-list__items-per-row-calculation--l}n) {
                margin-right: $product-list__gap-x__l;
            }

            &:nth-child(#{$product-list__items-per-row--l}n) {
                margin-right: 0;
            }
        }
    }
}

@include min-screen($screen__xl) {
    $product-list__items-per-row-calculation--xl: $product-list__items-per-row--xl - 1;

    .product-list,
    .ais-hits {
        flex-direction: row;

        &__item,
        &--item {
            margin-bottom: $product-list__gap-y__xl;
            width: calc(
                (100% - (#{$product-list__gap-x__xl} * (#{$product-list__items-per-row-calculation--xl}))) / #{$product-list__items-per-row--xl}
            );

            &:nth-child(n) {
                margin-right: $product-list__gap-x__xl;
            }

            &:nth-child(#{$product-list__items-per-row-calculation--xl}n) {
                margin-right: $product-list__gap-x__xl;
            }

            &:nth-child(#{$product-list__items-per-row--xl}n) {
                margin-right: 0;
            }
        }
    }
}

/* Disabled scroll-snap on iOS devices for the time being, because it was causing glitches */
@supports (-webkit-overflow-scrolling: touch) {
    .product-list {
        $self: &;

        &--full-width {
            scroll-snap-type: none;
            scroll-snap-points-x: 0;

            #{$self}__item {
                scroll-snap-align: none;
            }
        }
    }
}
