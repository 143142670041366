@include max-screen($screen__m) {
    .account {
        .column.main {
            display: block;
        }

        .sidebar-main {
            display: none;
        }
    }

    .account.customer-account-index {
        .column.main {
            display: none;
        }

        .sidebar-main {
            display: block;
        }
    }
}
