$sidedrawer-overlay__background: rgba($color--black, 0.6);

$primary-navigation__link-color: $color--dark-blue;
$primary-navigation__font-size: 1.9rem;
$primary-navigation__font-weight: $font-weight__bold;
$primary-navigation-link__padding: 0 1rem 1rem;
$primary-navigation__color--active: $color--blue;
$primary-navigation__color--attention: $color--dark-orange;
$primary-navigation__color--attention--hover: $color--orange;
$primary-navigation__color--hover: $color--blue;
$primary-navigation__font-weight--active: $font-weight__bold;
$primary-navigation__active-border: 6px solid $color--orange;

// Active link
$primary-navigation__background--active: transparent;
$primary-navigation-link__background--after--active: $color--orange;
$primary-navigation-link__bottom--after--active: 0;
$primary-navigation-link__display--after--active: flex;
$primary-navigation-link__height--after--active: 6px;
$primary-navigation-link__width--after--active: calc(100% - 1rem);
$primary-navigation-link__z-index--after--active: 2;

$secondary-wrapper__padding: 2rem;
$secondary-wrapper__top: 5rem;
$secondary-wrapper__background: $color--white;

$secondary-list__grid-gap: 0;
$secondary-list__grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
$secondary-list__grid-template-rows: 4rem 4rem 4rem 4rem 4rem;
$secondary-navigation-link__color: $color--default;
$secondary-navigation-link__font-weight: $font-weight--bold;
$secondary-navigation-link__padding-bottom: 0;
$secondary-navigation-link__color--active: $color--orange;
$secondary-navigation-link__font-weight--active: $secondary-navigation-link__font-weight;

$navigation-toggle-icon__fill: $color--blue;
$navigation-toggle-icon__height: 3rem;

$sidedrawer__background: $color--white;
$sidedrawer-header-icon__color: $color--blue;
$sidedrawer-pages-list__background: $color--white;
$sidedrawer-category-list__padding: 0 2rem;
$sidedrawer-category-arrow__height: 1.2rem;
$sidedrawer-category-link__padding: 0.5rem 0;

// Overlay
$navigation-overlay__color: rgba($color--black, 0.6);
$navigation-overlay__z-index: 0;
$navigation-overlay__opacity: 0.5;
