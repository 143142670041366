@include min-screen($screen__m) {
    nav.navigation {
        height: 4.9rem;
    }

    .navigation {
        $self: &;

        &__list {
            width: 100%;
            justify-content: space-between;
        }

        &__item {
            height: 4.9rem;
            margin: 0;
            padding: 0;

            &.is-current,
            &.is-active {
                #{$self}__link {
                    background: $primary-navigation__background--active;
                    color: $primary-navigation__color--active;
                    font-weight: $primary-navigation__font-weight--active;
                    position: relative;

                    &:after {
                        content: '';
                        background-color: $primary-navigation-link__background--after--active;
                        bottom: $primary-navigation-link__bottom--after--active;
                        display: $primary-navigation-link__display--after--active;
                        height: $primary-navigation-link__height--after--active;
                        position: absolute;
                        width: $primary-navigation-link__width--after--active;
                        z-index: $primary-navigation-link__z-index--after--active;
                    }

                    &:hover {
                        color: $primary-navigation__color--hover;

                        &.attention {
                            color: $primary-navigation__color--attention--hover;
                        }
                    }
                }
            }

            &.is-current {
                .attention {
                    color: $primary-navigation__color--attention--hover;
                }

                #{$self}__link {
                    &:after {
                        background-color: transparent;
                    }

                    &:hover:after {
                        content: '';
                        background-color: $primary-navigation-link__background--after--active;
                    }
                }
            }
        }

        &__link {
            align-items: center;
            color: $primary-navigation__link-color;
            display: flex;
            font-size: $primary-navigation__font-size;
            font-style: italic;
            font-weight: $primary-navigation__font-weight;
            height: 100%;
            padding: $primary-navigation-link__padding;
            position: relative;
            text-transform: uppercase;

            &:first-child {
                padding-left: 0;
            }

            &:hover {
                background: $primary-navigation__background--active;
                color: $primary-navigation__color--hover;
                font-weight: $primary-navigation__font-weight--active;
                text-decoration: none;
            }

            &.attention {
                color: $primary-navigation__color--attention;
            }

            &:after {
                display: none;
            }
        }

        &__secondary-wrapper {
            background: $secondary-wrapper__background;
            display: none;
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            padding: $secondary-wrapper__padding;
            position: absolute;
            right: 50%;
            top: $secondary-wrapper__top;
            width: 100vw;
            z-index: 1;

            &.is-visible {
                display: block;
            }
        }

        &__secondary-list {
            @include page-alignment();

            display: grid;
            grid-auto-flow: column;
            grid-gap: $secondary-list__grid-gap;
            grid-template-columns: $secondary-list__grid-template-columns;
            grid-template-rows: $secondary-list__grid-template-rows;
            list-style: none;
            padding: 0 2rem !important;
        }

        &__secondary-link {
            color: $secondary-navigation-link__color;
            display: inline-block;
            font-weight: $secondary-navigation-link__font-weight;
            padding-bottom: $secondary-navigation-link__padding-bottom;

            &:hover {
                color: $secondary-navigation-link__color--active;
                font-weight: $secondary-navigation-link__font-weight--active;
                text-decoration: none;
            }
        }
    }
}

@include max-screen(1080px) {
    .navigation {
        $self: &;

        &__link {
            font-size: 1.5rem;
            padding-right: 1rem;
        }

        &__secondary-list {
            grid-template-columns: repeat(auto-fill, minmax(12rem, 20rem));
        }

        &__secondary-link {
            font-size: $font-size__s;
        }
    }
}
