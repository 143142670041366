$featured-category-list__margin-bottom: 2rem;
$featured-category-list__padding: 0 2rem;
$featured-category-list__max-width: 128rem;
$featured-category-list__grid-columns: repeat(auto-fit, minmax(20rem, 1fr));
$featured-category-list__grid-gap: 2rem;

$featured-category-item__color: $color--white;
$featured-category-item__radius: 0.5rem;
$featured-category-item__height: inherit;
$featured-category-item__height--mobile: 5rem;
$featured-category-item__margin: 0;
$featured-category-item__margin--mobile: 0 0 .5rem 0;
$featured-category-item__box-shadow--hover: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);

$featured-category-arrow__height: 2rem;
$featured-category-arrow__width: 2rem;

$featured-category-link__width: 100%;
$featured-category-link__width--mobile: calc(100% - 50px);

$featured-category-image-link__width: inherit;
$featured-category-image-link__width--mobile: 5rem;
$featured-category-image-link__padding: 1rem 1.5rem;
$featured-category-image-link__padding--mobile: 1.5rem;

$featured-category-image__height: 100%;
$featured-category-image__width: 100%;

$content-banner__display: flex;

$content-banner-content__align-items: center;
$content-banner-content__background-color: $color--grey;
$content-banner-content__display: flex;
$content-banner-content__flex: 1 0 50%;
$content-banner-content__flex-direction: column;
$content-banner-content__justify-content: center;
$content-banner-content__padding: 4rem;

$content-banner-content-inner__max-width: 43rem;
$content-banner-content-inner__text-align: center;

$content-banner-content-title__color: $color--white;
$content-banner-content-title__font-weight: $font-weight__medium;
$content-banner-content-title__font-size: $font-size__xxl;
$content-banner-content-title__line-height: $line-height__xxl;

$content-banner-content-paragraph__color: $color--white;
$content-banner-content-paragraph__margin: 2rem 0 3rem;
$content-banner-content-paragraph__margin--desktop: 3rem 0 5rem;
$content-banner-content-paragraph__line-height: $line-height__xl;

$content-banner-content-banner__display: none;
$content-banner-content-banner__display--desktop: block;
$content-banner-content-banner__flex: 1 0 50%;
$content-banner-content-banner__height: 38rem;

$text-block__background: $color--light-grey;
$text-block__padding: $spacing__m;
$text-block__padding--desktop: 5rem 6rem;
