@mixin account-nav-icon() {
    &:after {
        @include icon-arrow($account-nav-icon__fill, $account-nav-icon__size, $account-nav-icon__size);

        content: '';
        display: block;
        position: absolute;
        right: $account-nav-icon__right;
        top: $account-nav-icon__top;
    }
}

.block.account-nav {
    background: $account-nav__background;
    border: $account-nav__border;
    border-radius: $account-nav__border-radius;
    margin: $account-nav__margin;
    padding: $account-nav__padding;

    .title,
    .current {
        // When on mobile, don't display "My Account" link
        display: none;
    }

    .item {
        border-bottom: $account-nav-item__border;

        &:last-child {
            border-bottom: 0;
        }

        &:last-child a:after {
            display: none;
        }

        &.current {
            a {
                padding: 0;

                &:after {
                    display: none;
                }
            }

            strong {
                padding: $account-nav-item__padding;
            }
        }
    }

    a,
    strong {
        color: $account-nav-item__color;
        display: block;
        padding: $account-nav-item__padding;
        position: relative;

        &:hover {
            background: transparent;
            color: $account-nav-item__color--hover;
            text-decoration: $account-nav-item__text-decoration--hover;
        }

        @if ($account-nav-icon__enabled == true) {
            @include account-nav-icon();
        }

        strong {
            padding: 0; // Child page of the current item
        }
    }

    strong {
        display: block;
        padding: $account-nav-item__padding;
    }
}

@include min-screen($screen__m) {
    .block.account-nav {
        background: $account-nav__background--desktop;
        border: $account-nav__border--desktop;
        border-radius: $account-nav__border-radius--desktop;
        margin: $account-nav__margin--desktop;
        padding: $account-nav__padding--desktop;

        .current {
            background: $account-nav-item__background--current;
            display: inherit;
            color: $account-nav-item__color--current;
            font-weight: $account-nav-item__font-weight--current;
        }

        .item {
            border-bottom: $account-nav-item__border--desktop;
        }

        a,
        strong {
            padding: $account-nav-item__padding--desktop;

            @if ($account-nav-icon__enabled--desktop == true) {
                @include account-nav-icon();
            }

            @if ($account-nav-icon__enabled--desktop == false) {
                &:after {
                    display: none;
                }
            }
        }
    }
}
