.header-logo {
    &__image {
        height: auto;
        width: 20rem;
    }
}

@include min-screen($screen__l) {
    .header-logo {
        .header-primary & {
            margin-right: auto !important;
        }

        &__image {
            height: auto;
            width: 37.5rem;
        }
    }
}
