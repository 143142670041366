.back-link {
    align-items: center;
    color: $text__color-link;
    display: flex;

    &:hover {
        color: $text__color-link--hover;
    }

    &__icon-arrow-left {
        fill: currentColor;
        height: 1.3rem;
        margin-right: 0.4rem;
        width: auto;
    }
}
