@mixin table-specifications() {
    border-spacing: 0;
    width: 100%;

    tr:nth-child(odd) {
        background: $table-specifications-odd__background;
    }

    th {
        font-weight: $table-specifications-th__font-weight;
    }

    td {
        font-weight: $table-specifications-td__font-weight;
    }

    th,
    td {
        padding: $table-specifications-row__padding;
        text-align: left;
        vertical-align: top;
    }

    th,
    td:first-child {
        border: 0;
        width: $table-specifications-label__width;
    }

    @include min-screen($screen__m) {
        th,
        td:first-child {
            width: $table-specifications-label__width--desktop;
        }
    }
}

.table--specifications {
    @include table-specifications();
}
