// Default font is used for all text on the site
$font-family__default: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, “Segoe UI”, Helvetica, Arial,
    sans-serif;

$font-size__xxxl: 2.8rem; // H1 mobile
$font-size__xxxl--desktop: 3.4rem; // H1 desktop
$font-size__xxl: 2.2rem; // H2 mobile
$font-size__xxl--dekstop: 2.8rem; // H2 desktop
$font-size__xl: 2rem; // H3 mobile
$font-size__xl--desktop: 2.4rem; // H3 desktop
$font-size__l: 1.8rem; // H4 mobile
$font-size__l--desktop: 2rem; // H4 desktop
$font-size__m: 1.4rem; // Default font-size
$font-size__s: 1.2rem;
$font-size__page-title--desktop: $font-size__xxxl--desktop;
$font-size__page-title--mobile: $font-size__xxxl;

$font-size__default: $font-size__m;

$font-weight__regular: 400;
//$font-weight__medium: 500;
$font-weight__bold: 700;
$font-weight__extra-bold: 800;

$font-weight__default: $font-weight__regular;

$line-height__xxxl: 4.4rem;
$line-height__xxxl--dekstop: 6.4rem;
$line-height__xxl: 3.4rem;
$line-height__xxl--desktop: 5.4rem;
$line-height__xl: 3rem;
$line-height__xl--desktop: 3.4rem;
$line-height__l: 2.8rem;
$line-height__m: 2.8rem;
$line-height__m--desktop: 2.1rem;
$line-height__sm: 1.5rem;
$line-height__s: 1.2rem;
$line-height__s--mobile: 1.8rem;

$line-height__default: $line-height__m;

$margin__xxl: 0 0 3rem 0;
$margin__xl: 0 0 2rem 0;
