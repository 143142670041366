@include min-screen($screen__m) {
    .data.table {
        th,
        td {
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
