.toolbar {
    $self: &;

    align-items: center;
    display: flex;

    &--top {
        flex-wrap: wrap;
        justify-content: $toolbar-top__justify;
        margin: $toolbar-top__margin;

        #{$self}__pager {
            display: none;
        }
    }

    &--bottom {
        justify-content: center;
        margin: $toolbar-bottom__margin;

        #{$self}__amount,
        #{$self}__sort-by,
        #{$self}__toggle-filters {
            display: none;
        }
    }

    &__sort-by select {
        margin-bottom: 0; /* Reset from default select styling */
        height: $toolbar-sort-select--height;
    }

    &__toggle-filters,
    &__sort-by {
        flex: $toolbar-sort-by--flex;
    }

    &__amount {
        &--top {
            display: $toolbar-amount-top--display__mobile;
        }

        &--bottom {
            display: $toolbar-amount-bottom--display__mobile;
            flex: 0 0 100%;
            margin-top: 1rem;
        }
    }

    &__wishlist--top {
        margin-bottom: 1rem;

        .pager {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .limiter {
            align-items: center;
            display: flex;
            grid-gap: 1rem;
            white-space: nowrap;
        }

        .limiter-label {
            display: none;
        }
    }
}

@include min-screen($screen__xs) {
    .toolbar {
        $self: &;

        &__wishlist--top {
            .limiter-label {
                display: block;
            }
        }
    }
}

@include min-screen($screen__m) {
    .toolbar {
        $self: &;

        &--top {
            flex-wrap: nowrap;

            #{$self}__toggle-filters {
                display: none;
            }
        }

        &__toggle-filters,
        &__sort-by {
            flex: inherit;
        }

        &__sort-by select {
            height: inherit;
        }

        &__amount {
            &--top {
                display: $toolbar-amount-top__display;
            }

            &--bottom {
                display: $toolbar-amount-bottom__display;
            }
        }
    }
}
