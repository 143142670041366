.review-form {
    border-top: $review-form__border-top;
    margin-top: $spacing__m;
    padding-top: $spacing__m;
    width: 100%;

    textarea {
        min-height: 20rem;
    }

    &__rating-label {
        font-size: $font-size__l;
        font-weight: $font-weight__bold;
    }
}

@if $review-form__flex-direction == 'row' {
    @include min-screen($screen__m) {
        .review-form {
            &__blocks {
                display: flex;
                justify-content: space-between;
            }

            &__intro,
            &__content {
                flex: 0 0 calc(50% - 1rem);
            }

            &__intro p:first-child,
            &__content label:first-child {
                margin-top: 0;
            }
        }
    }
}
