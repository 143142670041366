.sidedrawer-overlay {
    background: $sidedrawer-overlay__background;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 500ms ease-in-out;
    width: 100%;
    z-index: 3;

    .sidedrawer-is-open & {
        opacity: 1;
        pointer-events: inherit;
    }
}

/** Disable all events on the page header for better performance **/
.sidedrawer-is-open .page-header {
    pointer-events: none;
}

@include min-screen($screen__m) {
    .sidedrawer-overlay {
        display: none;
    }
}
