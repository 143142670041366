//$old-price__font-size: 1.6rem;
//$regular-price__font-size: 4rem;
//$special-price__font-size: $regular-price__font-size;
//
//$regular-price__color: $color--orange;
//$special-price__color: $regular-price__color;
//$product-card-name__color: $color--default;

$old-price__font-size: 0.75em;
$regular-price__font-size: 2.5rem;
$special-price__font-size: $regular-price__font-size;
$final-price__font-size--desktop: 2.5rem;
$final-price__font-size: 2.3rem;

$regular-price__color: $color--orange;
$special-price__color: $regular-price__color;
$product-card-name__color: $color--default;
