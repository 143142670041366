// Layout
$spacing__s: 1rem !default;
$spacing__m: 2rem !default;

$layout__background: $color--light-grey !default;
$layout__background-content: $color--white !default;
$layout__max-width: 144rem !default;
$layout__sidebar-width: 28rem !default;
$layout__homepage-full-width: true !default;

$layout__padding-inline: $spacing__s !default;
$layout__padding-inline--desktop: $spacing__m !default;

$border-radius__default: 1rem !default;
