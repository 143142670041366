$shipping-methods__border: 1px solid $color--grey !default;
$shipping-methods__border-radius: $border-radius__default !default;
$shipping-methods__padding: 1.5rem !default;
$shipping-methods__margin-bottom: 1rem !default;
$shipping-methods__margin-bottom--last-child: 1rem !default;

$shipping-methods-selected__background: transparent !default;
$shipping-methods-selected__border: 1px solid $color--black !default;

$shipping-methods-carrier__color: $text__color !default;

$shipping-methods__show-carrier: false !default;
