.navigation {
    display: none;
}

@include min-screen($screen__m) {
    /* Overwrite default theme styling, should be removed if default them is finished */
    nav.navigation {
        background: transparent;
        font-weight: $font-weight__default;

        ul {
            padding: 0;
        }
    }

    .navigation {
        $self: &;

        display: block;
        position: relative;
        width: 100%;

        &__list {
            align-items: center;
            display: inline-flex;
            flex-wrap: wrap;
            height: $primary-navigation__height;
            list-style: none;
            margin: 0;
            padding: 0;
            position: static !important;

            #{$self}--has-more-link & {
                display: grid;
                grid-auto-flow: column;
                overflow: hidden;
            }
        }

        &__item {
            height: 100%;
            margin: 0;
            padding: 0;

            &.is-current,
            &.is-active {
                #{$self}__link {
                    background: $primary-navigation__background--active;
                    color: $primary-navigation__color--active;
                    font-weight: $primary-navigation__font-weight--active;
                    position: relative;

                    &:after {
                        background-color: $primary-navigation-link__background--after--active;
                        bottom: $primary-navigation-link__bottom--after--active;
                        content: '';
                        display: $primary-navigation-link__display--after--active;
                        height: $primary-navigation-link__height--after--active;
                        position: absolute;
                        width: $primary-navigation-link__width--after--active;
                        z-index: $primary-navigation-link__z-index--after--active;
                    }
                }
            }

            &--more {
                align-items: center;
                background-color: transparent;
                display: flex;
                height: calc(100% - 1px);
                justify-content: center;
                padding: 0;
                position: absolute;
                right: 0;
            }

            &.is-not-visible {
                pointer-events: none;
                visibility: hidden;
            }
        }

        &__link {
            align-items: center;
            color: $primary-navigation__link-color;
            display: flex;
            font-size: $primary-navigation__font-size;
            height: 100%;
            padding: $primary-navigation-link__padding;
            position: relative;
            white-space: nowrap;

            #{$self}__item.is-active & {
                background: $primary-navigation__background--active;
                color: $primary-navigation__color--active;
                font-weight: $primary-navigation__font-weight--active;
                text-decoration: none;
            }

            #{$self}--has-more-link & {
                line-height: 1;
                white-space: nowrap;
            }

            &:hover {
                color: currentColor;
                text-decoration: none;
            }
        }

        &__secondary-wrapper {
            background: $secondary-wrapper__background;
            border-radius: $secondary-wrapper__border-radius;
            display: none;
            left: 0;
            padding: $secondary-wrapper__padding;
            position: absolute;
            top: $secondary-wrapper__top;
            width: 100%;
            z-index: 1;

            &.is-visible {
                display: block;
            }

            &--more {
                left: -20rem;
                width: 30rem;
            }
        }

        &__secondary-list {
            display: grid;
            grid-gap: $secondary-list__grid-gap;
            grid-template-columns: $secondary-list__grid-template-columns;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__secondary-link {
            color: $secondary-navigation-link__color;
            display: inline-block;
            font-weight: $secondary-navigation-link__font-weight;
            padding-bottom: $secondary-navigation-link__padding-bottom;

            &:hover {
                color: $secondary-navigation-link__color--active;
                font-weight: $secondary-navigation-link__font-weight--active;
                text-decoration: underline;
            }
        }

        &__third-list {
            line-height: $third-list__line-height;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__third-link {
            color: $third-navigation-link__color;
            display: inline-block;

            &:hover {
                color: $third-navigation-link__color--active;
            }

            &--see-all {
                padding-top: 0.5rem;
            }
        }

        &__icon-arrow {
            fill: $color--black;
            height: 1.2rem;
            margin-left: 1rem;
            transform-origin: center;
            transition: transform 250ms ease-in-out;
            width: auto;

            #{$self}__item.is-active & {
                fill: $color--blue;
                transform: rotate(180deg);
            }
        }

        .is-hidden {
            display: none;
        }
    }
}

