.search {
    $self: &;

    display: none;
    position: relative;
    width: $search__width--mobile;
    padding: $search__padding--mobile;

    &__form {
        display: flex;
        justify-content: center;
        margin: $search-form__margin--mobile;
        padding: $search-form__padding--mobile;
        position: relative;
    }

    &__input {
        background: $search-input__background;
        border: $search-input__border;
        height: $search-input__height;
        max-width: $search-input__max-width;
        margin: $search-input__margin;
        padding: $search-input__padding;
        width: 100%;

        &::-ms-clear {
            display: none;
        }
    }

    &__cross {
        display: none;
        position: absolute;
        right: 5rem;
        top: 1rem;
    }

    &__submit {
        align-items: center;
        background: $search-submit__background;
        border: $search-submit__border;
        border-radius: $search-submit__border-radius;
        cursor: pointer;
        display: flex;
        height: 4rem;
        justify-content: center;
        padding: 0;
        position: absolute;
        right: 0;
        transition: background $transition__default, border $transition__default;
        width: 4rem;

        &:hover,
        &:active,
        &:focus {
            background: $search-submit__hover-background;
            border: $search-submit__hover-border;
        }

        #{$self}__submit-icon {
            color: $submit-icon__color;
            height: $submit-icon__height;
            margin-right: 0;
            top: 0;
            transition: fill $transition__default;
            width: auto;

            #{$self}__submit:hover &,
            #{$self}__submit:active &,
            #{$self}__submit:focus & {
                color: $submit-icon__hover-color;
            }
        }
    }

    &__autocomplete {
        border: $search-autocomplete__border;
        border-radius: $search-autocomplete__border-radius;
        overflow: hidden;
        top: calc(#{$search-input__height} - #{$search-input__border-width});

        &.search-autocomplete {
            ul li {
                border: 0;
            }
        }
    }

    &--mobile {
        display: block;
    }
}

@include min-screen($screen__m) {
    .search {
        display: block;
        margin: $search__margin;
        width: $search__width;
        padding: 0;

        &__form {
            margin: 0;
            max-width: $search-form__max-width;
            width: $search-form__width;
            padding: 0;
        }

        &--mobile {
            display: none;
        }
    }
}
