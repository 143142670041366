$message__border: 0 !default;
$message__border-radius: 1rem !default;
$message__font-weight: $font-weight__regular !default;
$message__font-size: $font-size__m !default;
$message__line-height: $line-height__m !default;
$message__margin: 0 0 1rem 0 !default;
$message__padding: 1rem 2rem !default;

$success__background-color: $color--dark-green !default;
$success__border: 0 !default;
$success__color: $color--white !default;

$warning__background-color: $color--dark-yellow !default;
$warning__border: 0 !default;
$warning__color: $color--white !default;

$error__background-color: $color--dark-red !default;
$error__border: 0 !default;
$error__color: $color--white !default;
