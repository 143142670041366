$progress__background-color: $color--light-grey !default;
$progress__border-radius: 0.4rem !default;
$progress__height: 5rem !default;

$progress-item__color: $color--black !default;
$progress-item__font-size: $font-size__s !default;
$progress-item__font-size--desktop: $font-size__default !default;

$progress-item__background-color--active: $color--blue !default;
$progress-item__color--active: $color--white !default;

$progress-item__background-color--complete: $color--dark-blue !default;
$progress-item__color--complete: $color--white !default;

$progress-icon__height: 1.6rem !default;
