table {
    caption {
        display: none;
    }
}

.data.table {
    border-spacing: 0;
    width: 100%;

    th,
    td {
        text-align: left;
    }

    th {
        border-bottom: 1px solid $table__border-color;
    }

    tfoot {
        td {
            border-bottom: 1px solid $table__border-color;
        }

        th {
            text-align: right;
        }

        tr:first-child td,
        tr:first-child th {
            border-top: 1px solid $table__border-color;
        }

        tr:last-child td,
        tr:last-child th {
            border-bottom: 0;
        }
    }

    .actions a + a {
        margin-left: 0.8rem;
        padding-left: 1rem;
        position: relative;

        &:before {
            background: $color--grey;
            content: '';
            display: block;
            height: 1.8rem;
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;
        }
    }
}

@include max-screen($screen__l) {
    .data.table:not(.totals):not(.table-totals) {
        thead {
            display: none;
        }

        tr {
            border-bottom: 1px solid $table__border-color;
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;

            &:not(:first-child) {
                padding-top: 2rem;
            }
        }

        td:before {
            content: attr(data-th);
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: 0.4rem;
        }
    }
}

@include min-screen($screen__l) {
    .data.table {
        border-bottom: 1px solid $table__border-color;

        th,
        td {
            padding: 1rem 1rem 1rem 0;
        }

        &:not(.totals) tbody {
            tr:first-child td {
                padding-top: 1.8rem;
            }

            tr:last-child td {
                padding-bottom: 1.5rem;
            }
        }
    }
}
