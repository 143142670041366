$product-page__gallery--flex: 0 0 50% !default;
$product-page__summary--flex: 0 0 50% !default;

$product-page__block--border: 1px solid $color--grey !default;

$product-page__add-to-cart--border-bottom: 0 !default;
$product-page__add-to-cart--border-top: 0 !default;
$product-page__add-to-cart--margin: 1rem 0 !default;
$product-page__add-to-cart--pading: 1rem 0 !default;
$product-page__add-to-cart--max-width: inherit !default;

$product-page__stock--margin: 1rem 0 !default;
$product-page__price--margin: 1rem 0 !default;
$product-page__usps--margin: 1rem 0 !default;

$product-page-add-to-item__margin-bottom: 1rem !default;

/* Product page layout settings */
$product-page__always-show-title-on-top: false !default;

$product-page__grid-gap: 4rem 10% !default;
$product-page__grid-template-columns: 60% 30% !default;
$product-page__grid-template-rows: repeat(3, auto) !default;

$product-page-header__grid-column: #{'1 / -1'} !default;
$product-page-gallery__grid-column: 1 !default;
$product-page-gallery__grid-row: 2 !default;
$product-page-summary__grid-column: 2 !default;
$product-page-summary__grid-row: #{'2 / -1'} !default;
$product-page-primary__grid-column: 1 !default;
$product-page-primary__grid-row: 3 !default;
$product-page-secondary__grid-column: 2 !default;
$product-page-secondary__grid-row: 3 !default;

$product-page-upsell__grid-column: 1 / span 2 !default;
$product-page-upsell__grid-row: 4 !default;

$product-page-short-description--margin--mobile: 0 !default;
$product-page-short-description--display--mobile: inherit !default;
$product-page-short-description--margin: 0 !default;
$product-page-short-description--display: inherit !default;
