$popup__z-index: 3 !default;

$popup-overlay__background: rgba($color--deep-black, 0.75) !default;

$popup-modal__background-color: $color--white !default;
$popup-modal__border-radius: $border-radius__default !default;
$popup-modal__margin: 10vh 1rem !default;
$popup-modal__max-width: 80rem !default;
$popup-modal__padding: 3rem !default;
$popup-modal__transition: $transition__default !default;

$popup-title__color: $color--default !default;
$popup-title__font-size: 2rem !default;
$popup-title__margin: 0 0 2rem 0 !default;

$popup-close-icon__fill: $color--white !default;
$popup-close-icon__height: 1.6rem !default;
$popup-close-icon__right: 0 !default;
$popup-close-icon__top: -3rem !default;
