.header-item {
    &__link {
        align-items: center;
        background: $header-item__background;
        border-radius: $header-item__border-radius;
        display: flex;
        height: $header-item__height;
        justify-content: center;
        margin: $header-item__margin;
        position: relative;
        width: $header-item__width;
    }

    &__icon {
        fill: $header-item-icon__fill;
        height: $header-item-icon__height;
        width: auto;
    }

    &__label {
        color: $header-item-label__color;
        display: none;
        margin: $header-item-label__margin;
    }

    &__qty {
        align-items: center;
        background: $header-item-qty__background;
        border: $header-item-qty__border;
        border-radius: $header-item-qty__border-radius;
        color: $header-item-qty__color;
        display: flex;
        font-size: $header-item-qty__font-size;
        font-weight: $header-item-qty__font-weight;
        height: $header-item-qty__height;
        justify-content: center;
        position: $header-item-qty__position;
        right: $header-item-qty__offset-right;
        top: $header-item-qty__offset-top;
        width: $header-item-qty__height;

        &.empty {
            display: none;
        }
    }

    @if $header-item-label__show == true {
        &__label {
            display: block;
        }
    }
}

@include min-screen($screen__xxs) {
    .header-item {
        @if $header-item-label__show == true {
            &__link {
                flex-direction: column;
                height: inherit;
                width: inherit;
            }

            &__label {
                font-size: $header-item-label__font-size;
                line-height: $header-item-label__line-height;
            }
        }
    }
}

@include min-screen($screen__m) {
    .header-item {
        &__link {
            height: $header-item__height--desktop;
            margin: $header-item__margin--desktop;
            width: $header-item__width--desktop;
        }

        &__icon {
            height: $header-item-icon__height--desktop;
        }

        @if $header-item-label__show == true {
            &__link {
                justify-content: space-between;
                width: inherit;
            }

            &__label {
                margin: $header-item-label__margin--mobile;
            }
        }

        &__qty {
            font-size: $header-item-qty__font-size--desktop;
            font-weight: $header-item-qty__font-weight--desktop;
            height: $header-item-qty__height--desktop;
            right: $header-item-qty__offset-right--desktop;
            top: $header-item-qty__offset-top--desktop;
            width: $header-item-qty__height--desktop;

            &.empty {
                display: none;
            }
        }
    }
}
