.content-banner {
    &__button {
        @include button();

        background: $button-primary__background;
        border-radius: $button-primary__border-radius;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 1);
        color: $color--white;
        padding: $button-primary__padding;

        &:hover {
            background: $button-primary-hover__background;
            color: $color--white;
            text-decoration: none;
        }
    }
}
