.swatches {
    align-items: center;
    display: flex;
    justify-content: $swatches__justify-content;

    &__list {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__item {
        margin-right: $swatches-item__spacing;

        &:last-child {
            margin: 0;
        }
    }

    &__swatch {
        border-radius: $swatches-item__border-radius;
        display: block;
        height: $swatches-item__height;
        width: $swatches-item__width;
    }

    &__more {
        color: $text__color-link;
        margin-left: $swatches-item__spacing;
    }
}

@include min-screen($screen__s) {
    .swatches {
        justify-content: $swatches__justify-content--desktop;
    }
}
