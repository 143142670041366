.usps {
    $self: &;

    background: $usps__background;
    display: flex;
    flex-direction: $usps__flex-direction;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &__item {
        color: $usps__color;
        display: flex;
        margin: $usps-item__margin;
        padding: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        color: $usps-title__color;
        display: $usps-title__display;
        margin: $usps-title__margin;
        width: $usps-title__width;
        text-align: $usps-title__text-align;
        font-size: $usps-title__font-size;
        font-weight: $usps-title__font-weight;

        .h2 {
            margin: 0;
        }
    }

    &__link {
        color: $usps-link__color;

        &:hover {
            color: $usps-link__color--active;
        }
    }

    &__icon-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: $usps-icon__margin;
    }

    &__icon {
        fill: $usps-icon__fill;
        height: $usps-icon__height;
    }

    &--default {
        align-items: $usps__align-items--default;
        background: $usps__background--default;
        height: $usps__height--default;
        justify-content: $usps__justify-content--default;
        padding: $usps__padding--default;

        #{$self}__title {
            text-align: $usps__text-align--default;
        }

        #{$self}__item {
            color: $usps__color--default;
            margin: $usps-item__margin--default;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--default;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--default;
        }

        @if $usps__flex-direction--default == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--default == 'column' {
            flex-direction: $usps__flex-direction--default;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--cart {
        align-items: $usps__align-items--cart;
        background: $usps__background--cart;
        height: $usps__height--cart;
        justify-content: $usps__justify-content--cart;
        padding: $usps__padding--cart;
        margin: $usps__margin--cart;
        border: $usps__border--cart;
        border-radius: $usps__border-radius--cart;

        #{$self}__item {
            color: $usps__color--cart;
            margin: $usps-item__margin--cart;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--cart;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--cart;
        }

        @if $usps__flex-direction--cart == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--cart == 'column' {
            flex-direction: $usps__flex-direction--cart;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--checkout {
        align-items: $usps__align-items--checkout;
        background: $usps__background--checkout;
        height: $usps__height--checkout;
        justify-content: $usps__justify-content--checkout;
        padding: $usps__padding--checkout;
        margin: $usps__margin--checkout;
        border: $usps__border--checkout;
        border-radius: $usps__border-radius--checkout;

        #{$self}__item {
            color: $usps__color--checkout;
            margin: $usps-item__margin--checkout;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--checkout;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--checkout;
        }

        @if $usps__flex-direction--checkout == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--checkout == 'column' {
            flex-direction: $usps__flex-direction--checkout;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--pdp {
        align-items: $usps__align-items--pdp;
        background: $usps__background--pdp;
        height: $usps__height--pdp;
        justify-content: $usps__justify-content--pdp;
        padding: $usps__padding--pdp;

        #{$self}__item {
            color: $usps__color--pdp;
            margin: $usps-item__margin--pdp;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--pdp;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--pdp;
        }

        @if $usps__flex-direction--pdp == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--pdp == 'column' {
            flex-direction: $usps__flex-direction--pdp;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--category {
        background: $usps__background--category;
        flex-direction: $usps__flex-direction--category;
        padding: $usps__padding--category;

        #{$self}__title {
            font-weight: $usps__font-weight--category;
        }

        #{$self}__item {
            margin: $usps-item__margin--category;
        }
    }

    &--homepage {
        background: $usps__background--homepage;
        flex-direction: $usps__flex-direction--homepage;
        padding: $usps__padding--homepage;

        #{$self}__title {
            font-weight: $usps__font-weight--homepage;
        }

        #{$self}__item {
            margin: $usps-item__margin--homepage;
        }
    }

    &--account {
        align-items: $usps__align-items--account;
        background: $usps__background--account;
        height: $usps__height--account;
        justify-content: $usps__justify-content--account;
        padding: $usps__padding--account;

        #{$self}__item {
            color: $usps-item__color--account;
            margin: $usps-item__margin--account;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--account;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--account;
        }

        @if $usps__flex-direction--account == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--account == 'column' {
            flex-direction: $usps__flex-direction--account;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--register {
        align-items: $usps__align-items--register;
        background: $usps__background--register;
        height: $usps__height--register;
        justify-content: $usps__justify-content--register;
        padding: $usps__padding--register;

        #{$self}__title {
            color: $usps-title__color--register;
        }

        #{$self}__item {
            color: $usps-item__color--register;
            margin: $usps-item__margin--register;
        }

        #{$self}__icon-wrapper {
            margin: $usps-icon__margin--register;
        }

        #{$self}__icon {
            fill: $usps-icon__fill--register;
        }

        @if $usps__flex-direction--register == 'row' {
            flex-wrap: wrap;
            overflow: hidden;

            #{$self}__item {
                align-items: center;
                height: $usps__height--default;
            }
        }

        @if $usps__flex-direction--account == 'column' {
            flex-direction: $usps__flex-direction--account;

            #{$self}__item {
                margin-bottom: $usps-item__margin-bottom--column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
