.summary {
    background: $summary__background-color;
    border: $summary__border;
    border-radius: $summary__border-radius;
    padding: $summary__padding;

    &__title {
        margin: $summary-title__margin;
        font-size: $summary-title__font-size;
    }

    &__proceed-to-checkout {
        font-weight: $summary-proceed-to-checkout__font-weight;
        width: $summary-proceed-to-checkout__width;
    }
}

@include min-screen($screen__m) {
    .summary {
        border: $summary__border--desktop;
        padding: $summary__padding--desktop;

        &__title {
            margin: $summary-title__margin--desktop;
        }
    }
}
