$cookie-notice__background: $color--black !default;
$cookie-notice__padding: 0 !default;

$cookie-notice-text__color: $color--white !default;

$cookie-notice-link__color: $color--white !default;
$cookie-notice-link__text-decoration: none !default;

$cookie-notice-button__margin: 2rem !default;
$cookie-notice-text__padding: 2rem !default;
