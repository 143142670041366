$footer__border-top: 2px solid $color--black !default;
$footer__font-size: $font-size__default !default;
$footer__font-size--desktop: $font-size__default !default;
$footer__line-height: 3rem !default;

$footer-top__background: transparent !default;
$footer-top__padding: 2rem !default;

$footer-primary__background-color: $color--light-grey !default;
$footer-primary__color: $color--black !default;
$footer-primary__font-weight: $font-weight__default !default;
$footer-primary__padding: 4rem 0 !default;

$footer-link__color: $color--black !default;
$footer-link__text-decoration: none !default;
$footer-link__text-decoration--hover: underline !default;

$footer-block-container__max-width: 25% !default;

$footer-block__min-width: 20rem !default;
$footer-block__max-width: 25% !default;
$footer-block__margin: 2rem 0 4rem 0 !default;
$footer-block__margin--collapsible: 0 !default;
$footer-block__margin--desktop: 0 0 4rem 0 !default;

$footer-block-title__border-bottom: 1px solid $color--black !default;
$footer-block-title__font-size: $font-size__default !default;
$footer-block-title__margin: 0 0 1.5rem 0 !default;
$footer-block-title__padding: 0 0 1.5rem 0 !default;
$footer-block-title__font-weight: $font-weight__bold !default;

$footer-block-title__border--desktop: 0 !default;
$footer-block-title__font-size--desktop: $font-size__default !default;
$footer-block-title__margin--desktop: 2rem 0 !default;
$footer-block-title__padding--desktop: 0 !default;

$footer-payment__padding: 3rem 2rem !default;
