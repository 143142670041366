.product-navigation {
    &__list {
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        padding: $product-navigation-item__padding;
    }
}

@include max-screen($screen__m) {
    .product-navigation {
        @if $product-navigation__show-on-mobile == false {
            display: none;
        }

        @if $product-navigation__show-on-mobile == true {
            height: $product-navigation__height;
            overflow: hidden;
            width: calc(100% + #{$spacing__s});

            &__wrapper {
                height: $product-navigation__height + 1.6rem; /* + 1.6rem beacause of the scrollbar */
                overflow-x: scroll;
                overflow-y: hidden;
                scroll-snap-type: x mandatory;
            }

            &__list {
                line-height: $product-navigation__height;
            }

            &__item {
                scroll-snap-align: start;
            }

            &__link {
                align-items: center;
                background: $product-navigation-link__background;
                border-radius: $product-navigation-link__border-radius;
                display: flex;
                color: $product-navigation-link__color;
                height: $product-navigation__height;
                padding: $product-navigation-link__padding;
                white-space: nowrap;

                &:hover,
                &:active,
                &:focus {
                    color: $product-navigation-link__color--hover;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .product-navigation {
        &__item {
            padding: $product-navigation-item__padding--m;
        }
    }
}

@include min-screen($screen__xl) {
    .product-navigation {
        &__item {
            padding: $product-navigation-item__padding--xl;
        }
    }
}
