.account-form {
    $self: &;

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @if $account-form-name__horizontal == true {
        &__name {
            display: flex;
            justify-content: space-between;
        }

        .field-name-firstname,
        .field-name-lastname {
            flex: 1 0 calc(50% - (#{$account-form-name__spacing} / 2));
            max-width: calc(50% - (#{$account-form-name__spacing} / 2));
        }
    }

    &__button {
        margin: $account-form-button__margin;
        width: $account-form-button__width;
    }

    &__content {
        margin: $account-form-content__margin;
        flex: 1 0 auto;
    }

    &__link {
        display: flex;
        align-items: center;
    }

    &__icon-arrow {
        fill: $account-form-icon-arrow__fill;
        height: $account-form-icon-arrow__height;
        position: relative;
        width: auto;

        & + span {
            margin-left: $account-form-icon-arrow-span__margin-left;
        }
    }

    &--new-customer {
        #{$self}__button {
            margin: $account-form-button__margin--new-customer;
        }
    }
}

.ui-datepicker-trigger {
    margin: $account-form-datepicker__margin;
}
