.search-autocomplete.amsearch-clone-position {
    top: 5.4rem;
    width: 100% !important;
}

.amsearch-wrapper-content {
    .product-item {
        padding: 1rem;
    }

    .product-item:nth-child(3n + 1) {
        border-left: 0;
    }

    > .amsearch-item {
        padding: 2rem !important;

        &:hover {
            background: rgba($color--light-blue, 0.2) !important;
            box-shadow: none !important;
        }

        &:before {
            background-color: $color--light-grey !important;
        }

        .amsearch-autocomplete-image {
            width: 100%;

            .product-image {
                height: 10rem;
                width: auto;
            }
        }

        .amsearch-autocomplete-information {
            flex: 1 1 100%;
        }
    }

    .price-excluding-tax {
        .price {
            font-size: $font-size__xl !important;
        }
    }

    .price-final_price .price-wrapper {
        .price {
            font-size: $font-size__xl !important;
        }
    }
}

.amsearch-form-container {
    input[type='text']::placeholder {
        font-style: normal !important;
    }

    .amsearch-results {
        background-color: $color--white !important;
    }

    .search-autocomplete .amsearch-results .amsearch-leftside {
        background: $color--light-blue !important;
    }

    .input-text:focus {
        border: none !important;
        box-shadow: none !important;
    }

    &.-opened .input-text {
        border: 0.1rem solid $color--dark-blue !important;
        padding-left: 1.5rem !important;
    }
}

.amsearch-products {
    > .amasty-xsearch-block-header {
        color: $color--blue;
        font-size: $font-size__xxxl;
        font-style: italic;
        margin-top: 0.2rem;
        padding: 1.5rem 2rem 0 1rem !important;
    }

    &.-columns {
        width: auto !important;
    }

    .amsearch-highlight {
        background-color: rgba($color--blue, 0.2) !important;
        color: #333 !important;
        padding: 0 !important;
    }
}

.amsearch-more-results {
    padding-bottom: 1rem;

    .amsearch-link {
        background-color: $color--blue !important;
        border: 0 !important;
        border-radius: 0.3rem;
        font-size: 1.6rem !important;
        font-weight: $font-weight__bold;
        height: $button__height;
        margin: 2rem 1rem;
        padding: 1.3rem 3rem !important;

        &:hover {
            background-color: $color--dark-blue !important;
        }
    }
}

.amsearch-item-container {
    > .amasty-xsearch-block-header:before {
        content: '';
        display: none;
    }

    > .amasty-xsearch-block-header {
        color: $color--blue;
        font-size: $font-size__l;
        font-style: italic;
        padding: 0 !important;
        word-break: inherit;
    }

    .item-name {
        background: none !important;
        color: $color--orange !important;
        font-size: $font-size__m !important;
        font-weight: $font-weight__regular;
        padding: 0 !important;
    }

    .amsearch-highlight {
        font-size: $font-size__m !important;
        font-weight: $font-weight__regular !important;
    }

    &:after {
        height: 0 !important;
    }
}

@include max-screen($screen__l) {
    .amsearch-wrapper-content {
        .product-item:nth-child(odd) {
            border-left: 0 !important;
        }

        .product-item:nth-child(3n + 1):not(:first-child) {
            border-left: 1px solid rgba(81, 90, 93, 0.3) !important;
        }
    }
}

@include min-screen($screen__m) {
    .search-autocomplete.amsearch-clone-position {
        top: 3.9rem;
        width: 90rem !important;
    }

    .amsearch-form-container.-large {
        .amsearch-wrapper-content {
            > .amsearch-item {
                width: 50% !important;
            }
        }
    }

    .amsearch-wrapper-content {
        text-align: center;

        .amsearch-wrapper-inner {
            .price-box {
                justify-content: center;
                max-width: 30rem !important;
            }
        }

        .product-item-details {
            align-items: center;
        }

        .product-item-link {
            text-align: center;
        }

        .product-item {
            border-left: 1px solid $color--grey;
            margin-bottom: 2rem;
            padding: 1rem;
        }
    }
}

@include min-screen($screen__l) {
    .search-autocomplete.amsearch-left-position {
        left: unset !important;
        right: 0 !important;
    }

    .amsearch-form-container.-large {
        .amsearch-wrapper-content {
            > .amsearch-item {
                width: 33.33% !important;
            }
        }
    }

    .amsearch-wrapper-content {
        .price-excluding-tax {
            .price {
                font-size: $font-size__xl--desktop !important;
            }
        }

        .price-final_price .price-wrapper {
            .price {
                font-size: $font-size__xl--desktop !important;
            }
        }
    }
}
