.secondary-banners {
    border-top: 1px solid $color--white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $layout__max-width;
    padding: 4rem 2rem;

    .banner {
        height: $banner-small__height;

        &--small {
            background: $color--blue;
            border-radius: $banner-small__border-radius;
            flex: 1;

            &-odd {
                margin: 0 0 2rem 0;
            }

            &-even {
                margin: 2rem 0 0 0;
            }
        }

        &__image {
            display: block;
            opacity: 0.35;
        }

        a.secondary {
            font-size: 1.3rem;
            height: 4.2rem;
            padding: 1.5rem 1.3rem;
        }

        &__title {
            font-size: $banner-small__font-size;
            font-weight: $font-weight__default;
            padding: 0;
        }
    }
}

@include min-screen($screen__m) {
    .secondary-banners {
        flex-direction: row;

        .banner {
            height: $banner-small__height--desktop;

            &--small {
                &-odd {
                    margin: 0 2rem 0 0;
                }

                &-even {
                    margin: 0 0 0 2rem;
                }
            }

            &__image {
                display: none;
            }

            &__image--tablet,
            &__image--desktop {
                opacity: 0.35;
            }

            a.secondary {
                font-size: 1.8rem;
                height: 6rem;
                padding: 2.5rem 3rem;
            }

            &__title {
                font-size: $banner-small__font-size--desktop;
            }
        }
    }
}
