$checkout-payment-method-title--border: 0.1rem solid $color--default !default;
$checkout-payment-method-title__padding: 2rem !default;
$checkout-payment-method-title-mobile__padding: 1.5rem !default;
$checkout-payment-method-content__padding__xl: 2rem !default;

$checkout-payment-method__border: 0.1rem solid $color--default !default;
$checkout-payment-method__border--active: 0.1rem solid $color--default !default;
$checkout-payment-method__margin: 0 0 1rem 0 !default;
$checkout-payment-method__margin--last: 0 0 4rem 0 !default;
$payment-method-billing-address__padding: 0 !default;
$payment-method-content__padding: 0 !default;
