.ambrands-brandlist-widget {
    .ambrands-letters-list .ambrands-letter {
        width: 100% !important;
    }

    .ambrands-letters-filter {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 3rem;
    }

    input#ambrands-search-input {
        margin-bottom: 1rem;
    }

    ul.ambrands-content {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
    }

    .ambrands-brand-item {
        width: auto !important;
    }

    span.ambrands-livesearch-block {
        padding: 10px 0px;
        margin-bottom: 1rem;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
    }

    a.ambrands-inner {
        border: 1px solid #d8d8d8;
        padding: 2rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 170px;
    }

    a:hover.ambrands-inner {
        border: 1px solid #008bc1;
        -webkit-box-shadow:     0px 0px 10px 0px rgba(0,0,0,0.1);
        -moz-box-shadow:        0px 0px 10px 0px rgba(0,0,0,0.1);
        box-shadow:             0px 0px 10px 0px rgba(0,0,0,0.1);
    }

    span.ambrands-image-block {
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ambrands-search-container {
        display: flex;
        gap: 1rem;
    }
}
