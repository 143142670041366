fieldset,
.fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    .field {
        margin: $form-field__margin;
    }
}

label {
    display: block;
    margin: $form-field--label--margin;
}

input,
select,
textarea {
    background-color: $form-field__background-color;
    border: $form-field__border;
    border-radius: $form-field__text-input-radius;
    height: $font-field__height;
    font-family: $form-field__font;
    font-weight: $font-weight__default;
    margin: $form-field__text-input-margin;
    max-width: $form-field__max-width;
    padding: $form-field__padding;
    width: $form-field__width;

    &:active,
    &:focus {
        border-color: $form-field__border-color--focus;
        box-shadow: $form-field__box-shadow;
        outline: 0;
    }

    &::placeholder {
        color: $form-field__placeholder-color;
    }
}

input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

textarea {
    min-height: $form-field-textarea__min-height;
    padding-top: $form-field-textarea__padding-top;
    padding-bottom: $form-field-textarea__padding-bottom;
}

select {
    @include icon-bg-arrow-down($form-field__select-arrow-fill, 1.6rem, 1.6rem);

    -webkit-appearance: none;
    appearance: none;
    background-position: $form-field-select__background-position;
    background-repeat: no-repeat;
    padding-right: 4.2rem;
}

/**
 * Errors
 */
select.mage-error,
textarea.mage-error,
input.mage-error,
.field._error input {
    background-color: $form-field-error__background-color;
    border: $form-field-error__border;
    color: $form-field-error__color;

    &::placeholder {
        color: $form-field-error__color;
    }
}

.mage-error,
.field-error {
    color: $form-field-error__color;
    font-size: $form-field-error__font-size;
    margin: $form-field-error__margin;
}

input[type='radio'],
input[type='checkbox'] {
    background-color: initial;
    border: initial;
    border-radius: initial;
    color: initial;
    height: $form-native-checkbox-radio__height;
    padding: initial;
    margin: initial;
    max-width: initial;
    width: $form-native-checkbox-radio__width;
}

/**
 * Radio & checkboxes
 */
@if $form__use-custom-checkbox-and-radio == true {
    input[type='radio'],
    input[type='checkbox'] {
        display: block;
        border: 0;
        -webkit-clip-path: inset(100%);
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    input[type='checkbox'] + label,
    input[type='radio'] + label {
        align-items: center;
        display: flex;
        font-size: $font-size__default;
        font-weight: $font-weight__default;
        line-height: 2.2rem;
        padding-left: 3rem;
        position: relative;

        img {
            margin-right: 0.5rem;
        }
    }

    /**
     * Checkbox specific
     */
    input[type='checkbox'] + label:before {
        background: $form-checkbox__background;
        border: $form-checkbox__border-width solid $form-checkbox__border-color;
        border-radius: $form-checkbox__border-radius;
        box-shadow: $form-checkbox__border-radius;
        content: '';
        display: block;
        height: $form-checkbox__height;
        left: $form-checkbox__left;
        position: absolute;
        top: $form-checkbox__top;
        width: $form-checkbox__width;
    }

    input[type='checkbox'] + label:after {
        border-radius: $form-checkbox__border-radius--inner;
        content: '';
        display: block;
        height: $form-checkbox__height--inner;
        left: $form-checkbox__left--inner;
        position: absolute;
        top: $form-checkbox__top--inner;
        width: $form-checkbox__width--inner;
    }

    input[type='checkbox'] + label:hover:before {
        border-color: $form-checkbox__border-color--hover;
    }

    input[type='checkbox']:checked + label:before {
        border-color: $form-checkbox__checked-border-color;
    }

    input[type='checkbox']:checked + label:after,
    input[type='checkbox']:checked + label:hover:after {
        background: $form-checkbox__checked-background;
        content: '\2713'; // Checkmark
        color: $form-checkbox__checkmark-color;
        font-family: $font-family__system;
        font-size: $form-checkbox__checkmark-font-size;
        font-weight: $font-weight__bold;
        line-height: $form-checkbox__checkmark-line-height;
        text-align: center;
    }

    input[type='checkbox'] + label:hover:after {
        background: $form-checkbox__background-color--hover;

        @if $form-checkbox__checkmark-color--hover != 'transparent' {
            content: '\2713'; // Checkmark
            color: $form-checkbox__checkmark-color--hover;
            font-family: $font-family__system;
            font-size: $form-checkbox__checkmark-font-size;
            font-weight: $font-weight__bold;
            line-height: $form-checkbox__checkmark-line-height;
            text-align: center;
        }
    }

    /**
     * Radio specific
     */
    input[type='radio'] + label:before {
        background: $form-radio__background;
        border: $form-radio__border-width solid $form-radio__border-color;
        border-radius: $form-radio__border-radius;
        box-shadow: $form-radio__box-shadow;
        content: '';
        display: block;
        height: $form-radio__height;
        left: $form-radio__left;
        position: absolute;
        top: $form-radio__top;
        width: $form-radio__width;
    }

    input[type='radio']:checked + label:after,
    input[type='radio'] + label:hover:after {
        background: $form-radio__background-color--hover;
        border-radius: $form-radio__border-radius--inner;
        content: '';
        display: block;
        height: $form-radio__height--inner;
        left: $form-radio__left--inner;
        position: absolute;
        top: $form-radio__top--inner;
        width: $form-radio__width--inner;
    }

    input[type='radio'] + label:hover:before {
        border-color: $form-radio__border-color--hover;
    }

    input[type='radio']:checked + label:before {
        border-color: $form-radio__border-color--checked;
    }

    input[type='radio']:checked + label:after {
        background: $form-radio__background-color--checked;
    }
}
