.product-attributes {
    &__item {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 2fr;
    }

    &__block {
        margin-top: 5rem;
    }

    @include max-screen($screen__m) {
        &__item {
            grid-template-columns: 1fr 1fr;
        }
    }
}
