@include min-screen($screen__m) {
    .account {
        .columns {
            margin-top: 7rem;

            .main {
                margin-top: 0.5rem;

                .block {
                    border-left: 1px solid $color--grey;
                    margin-left: -3rem;
                    padding-left: 3rem;

                    .block-content {
                        a {
                            color: $color--orange;
                        }
                    }
                }
            }
        }

        &.customer-account-index {
            .main {
                margin-top: 0.7rem;
            }
        }
    }
}
