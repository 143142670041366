.product-page {
    &__title {
        &--large-screen {
            display: none;
        }
    }

    &__review-summary {
        display: none;
    }

    &__block {
        border-bottom: $product-page__block--border;
        margin-top: 4rem;
        padding-bottom: 4rem;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__add-to-cart {
        margin: $product-page__add-to-cart--margin;
        padding: $product-page__add-to-cart--pading;
        border-top: $product-page__add-to-cart--border-top;
        border-bottom: $product-page__add-to-cart--border-bottom;
    }

    &__add-to > div {
        margin-bottom: $product-page-add-to-item__margin-bottom;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__stock {
        margin: $product-page__stock--margin;
    }

    &__price {
        margin: $product-page__price--margin;
    }

    &__short-description {
        margin: $product-page-short-description--margin--mobile;
        display: $product-page-short-description--display--mobile;
    }

    &__usps {
        margin: $product-page__usps--margin;
    }
}

@include min-screen($screen__m) {
    .product-page {
        display: grid;
        grid-gap: $product-page__grid-gap;
        grid-template-columns: $product-page__grid-template-columns;
        grid-template-rows: $product-page__grid-template-rows;

        &__header {
            display: none;
            grid-column: $product-page-header__grid-column;

            @if $product-page__always-show-title-on-top == true {
                display: block;
            }
        }

        &__gallery {
            grid-column: $product-page-gallery__grid-column;
            grid-row: $product-page-gallery__grid-row;
        }

        &__summary {
            grid-column: $product-page-summary__grid-column;
            grid-row: $product-page-summary__grid-row;
        }

        &__upsell {
            grid-column: $product-page-upsell__grid-column;
            grid-row: $product-page-upsell__grid-row;
        }

        &__content-primary {
            grid-column: $product-page-primary__grid-column;
            grid-row: $product-page-primary__grid-row;
        }

        &__content-secondary {
            grid-column: $product-page-secondary__grid-column;
            grid-row: $product-page-secondary__grid-row;
        }

        &__title {
            @if $product-page__always-show-title-on-top == false {
                &--large-screen,
                &__review-summary {
                    display: block;
                }
            }
        }

        &__short-description {
            margin: $product-page-short-description--margin;
            display: $product-page-short-description--display;
        }
    }
}
