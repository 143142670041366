// Default font is used for all text on the site
$font-family__default: system-ui, -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif !default;

$font-size__xxxl: 3.6rem !default;
$font-size__xxl: 3rem !default;
$font-size__xl: 2.4rem !default;
$font-size__l: 2rem !default;
$font-size__m: 1.6rem !default; // Default font-size
$font-size__s: 1.4rem !default;
$font-size__xs: 1.2rem !default;

$font-size__default: $font-size__m !default;

$font-weight__regular: 400 !default;
$font-weight__medium: 500 !default;
$font-weight__bold: 700 !default;

$font-weight__default: $font-weight__medium !default;

$line-height__default: 2.4rem !default;
$line-height__xxl: 3.4rem !default;
$line-height__xl: 2.6rem !default;
$line-height__l: 2rem !default;
$line-height__m: 1.6rem !default;
$line-height__s: 1.2rem !default;

$margin__xxl: 0 0 3rem 0 !default;
$margin__xl: 0 0 2rem 0 !default;
