.content-banner {
    display: $content-banner__display;

    &__content {
        align-items: $content-banner-content__align-items;
        background-color: $content-banner-content__background-color;
        display: $content-banner-content__display;
        flex: $content-banner-content__flex;
        flex-direction: $content-banner-content__flex-direction;
        justify-content: $content-banner-content__justify-content;
        padding: $content-banner-content__padding;
    }

    &__content-inner {
        max-width: $content-banner-content-inner__max-width;
        text-align: $content-banner-content-inner__text-align;
    }

    &__title {
        color: $content-banner-content-title__color;
        font-size: $content-banner-content-title__font-size;
        font-weight: $content-banner-content-title__font-weight;
        line-height: $content-banner-content-title__line-height;
    }

    &__paragraph {
        color: $content-banner-content-paragraph__color;
        line-height: $content-banner-content-paragraph__line-height;
        margin: $content-banner-content-paragraph__margin;
    }

    &__banner {
        display: $content-banner-content-banner__display;
        flex: $content-banner-content-banner__flex;
        height: $content-banner-content-banner__height;
    }

    &__image {
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .content-banner {
        &__banner {
            display: $content-banner-content-banner__display--desktop;
        }

        &__paragraph {
            margin: $content-banner-content-paragraph__margin--desktop;
        }
    }
}
