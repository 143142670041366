.data.table.totals .grand.totals {
    td {
        font-size: $font-size__xxxl;
    }
    th {
        font-size: $font-size__l;
    }
}

@include max-screen($screen__s) {
    .data.table.table-order-items {
        font-size: $font-size__s;

        tfoot {
            .grand.totals,
            .grand_total {
                th,
                td {
                    font-size: $font-size__default;
                }
            }
        }
    }
}
