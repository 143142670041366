@mixin cartItemsAsGrid() {
    @supports (display: grid) {
        .cart-items {
            width: 100%;

            &__item {
                display: grid;
                grid-gap: $cart-item__grid-gap;
                grid-template-columns: $cart-item__grid-template-columns;
                padding: $cart-item__padding;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }

            &__image-wrapper {
                grid-column-start: $cart-items-image-wrapper__grid-column-start;
                grid-row: $cart-items-image-wrapper__grid-row;
                padding: 0;
                min-width: initial;

                img {
                    object-fit: scale-down;
                    width: $cart-items-image__width--large;
                    height: $cart-items-image__height--large;
                }
            }

            &__info {
                grid-column: $cart-items-info__grid-column;
                border: $cart-items-info__border;
            }

            &__qty {
                grid-column-start: $cart-items-qty__grid-column-start;
                grid-row-start: $cart-items-qty__grid-row-start;
            }

            &__price {
                grid-column-start: $cart-items-price__grid-column-start--xs;
                grid-column-end: $cart-items-price__grid-column-end--xs;
                grid-row-start: $cart-items-price__grid-row-start--xs;
                grid-row-end: $cart-items-price__grid-row-end--xs;
                display: flex;
                align-items: center;
                justify-content: center;
                justify-self: $cart-items-price__justify-self--xs;
                font-weight: $cart-items-price__font-weight;
            }

            &__remove-item {
                grid-column-end: $cart-items-remove-item__grid-column-end;
                grid-row: $cart-items-remove-item__grid-row;
                justify-self: $cart-items-remove-item__justify-self;
            }

            &__message {
                color: $cart-items-message__color;
                font-weight: $cart-items-message__font-weight;
            }

            &__td {
                padding: 0;
            }
        }

        @include min-screen($screen__xs) {
            .cart-items {
                &__price {
                    grid-column-start: $cart-items-price__grid-column-start;
                    grid-column-end: $cart-items-price__grid-column-end;
                    grid-row-start: $cart-items-price__grid-row-start;
                    grid-row-end: $cart-items-price__grid-row-end;
                    justify-self: $cart-items-price__justify-self;
                    font-weight: $cart-items-price__font-weight;
                }
            }
        }

        @include min-screen($screen__m) {
            .cart-items {
                &__item {
                    grid-gap: $cart-item__grid-gap--desktop;
                    grid-template-columns: $cart-item__grid-template-columns--desktop;
                }

                &__info {
                    grid-column: $cart-items-info__grid-column--desktop;
                }
            }
        }
    }
}

.cart-items {
    border-collapse: collapse;
    width: 100%;
    margin: $cart-items__margin;

    &__item {
        border-bottom: $cart-item__border;

        &:last-child {
            border-bottom: 0;
        }
    }

    &__td {
        padding: $cart-item__padding;

        &:first-of-type {
            padding-left: $cart-item__padding--first-item;
        }

        &:last-of-type {
            padding-right: $cart-item__padding--last-item;
        }
    }

    &__info {
        padding: $cart-items-info__padding;
    }

    &__image {
        display: flex;

        img {
            border-radius: $cart-items-image__radius;
            width: $cart-items-image__width;
            height: $cart-items-image__height;
        }
    }

    &__price {
        padding-right: 0;
    }

    &__title {
        font-weight: $cart-item-title__font-weight;

        a {
            color: $cart-item-title__color;
        }
    }

    &__stock-status {
        display: flex;
        align-items: center;
        margin: $cart-stock-status__margin;
    }

    &__stock-status-icon {
        margin: $cart-stock-status-icon__margin;
    }

    &__qty {
        padding-left: 0;
    }

    &__remove-item {
        display: flex;
        justify-content: center;
    }

    &__remove-icon {
        height: $cart-remove-icon__height;
    }
}

@if $cart-items__show-grid--desktop == true {
    @include cartItemsAsGrid();
}

@include max-screen($screen__l) {
    @include cartItemsAsGrid();
}

@include min-screen($screen__s) {
    .cart-items {
        &__qty {
            grid-column-start: $cart-items-qty__grid-column-start--desktop;
        }

        &__image-wrapper {
            grid-row: $cart-items-image-wrapper__grid-row--desktop;
        }

        &__td {
            padding: $cart-item__padding--small;
        }

        &__qty {
            padding: $cart-items-qty__padding-small;
        }

        &__info {
            padding: $cart-items-info__padding--small;
        }
    }
}

@include min-screen($screen__l) {
    .cart-items {
        margin: $cart-items__margin--desktop;

        &__price,
        &__qty,
        &__info {
            padding: $cart-items-price-qty-info__padding--large;
        }

        &__remove-item {
            display: table-cell;
        }

        &__image {
            img {
                width: $cart-items-image__width--large;
                height: $cart-items-image__height--large;
            }
        }
    }
}
