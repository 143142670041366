@include min-screen($screen__m) {
    .filters {
        &__categories {
            border-bottom: 1px solid $color--grey;
            margin-top: 14rem;
        }
    }
}

@include max-screen($screen__m) {
    .catalogsearch-result-index .sidebar-main .filters {
        margin: 0;
    }
}
