$banner__color: $color--white;
$banner__height: 24.8rem;
$banner__height--desktop: 32.8rem;

$banner-content__max-width: 88rem;
$banner-content__padding-left: 1rem;
$banner-content__padding-right: 1rem;
$banner-content__align-items: center;
$banner-content__justify-content: center;

$banner-content__padding-left--desktop: 2rem;
$banner-content__padding-right--desktop: 2rem;

$banner-title__font-family: $font-family__default;
$banner-title__font-size: 2.8rem;
$banner-title__font-weight: $font-weight__default;
$banner-title__spacing: $spacing__m;

$banner-title__font-size--desktop: 4.8rem;
$banner-title__font-weight--desktop: $font-weight__default;
$banner-title__spacing--desktop: $spacing__m;

$banner-image__filter: brightness(1);
$banner-small__border-radius: 3px;
$banner-small__font-size: 1.9rem;
$banner-small__font-size--desktop: 2.8rem;
$banner-small__height: 18rem;
$banner-small__height--desktop: 25.8rem;
