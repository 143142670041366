.account-page-before-login {
}

@include min-screen($screen__m) {
    .account-page-before-login {
        &__actions {
            display: flex;
            justify-content: space-between;
        }
    }
}
