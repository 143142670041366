.header-logo {
    &__image {
        height: $header-logo-image__height;
        width: $header-logo-image__width;
    }

    &__logo-text {
        border: 0;
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

@include min-screen($screen__m) {
    .header-logo {
        &__image {
            height: $header-logo-image__height--desktop;
            width: $header-logo-image__width--desktop;
        }
    }
}
