$product-list__gap-x: 2rem !default;
$product-list__gap-y: 2rem !default;

$product-list__gap-x__xs: $product-list__gap-x !default;
$product-list__gap-y__xs: $product-list__gap-y !default;
$product-list__gap-x__s: $product-list__gap-x !default;
$product-list__gap-y__s: $product-list__gap-y !default;
$product-list__gap-x__m: $product-list__gap-x !default;
$product-list__gap-y__m: $product-list__gap-y !default;
$product-list__gap-x__l: $product-list__gap-x !default;
$product-list__gap-y__l: $product-list__gap-y !default;
$product-list__gap-x__xl: $product-list__gap-x !default;
$product-list__gap-y__xl: $product-list__gap-y !default;
$product-list__gap-x__xxl: $product-list__gap-x !default;
$product-list__gap-y__xxl: $product-list__gap-y !default;

$product-list__direction--xs: column !default;
$product-list__direction--s: row !default;

$product-list__items-per-row--xs: 1 !default;
$product-list__items-per-row--s: 1 !default;
$product-list__items-per-row--m: 2 !default;
$product-list__items-per-row--l: 3 !default;
$product-list__items-per-row--xl: 4 !default;
$product-list__items-per-row--xxl: 6 !default;

$product-list-item__padding: 0 1rem !default;
