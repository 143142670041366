$product-attributes--background-color: $color--light-grey !default;
$product-attributes--margin: 2rem 0 0 0 !default;
$product-attributes--line-height: $line-height__default !default;

$product-attributes-title--font-size: $font-size--default !default;
$product-attributes-title--padding: 1rem 2rem !default;
$product-attributes-title--font-weight: $font-weight--bold !default;
$product-attributes-title--text-align: left !default;
$product-attributes-title--width: inherit !default;

$product-attributes-value--font-size: $font-size--default !default;
$product-attributes-value--padding: 1rem 2rem !default;
$product-attributes-value--font-weight: $font-weight--default !default;
$product-attributes-value--text-align: left !default;
$product-attributes-value--width: inherit !default;
