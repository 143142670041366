// modal popup
// modal sidedrawer

.modal-is-open {
    overflow-y: hidden;

    @supports (-webkit-overflow-scrolling: touch) {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

.modal {
    $self: &;

    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-x: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $modal__z-index;

    &__overlay {
        background: $modal-overlay__background;
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        transition: opacity $transition__default;
        width: 100%;
    }

    &__modal {
        background: $modal__background-color;
        max-width: $modal__max-width;
        opacity: 0;
        overflow: hidden;
        padding: $modal__padding;
        position: relative;
        pointer-events: none;
        transition: opacity $modal__transition, transform $modal__transition;
        z-index: $modal__z-index + 1;
    }

    &__content {
        display: none;
    }

    &__title {
        color: $modal-title__color;
        font-family: $modal-title__font-family;
        font-size: $modal-title__font-size;
        margin: $modal-title__margin;
    }

    .modal-is-open &.is-open {
        opacity: 1;
        overflow-y: auto;
        pointer-events: inherit;

        #{$self}__overlay {
            opacity: 1;
            pointer-events: inherit;
        }

        #{$self}__modal {
            opacity: 1;
            pointer-events: inherit;
            transform: translateY(0);
        }

        #{$self}__content {
            display: block;
        }
    }

    &__close {
        align-items: center;
        cursor: pointer;
        display: flex;
        position: absolute;
        right: $modal-close__right;
        top: $modal-close__top;
    }

    &__close-label {
        @if $modal-close-label__show == false {
            display: none;
        }

        color: $modal-close-label__color;
        margin-right: 1rem;
    }

    &__icon-close {
        fill: $modal-close-icon__fill;
        height: $modal-close-icon__height;
        width: auto;
    }

    img {
        height: auto;
        max-width: 100%;
    }
}

.modal--popup {
    $self: '.modal';

    #{$self}__modal {
        border-radius: $modal__border-radius;
        margin: $modal__margin;
        transform: translateY(-10vh);
    }
}

.modal--sidedrawer {
    $self: '.modal';

    &[data-origin='left'] {
        justify-content: flex-start;

        #{$self}__modal {
            transform: translateX(-#{$modal-sidedrawer__width});
        }
    }

    &[data-origin='right'] {
        justify-content: flex-end;

        #{$self}__modal {
            transform: translateX(#{$modal-sidedrawer__width});
        }
    }

    &[data-origin='left'],
    &[data-origin='right'] {
        .modal-is-open & {
            #{$self}__modal {
                transform: translateX(0);
            }
        }

        #{$self}__modal {
            height: 100%;
            max-width: $modal-sidedrawer__max-width;
            width: $modal-sidedrawer__width;
        }
    }

    &[data-origin='top'] {
        align-items: flex-start;

        #{$self}__modal {
            transform: translateY(-100%);
        }
    }

    &[data-origin='bottom'] {
        align-items: flex-end;

        #{$self}__modal {
            transform: translateY(100%);
        }
    }

    &[data-origin='top'],
    &[data-origin='bottom'] {
        .modal-is-open & {
            #{$self}__modal {
                transform: translateY(0);
            }
        }

        #{$self}__modal {
            max-height: calc(100% - 5rem);
            width: 100%;
        }
    }
}

@include min-screen($screen__m) {
    .modal--mobile-only {
        $self: '.modal';

        border-radius: inherit;
        display: inherit;
        height: inherit;
        left: inherit;
        margin: inherit;
        pointer-events: inherit;
        position: inherit;
        top: inherit;
        width: inherit;
        z-index: inherit;

        &[data-origin='left'],
        &[data-origin='right'],
        &[data-origin='top'],
        &[data-origin='bottom'] {
            justify-content: inherit;
        }

        #{$self}__modal,
        &[data-origin='left'] #{$self}__modal,
        &[data-origin='right'] #{$self}__modal,
        &[data-origin='top'] #{$self}__modal,
        &[data-origin='bottom'] #{$self}__modal {
            background: inherit;
            max-width: inherit;
            opacity: inherit;
            overflow: inherit;
            padding: inherit;
            position: inherit;
            pointer-events: inherit;
            transform: inherit;
            transition: inherit;
            width: inherit;
            z-index: inherit;
        }

        #{$self}__title,
        #{$self}__overlay,
        #{$self}__close {
            display: none;
        }

        #{$self}__content {
            display: inherit;
        }
    }
}
