.category-description {
    $self: &;

    &__link-arrow {
        fill: $category-description-link-arrow__color;
        margin: $category-description-link-arrow__margin;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        text-decoration: $category-description-link__text-decoration;

        &:hover #{$self}__link-arrow {
            fill: $color--default;
        }
    }
}