.sidedrawer {
    $self: &;

    &__header {
        background: $sidedrawer__background;
    }

    &__header-icon {
        fill: $sidedrawer-header-icon__color;
    }

    &__pages-list {
        background: $sidedrawer-pages-list__background;
    }

    &__category-list {
        padding: $sidedrawer-category-list__padding;
    }

    &__category-link {
        font-weight: $font-weight__default;
        padding: $sidedrawer-category-link__padding;

        &:first-item {
            border-top: 1px solid $color--light-grey;
        }
    }

    &__category-arrow {
        height: $sidedrawer-category-arrow__height;
    }

    &__category-item {
        &--show-all {
            #{$self}__category-link--no-child {
                font-weight: $font-weight__bold;
            }
        }
    }
}

@include min-screen($screen__m) {
    .sidedrawer-overlay {
        display: block;
    }
    .sidedrawer {
        display: block;

        &__cross {
            display: block;
        }
    }
}


@include min-screen($screen__l) {
    .sidedrawer-overlay {
        display: none;
    }
    .sidedrawer {
        display: none;

        &__cross {
            display: none;
        }
    }
}
