.summary-totals {
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th,
    td {
        padding: 1rem 0;
    }

    th {
        font-weight: $font-weight__regular;
        text-align: left;
    }

    td {
        text-align: right;
    }
    .totals-tax {
        th,
        td {
            padding: $total-tax--padding;
        }
    }

    .grand.totals {
        th,
        td {
            border-top: $grand-total--border;
            padding: $grand-total--padding;
        }

        td {
            font-size: $grand-total--fontsize;
            font-weight: bold;
        }
    }
}
