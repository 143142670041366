$review-vote__star-size: 3rem;
$review-vote__star-color--empty: $color--grey;
$review-vote__star-color--full: $color--yellow;

$review-stars__color--empty: $color--grey;
$review-stars__color--full: $color--yellow;
$review-stars__color--loading: $color--grey;
$review-stars__width: 2rem;

$review-form__border-top: 0;

$review-form__flex-direction: column;

$review-detail__loading-color: $color--light-grey;
$review-detail__separator-color: $color--light-grey;
