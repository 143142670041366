$price-box__align-items: normal !default;

$regular-price__color: $color--black !default;
$regular-price__font-size: $font-size__l !default;
$regular-price__font-weight: $font-weight__bold !default;
$regular-price__line-height: $line-height__l !default;

$old-price__color: $color--grey !default;
$old-price__font-size: $font-size__s !default;
$old-price__font-weight: $font-weight__default !default;
$old-price__line-height: $line-height__s !default;

$special-price__color: $color--purple !default;
$special-price__font-size: $font-size__l !default;
$special-price__font-weight: $font-weight__bold !default;
$special-price__line-height: $line-height__l !default;

$pdp--old-price__font-size: inherit !default;
$pdp--special-price__font-size: inherit !default;
