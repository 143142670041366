.block.account-nav {
    .item {
        font-size: $account-nav-item__font-size;
        color: $account-nav-item__color;

        &:last-child {
            border-bottom: $account-nav-item__border;
        }
    }
}

@include min-screen($screen__m) {
    .block.account-nav {
        .item {
            a,
            strong {
                &:after {
                    display: none;
                }
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
