.brand-list {
    background: $brand-list__background;
    border-radius: $brand-list__border-radius;
    padding: $brand-list__padding;

    &__list-container {
        background: $brand-list-container__background;
        border-radius: $border-radius__default;
        margin: $brand-list-container__margin;
        overflow-x: auto;
        padding: $brand-list-container__padding;
        scroll-snap-type: x mandatory;
    }

    &__list {
        align-items: $brand-list-list__align-items;
        display: $brand-list-list__display;
        justify-content: $brand-list-list__justify-content;
        padding: $brand-list-list__padding;
    }

    &__item {
        align-items: $brand-list-item__align-items;
        display: $brand-list-item__display;
        flex: $brand-list-item__flex;
        justify-content: $brand-list-item__justify-content;
        scroll-snap-align: start;
    }

    &__item + &__item {
        margin-left: $brand-list-item__margin-left;
    }

    &__button-wrapper {
        display: flex;
        justify-content: $brands-list-button-wrapper__justify-content;
    }

    &__item {
        svg,
        img {
            height: auto;
            width: 100%;
        }
    }
}

@include min-screen($screen__m) {
    .brand-list {
        &__list {
            padding: $brand-list-list__padding--desktop;
        }

        &__item {
            flex: $brand-list-item__flex--desktop;
            justify-content: $brand-list-item__justify-content--desktop;
        }

        &__item + &__item {
            margin-left: $brand-list-item__margin-left--desktop;
            padding-left: $brand-list-item__padding-left--desktop;
        }
    }
}