.category-description {
    margin: $category-description--margin;
    border-bottom: $category-description--border-bottom;
    padding: $category-description--padding;

    &--bottom {
        margin: $category-description--bottom--margin;
        border-bottom: $category-description--bottom--border-bottom;
    }
}
