.cookie-notice {
    background: $cookie-notice__background;
    width: 100%;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 5;

    &__content {
        @include page-alignment();
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: $cookie-notice__padding;
    }

    &__button {
        margin: $cookie-notice-button__margin;
    }

    &__link {
        color: $cookie-notice-link__color;
        text-decoration: $cookie-notice-link__text-decoration;
    }

    &__text {
        color: $cookie-notice-text__color;
        flex: 1 0 auto;
        padding: $cookie-notice-text__padding;
        max-width: 100%;
    }
}
