$table__border-color: $color--light-grey !default;

/**
 * Table specifications
 */
$table-specifications-odd__background: $color--light-grey !default;

$table-specifications-row__padding: 0.5rem 1rem !default;

$table-specifications-label__width: 50% !default;
$table-specifications-label__width--desktop: 25% !default;

$table-specifications-th__font-weight: $font-weight__medium !default;
$table-specifications-td__font-weight: $font-weight__regular !default;

/**
 * Table totals for cart and checkout
 */
$table-totals__spacing: 0.6rem !default;
$table-totals__border-bottom: 0 !default;
$table-totals__font-weight: $font-weight__default !default;

$table-totals-row__border-bottom: 0 !default;

$table-totals-price__color: $color--blue !default;
$table-totals-price__color-free: $color--green !default;

$table-totals-grand-total__border-top: 1px solid $color--grey !default;
$table-totals-grand-total__font-size: $font-size__l !default;
