.header-checkout {
    &__back-to-shop-link {
        display: flex;
        align-items: center;
    }

    &__icon-arrow-left {
        margin-right: 0.6rem;
    }
}
