$navigation-second-level__back-border-bottom: 1px solid $color--light-grey;
$navigation-second-level__back-color: $color--dark-grey;
$navigation-second-level__title-font-size: $font-size__xl;

.navigation-second-level {
    &__back-to-previous {
        align-items: center;
        border-bottom: 1px solid $navigation-second-level__back-border-bottom;
        color: $navigation-second-level__back-color;
        cursor: pointer;
        display: flex;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
    }

    &__back-to-previous-icon {
        fill: currentColor;
        height: 1rem;
        margin-right: 0.4rem;
        width: auto;
    }

    &__title {
        font-size: $navigation-second-level__title-font-size;
        margin-bottom: 1rem;
    }

    &__list {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    &__link {
        display: block;
        padding: 0.5rem 0;
    }
}
