.short-summary {
    display: flex;
    font-size: $short-summary__font-size;
    padding: $short-summary__padding;

    &__estimation {
        margin-right: 1rem;
    }

    &__label {
        color: $short-summary-label__color;
        display: inline-block;
        margin-right: 0.5rem;
    }

    &__price {
        color: $short-summary-price__color;
    }

    &__show-more {
        align-items: center;
        border-radius: $short-summary-show-more__border-radius;
        background: $short-summary-show-more__background;
        color: $short-summary-show-more__color;
        cursor: pointer;
        display: flex;
        font-size: $short-summary-show-more__font-size;
        font-weight: $short-summary-show-more__font-weight;
        height: $short-summary-show-more__height;
        justify-content: center;
        width: $short-summary-show-more__width;
    }
}

@include min-screen($screen__m) {
    .short-summary {
        display: none;
    }
}
