.address-edit {
    &__name {
        display: grid;
        grid-gap: $spacing__m;
        grid-template-columns: 1fr 1fr;
    }

    &__address-fields {
        display: grid;
        grid-gap: $spacing__m;
        grid-template-columns: 3fr 1fr 1fr;
    }

    &__zip-city {
        display: grid;
        grid-gap: $spacing__m;
        grid-template-columns: 1fr 1fr;
    }

    &__region {
        display: none;

        &.required {
            display: block;
        }
    }
}

@include min-screen($screen__s) {
    .address-edit {
        &__address-fields {
            grid-template-columns: 2fr 1fr 1fr;
        }
    }
}
