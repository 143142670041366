$swatch-attribute__margin-bottom: 2rem !default;

$swatch-attribute-label__font-weight: $font-weight__bold !default;
$swatch-attribute-label__margin-bottom: 0.4rem !default;

$swatch-option-visual__border-radius: 50% !default;
$swatch-option-visual__border--hover: 1px solid $color--light-grey !default;
$swatch-option-visual__border--selected: 1px solid $color--grey !default;

$swatch-option-text__background: $color--white !default;
$swatch-option-text__border: 1px solid $color--light-grey !default;
$swatch-option-text__border-radius: $border-radius__default !default;
$swatch-option-text__color: $text__color !default;
$swatch-option-text__margin: 0 0 1rem 1rem !default;
$swatch-option-text__padding: 1rem 1rem 1rem 4.2rem !default;

$swatch-option-text-selected__background: $color--white !default;
$swatch-option-text-selected__border: 1px solid $color--dark-grey !default;
$swatch-option-text-selected__color: $text__color !default;

$swatch-option-text-hover__border: 1px solid $color--grey !default;
$swatch-option-text-hover__color: $text__color !default;

$swatch-option-text-disabled__opacity: 0.5 !default;

$swatch-option-text-radio__background: $form-radio__background !default;
$swatch-option-text-radio__border-width: $form-radio__border-width !default;
$swatch-option-text-radio__border-color: $form-radio__border-color !default;
$swatch-option-text-radio__box-shadow: $form-radio__box-shadow !default;
$swatch-option-text-radio__height: $form-radio__height !default;
$swatch-option-text-radio__width: $form-radio__width !default;
$swatch-option-text-radio__top: 1.2rem !default;
$swatch-option-text-radio__left: 1rem !default;

$swatch-option-text-radio__border-radius: $form-radio__border-radius !default;
$swatch-option-text-radio__border-color--checked: $form-radio__border-color--checked !default;
$swatch-option-text-radio__background-color--checked: $form-radio__background-color--checked !default;
$swatch-option-text-radio__background-color--hover: $form-radio__background-color--hover !default;
$swatch-option-text-radio__border-color--hover: $form-radio__border-color--hover !default;
$swatch-option-text-radio__border-color--checked: $form-radio__border-color--checked !default;
$swatch-option-text-radio__border-radius--inner: $form-radio__border-radius--inner !default;
$swatch-option-text-radio__height--inner: $form-radio__height--inner !default;
$swatch-option-text-radio__width--inner: $form-radio__width--inner !default;
$swatch-option-text-radio__top--inner: 1.6rem !default;
$swatch-option-text-radio__left--inner: 1.4rem !default;
