$cart-remove-icon__height: 1.4rem;
$cart-item__grid-template-columns: 7.5rem auto auto;
$cart-item__grid-template-columns--desktop: 7.5rem auto auto;
$cart-items-image__width--large: 7.5rem;
$cart-items-image__height--large: 7.5rem;
$cart-item__padding: 2rem 0;
$cart-items__margin--desktop: 0;

$cart-items-image__width: 7.5rem;
$cart-items-image__height: 7.5rem;
