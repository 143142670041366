.product-list,
.product-grid {
    &__item {
        #amasty-shopby-product-list {
            height: 100%;
        }

        border-left: 1px solid $color--grey;
        padding: 1rem;

        &:hover {
            background: rgba($color--light-blue, 0.2);
        }
    }

    &__actions {
        text-align: center;
    }

    &__button {
        margin: 3.4rem 0 0 0;
    }
}

@include max-screen($screen__s) {
    .product-list {
        &__item {
            border-left: 0;

            &:hover {
                background: none;
            }
        }
    }
}

@include screen($screen__s, $screen__l) {
    .product-list {
        &__item {
            width: 50%;

            &:nth-child(2n + 1) {
                border-left: 0;
            }
        }
    }
}

@include screen($screen__l, $screen__xl) {
    .product-list {
        &__item {
            width: 33%;

            &:nth-child(3n + 1) {
                border-left: 0;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .product-list {
        &__item {
            width: 25%;

            &:nth-child(4n + 1) {
                border-left: 0;
            }
        }
    }
}
