.shipping-methods {
    &__item {
        border: $shipping-methods__border;
        border-radius: $shipping-methods__border-radius;
        cursor: pointer;
        padding: $shipping-methods__padding;
        margin-bottom: $shipping-methods__margin-bottom;

        &:last-child {
            margin-bottom: $shipping-methods__margin-bottom--last-child;
        }

        &:hover,
        &.is-selected {
            background: $shipping-methods-selected__background;
            border: $shipping-methods-selected__border;
        }
    }

    &__label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }

    &__method,
    &__carrier {
        margin-right: 0.4rem;
    }

    &__carrier {
        color: $shipping-methods-carrier__color;
        flex: 1 0 auto;
    }

    @if $shipping-methods__show-carrier == false {
        &__carrier {
            display: none;
        }
    }
}
