.header-top__inner {
    height: $header-top-inner__height;
    overflow: $header-top-inner__overflow;
}

@media screen and (max-width: 786px) {
    .header-top__inner {
        display: $header-top-inner__display-mobile;
    }
}

.header-inner {
    @include page-alignment();

    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;

    .search {
        display: none;
    }
}

.header-primary {
    .header-logo {
        width: 20rem;
        padding-left: 1rem;
    }

    &--checkout {
        .header-logo {
            flex: 1 0 auto;
            text-align: center;
        }
    }
}

.header-search {
    padding: $header-search__padding;
}

@include min-screen($screen__m) {
    .header-inner {
        padding: 1.8rem 2rem;

        .search {
            display: flex;
        }
    }

    .header-primary {
        .header-logo {
            width: 37.5rem;
            padding: 0;
        }

        .search {
            padding-top: 1rem;
        }

        &--checkout {
            .header-logo {
                flex: 1 0 auto;
                text-align: left;
            }
        }
    }

    .header__navigation {
        display: none;
        .header-inner {
            padding: 1.8rem 2rem 0rem;
        }

        &.header-navigation {
            border-bottom: 1px solid $color--dark-blue;
            z-index: 1;
        }
    }
}
@include min-screen($screen__l) {
    .header__navigation {
        display: block;
    }
}
