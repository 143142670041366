/**
 * Primary button settings
 */
$button__background: $color--green !default;
$button__border: 0 !default;
$button__border-radius: 10rem !default;
$button__color: $color--white !default;
$button__font: $font-family__default !default;
$button__font-size: $font-size__default !default;
$button__font-weight: $font-weight__bold !default;
$button__height: 3rem !default;
$button__padding: 0 2rem !default;
$button-hover__color: $color--white !default;
$button-hover__background: $color__dark-green !default;
$button__shadow: 0 !default;
$button-hover__shadow: 0 !default;

/*
 * Secondary button settings
 */
$button-secondary__background: $color__primary !default;
$button-secondary__border: 0 !default;
$button-secondary__border-radius: 10rem !default;
$button-secondary__color: $color--white !default;
$button-secondary__font: $font-family__default !default;
$button-secondary__font-size: $font-size__default !default;
$button-secondary__font-weight: $font-weight__bold !default;
$button-secondary__height: 3rem !default;
$button-secondary__padding: 0 2rem !default;
$button-secondary-hover__color: $color--white !default;
$button-secondary-hover__background: $color__primary-dark !default;
$button-secondary__shadow: 0 !default;
$button-secondary-hover__shadow: 0 !default;

/**
 * Alternative button settings
 */
$button-alt__background: $color__primary !default;
$button-alt__border: 0 !default;
$button-alt__border-radius: 10rem !default;
$button-alt__color: $color--white !default;
$button-alt__font: $font-family__default !default;
$button-alt__font-size: $font-size__default !default;
$button-alt__font-weight: $font-weight__bold !default;
$button-alt__height: 3rem !default;
$button-alt-hover__color: $color--white !default;
$button-alt-hover__background: $color__primary-dark !default;

/*
 * Ghost button settings
 */
$button-ghost__enabled: true !default;
$button-ghost__background: transparent !default;
$button-ghost__border: 2px solid $color--black !default;
$button-ghost__border-radius: 2px !default;
$button-ghost__color: $color--black !default;
$button-ghost__font: $font-family__default !default;
$button-ghost__font-size: $font-size__default !default;
$button-ghost__font-weight: $font-weight__bold !default;
$button-ghost__height: 3rem !default;
$button-ghost__padding: 0 2rem !default;
$button-ghost-hover__color: $color--white !default;
$button-ghost-hover__background: $color--black !default;
$button-ghost-hover__shadow: 0 !default;

/*
 * Ghost alternative button settings
 */
$button-ghost-alt__enabled: false !default;
$button-ghost-alt__background: transparent !default;
$button-ghost-alt__border: 2px solid $color--white !default;
$button-ghost-alt__border-radius: 2px !default;
$button-ghost-alt__color: $color--white !default;
$button-ghost-alt__font: $font-family__default !default;
$button-ghost-alt__font-size: $font-size__default !default;
$button-ghost-alt__font-weight: $font-weight__bold !default;
$button-ghost-alt__height: 3rem !default;
$button-ghost-alt__padding: 0 2rem !default;
$button-ghost-alt-hover__color: $color--black !default;
$button-ghost-alt-hover__background: $color--white !default;
$button-ghost-alt-hover__shadow: 0 !default;
