$filters__animation: 500ms ease-in-out !default;
$filters__spacing-right: 5rem !default;
$filters__background: $color--white !default;

$filters__header-enabled: true !default;

// Settings if header is set
$filters-header__background: $color--primary !default;
$filters-header__color: $color--white !default;
$filters-header__height: 5rem !default;
$filters-header__grid-template-columns: auto 1fr auto !default;
$filters-header__grid-gap: 1rem !default;
$filters-header__padding: 0 2rem !default;

$filters-header-title__font-weight: $font-weight__bold !default;

$filters-header-link__color: currentColor !default;
$filters-header-link__text-decoration: underline !default;

$filters-close__fill: currentColor !default;
$filters-close__height: 2.6rem !default;

// Settings if there is no header set
$filters__cross-color: $color--white !default;

$filters-content-inner__padding: 2rem 2rem 8rem !default;

$filters-show-results__height: 6rem !default;
$filters-show-results__padding: 1rem !default;
$filters-show-results__z-index: 5 !default;

$filters-show-results__shadow: true !default;

$filters-overlay__background: rgba($color--deep-black, 0.75) !default;
