.brand-list {
    border-top: 1px solid $color--black;
    border-bottom: 1px solid $color--black;
    @include page-alignment();

    .brand-list__item + .brand-list__item {
        margin-left: 0;
        padding-left: 0;
    }

    &__item {
        svg,
        img {
            max-width: 10.5rem;
            min-height: 10rem;
        }
    }

    &__list-container {
        @include page-alignment();
        display: inline-block;
        overflow-x: hidden;
        scroll-snap-type: none;
    }

    &__list {
        display: grid;
        grid-auto-columns: calc((100% - 2rem) / 3);
        grid-auto-flow: column;
        grid-gap: 1rem;
        max-height: 6.5rem;
        padding: 0;
    }

    &__slider-wrapper {
        margin-bottom: 1rem;
        position: relative;
    }

    &__slider-wrapper:after {
        background: white;
        bottom: 0;
        content: '';
        display: block;
        height: 3rem;
        position: absolute;
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .brand-list {
        &__item {
            max-width: 12rem;
        }
    }
}
