$product-card-name__line-height: 2.2rem;
$product-card-name__margin-bottom: 2rem;
$product-card-name__margin-bottom--desktop: 4rem;
$product-card-info__padding: 0 1rem;
$product-card-info__padding--desktop: 0 1rem;
$product-card-price__margin: 0;
$product-card-image__background: $color--white;
$product-card-image__padding: 0;
$product-card-image__padding--desktop: 0;
$product-card-image__margin-bottom: 0.5rem;
$product-card__padding--mobile: 0;
