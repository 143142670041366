// Do not change this font, is used for default element styling
$font-family__system: system-ui, -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;

html {
    font-size: 62.5%; // 1.6rem is equal to 16px
}

body {
    color: $text__color;
    font-family: $font-family__default;
    font-size: $font-size__default;
    font-weight: $font-weight__default;
    line-height: $line-height__default;
}

@mixin hyperlink() {
    color: $text__color-link;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $text__color-link--hover;
        text-decoration: underline;
        text-decoration-skip-ink: auto;
    }

    &:focus,
    &:active {
        color: $text__color-link--active;
    }

    &::selection {
        background: $text__color-link;
        color: $text__color-selection;
        text-decoration: none;
    }
}

a {
    @include hyperlink();
}

h1,
.h1 {
    font-size: $font-size__xxl;
    font-weight: $font-weight__bold;
    line-height: $line-height__xxl;
    margin: $margin__xxl;
}

h2,
.h2 {
    font-size: $font-size__xl;
    font-weight: $font-weight__bold;
    line-height: $line-height__xl;
    margin: $margin__xl;
}

h3,
.h3 {
    font-size: $font-size__l;
    font-weight: $font-weight__bold;
    line-height: $line-height__l;
}

h4,
h5,
.h4,
.h5 {
    font-size: $font-size__m;
    line-height: $line-height__m;
}
