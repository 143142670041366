.toolbar {
    &__sort-by select {
        font-size: $font-size__default;
    }

    &__toggle-filters {
        margin-bottom: 2rem;

        button {
            background-color: $button-secondary__background;
            height: 3.6rem;
            width: 16rem;

            &:hover {
                background-color: $button-secondary-hover__background;
            }
        }
    }

    &-sorter {
        display: flex;
    }
}

.order-products-toolbar {
    border-top: 1px solid $color--light-grey;
}

@include max-screen($screen__m) {
    .toolbar {
        &__sort-by {
            flex: 0 0 calc(100% - (#{$spacing__s} / 2));
        }
    }
}

@include min-screen($screen__m) {
    .toolbar {
        &--top {
            border-bottom: 1px solid $color--light-grey;
        }

        &-sorter {
            display: grid;
        }
    }

    .order-products-toolbar {
        .pager {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .limiter {
            align-items: center;
            display: flex;
            flex-basis: 22rem;
            justify-content: space-between;

            .limiter-label,
            .limiter-options {
                margin-right: 1rem;
            }

            .limiter-text {
                white-space: nowrap;
            }

            .limiter-options {
                font-size: $font-size__default;
                height: inherit;
            }
        }
    }
}
