$cart-items__show-grid--desktop: true !default;

$cart-items__margin: 2rem 0 4rem !default;
$cart-items__margin--desktop: 2rem 0 4rem !default;

$cart-item__border: 1px solid $color--light-grey !default;
$cart-item__padding: 1rem !default;
$cart-item__padding--small: 0rem !default;
$cart-item__padding--first-item: 0 !default;
$cart-item__padding--last-item: 0 !default;
$cart-item__grid-template-columns: 10rem auto auto !default;
$cart-item__grid-template-columns--desktop: 10rem auto auto !default;
$cart-item__grid-gap: 1rem !default;
$cart-item__grid-gap--desktop: 2rem !default;
$cart-item__padding: 4rem 0 !default;

$cart-items-qty__padding-small: 2rem 0 !default;
$cart-items-qty__grid-column-start: 1 !default;
$cart-items-qty__grid-column-start--desktop: 2 !default;
$cart-items-qty__grid-row-start: 2 !default;

$cart-item-title__font-weight: $font-weight__regular !default;
$cart-item-title__color: $color--default !default;

$cart-stock-status__margin: 0 !default;
$cart-stock-status-icon__margin: 0 !default;

$cart-remove-icon__height: 2rem !default;

$cart-items-image-wrapper__grid-column-start: 1 !default;
$cart-items-image-wrapper__grid-row: 1 !default;
$cart-items-image-wrapper__grid-row--desktop: 1 / span 2 !default;

$cart-items-price-qty-info__padding--large: 0rem !default;

$cart-items-info__padding: 0 0 0 0 !default;
$cart-items-info__padding--small: 0 0 0 0 !default;
$cart-items-info__grid-column: 2 !default;
$cart-items-info__grid-column--desktop: 2 !default;
$cart-items-info__border: 0 !default;

$cart-items-image__width: 8rem !default;
$cart-items-image__width--large: 12rem !default;
$cart-items-image__height: 8rem !default;
$cart-items-image__height--large: 12rem !default;
$cart-items-image__radius: 0 !default;

$cart-items-price__grid-column-start--xs: 2 !default;
$cart-items-price__grid-column-end--xs: -1 !default;
$cart-items-price__grid-row-start--xs: 3 !default;
$cart-items-price__grid-row-end--xs: 4 !default;
$cart-items-price__justify-self--xs: left !default;

$cart-items-price__grid-column-end: -1 !default;
$cart-items-price__grid-column-start: 2 !default;
$cart-items-price__grid-row-end: 3 !default;
$cart-items-price__grid-row-start: 2 !default;
$cart-items-price__justify-self: right !default;
$cart-items-price__font-weight: $font-weight__default !default;

$cart-items-remove-item__grid-column-end: -1 !default;
$cart-items-remove-item__grid-row: 1 !default;
$cart-items-remove-item__justify-self: self-end !default;

$cart-items-message__color: $color--default !default;
$cart-items-message__font-weight: $font-weight__regular !default;
