// Color definitions
$color--white: rgb(255, 255, 255) !default;

$color--light-red: rgb(255, 236, 236) !default;
$color--red: rgb(255, 0, 0) !default;
$color--dark-red: rgb(187, 0, 0) !default;

$color--light-green: rgb(238, 255, 237) !default;
$color--green: rgb(58, 170, 53) !default;
$color--dark-green: rgb(11, 115, 6) !default;

$color--light-yellow: rgb(255, 248, 230) !default;
$color--yellow: rgb(250, 184, 3) !default;
$color--dark-yellow: rgb(144, 106, 0) !default;

$color--light-blue: rgb(140, 194, 226) !default;
$color--blue: rgb(74, 144, 226) !default;
$color--dark-blue: rgb(0, 58, 126) !default;

$color--light-purple: rgb(208, 171, 217) !default;
$color--purple: rgb(134, 53, 170) !default;
$color--dark-purple: rgb(87, 32, 110) !default;

$color--light-grey: rgb(237, 237, 237) !default;
$color--grey: rgb(201, 201, 201) !default;
$color--dark-grey: rgb(120, 121, 124) !default;

$color--black: rgb(31, 31, 31) !default;
$color--deep-black: rgb(0, 0, 0) !default; // Should only be used for shadows and opacity

// Default colors
$color__primary: $color--grey !default;
$color__primary-dark: $color--dark-grey !default;
$color__default: $color--black !default;

// Typography
$text__color: $color__default !default;
$text__color-link: $color--blue !default;
$text__color-link--hover: $color--dark-blue !default;
$text__color-link--active: $color--dark-blue !default;
$text__color-selection: $color--white !default;
$text__color-secondary: $color--dark-grey !default;

// Success, warning and errors
$success__color: $color--dark-green !default;
$success__border-color: $color--green !default;
$success__background-color: $color--light-green !default;

$warning__color: $color--dark-yellow !default;
$warning__border-color: $color--yellow !default;
$warning__background-color: $color--light-yellow !default;

$error__color: $color--dark-red !default;
$error__border-color: $color--red !default;
$error__background-color: $color--light-red !default;
