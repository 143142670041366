.attachments {
    &__item {
        margin-top: $product-page__attachment-item-margin-top;
    }
    &__icon svg {
        fill: #008bc1;
        height: $product-page__attachment-icon--height;
        vertical-align: middle;
    }
    &__link-label {
        margin-left: $product-page__attachment-link-label-margin-left;
        vertical-align: bottom;
    }
    &__link:hover .attachments__link-label {
        text-decoration: underline;
    }
}
