$toolbar-top__margin: 0 !default;
$toolbar-top__justify: space-between !default;
$toolbar-bottom__margin: 0 !default;

$toolbar-amount-top--display__mobile: none !default;
$toolbar-amount-bottom--display__mobile: flex !default;

$toolbar-amount-top__display: block !default;
$toolbar-amount-bottom__display: none !default;

$toolbar-sort-by--flex: 0 0 calc(50% - (#{$spacing__s} / 2)) !default;
$toolbar-sort-select--height: 4rem !default;
