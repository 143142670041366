input,
select,
textarea {
    color: $color__default;
    font-size: $font-size__l;
}

select {
    background-image: none;
    -webkit-appearance: button;
    padding-right: 0;
}

label {
    font-size: $font-size__l;
}

.field._required,
.field.required {
    >.label:after{
        content: '*';
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 5px;
    }
}
