.newsletter-subscribe {
    padding: $newsletter__padding;

    &__title {
        font-size: $newsletter-title__font-size;
        font-weight: $newsletter-title__font-weight;
        line-height: $newsletter-title__line-height;
        margin: $newsletter-title__margin;
    }

    &__form {
        align-items: $newsletter-form__align-items--mobile;
        display: flex;
        flex-direction: $newsletter-form__flex-direction--mobile;
        flex-wrap: $newsletter-form__flex-wrap--mobile;
        justify-content: $newsletter-form__justify-content--mobile;
    }

    &__input {
        border: $newsletter-input__border--mobile;
        height: $newsletter-input__height--mobile;
        margin: $newsletter-input__margin--mobile;
        width: $newsletter-input__width--mobile;
        max-width: $newsletter-input__max-width--mobile;
    }

    &__control {
        flex: $newsletter-control__flex;
        margin: $newsletter-control__margin--mobile;
    }
}

@include min-screen($screen__m) {
    .newsletter-subscribe {
        &__form {
            align-items: $newsletter-form__align-items--desktop;
            flex-direction: $newsletter-form__flex-direction--desktop;
            flex-wrap: $newsletter-form__flex-wrap--desktop;
            justify-content: $newsletter-form__justify-content--desktop;
        }

        &__control {
            margin: $newsletter-control__margin--desktop;
        }

        &__input {
            border: $newsletter-input__border--desktop;
            height: $newsletter-input__height--desktop;
            margin: $newsletter-input__margin--desktop;
            width: $newsletter-input__width--desktop;
            max-width: $newsletter-input__max-width--desktop;
        }
    }
}
