ul {
    padding: $list__padding;
    list-style: none;
    margin: $list__margins;

    li {
        padding: $list-item__padding;
        margin: $list-item__margin;
    }

    a {
        color: $list-item__link-color;
    }
}
