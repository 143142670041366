$account-form-button__width: 100% !default;
$account-form-button__margin: 0 0 1rem 0 !default;
$account-form-button__margin--new-customer: 0 0 3.4rem 0 !default; /* Use extra margin to align buttons on login page */

$account-form-content__margin: 0 0 2rem 0 !default;

$account-form-icon-arrow__fill: currentColor !default;
$account-form-icon-arrow__height: 1.2rem !default;

$account-form-icon-arrow-span__margin-left: 0.3rem !default;

$account-form-name__horizontal: true !default;
$account-form-name__spacing: 2rem !default;

$account-form-datepicker__margin: 0.5rem 0 0 0 !default;
