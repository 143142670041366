.add-to {
    $self: &;

    &__link {
        color: $add-to-link__color;
    }

    &__icon {
        fill: $add-to-icon__fill;
        height: $add-to-icon__height;
        position: relative;
        top: $add-to-icon__top;
        width: auto;
    }

    &__label {
        padding: $add-to-label__padding;
    }

    &--wishlist {
        #{$self}__link:hover {
            color: $add-to-link-hover__color--wishlist;
        }

        #{$self}__link:hover #{$self}__icon {
            fill: $add-to-icon-hover__fill--wishlist;
        }
    }

    &--compare {
        #{$self}__link:hover {
            color: $add-to-link-hover__color--compare;
        }

        #{$self}__link:hover #{$self}__icon {
            fill: $add-to-icon-hover__fill--compare;
        }
    }
}
