.links {
    display: none;
}

@include min-screen($screen__m) {
    .links {
        display: flex;
        font-size: $links__font-size;
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
            color: $links__color;
            margin: 0 $links__spacing 0 0;
            padding: 0;
            white-space: $links__white-space;

            &:hover {
                color: $links__color--hover;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__icon {
            fill: currentColor;
            height: 0.8em;
            width: auto;
        }
    }
}
