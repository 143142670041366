.product-grid {
    display: $product-grid__display--mobile;
    flex-direction: $product-grid__flex-direction--mobile;

    &__item {
        margin: $product-grid__margin--mobile;
    }
}

@include min-screen($screen__s) {
    .product-grid {
        display: $product-grid__display;
        grid-template-columns: $product-grid__grid-template-columns;
        grid-gap: $product-grid__grid-gap;

        &--vertical {
            grid-template-columns: $product-grid__grid-template-columns--vertical;
            grid-gap: $product-grid__grid-gap--vertical;
        }

        &__item {
            margin: $product-grid__margin;
        }
    }
}
