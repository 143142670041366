$pagination__background-color: transparant !default;
$pagination__border-radius: 50% !default;
$pagination__border: 0 !default;
$pagination__border--current: 0 !default;
$pagination__color: $color--black !default;
$pagination__font-size: $font-size--l !default;
$pagination__line-height: 5rem !default;
$pagination__height: 5rem !default;
$pagination__spacing: 0.5rem !default;
$pagination__width: 5rem !default;

$pagination__font-weight: $font-weight__default !default;

$pagination__background-color--hover: $color--dark-grey !default;
$pagination__color--hover: $color--white !default;

$pagination__background-color--current: $color--black !default;
$pagination__color--current: $color--white !default;

$pagination__icon-color: $color--black !default;
$pagination__icon-height: 3rem !default;
$pagination__icon-width: 3rem !default;
$pagination__icon-color--hover: $color--white !default;
