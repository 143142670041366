.shipping-address-item {
    border: $shipping-address-item__border;
    padding: $shipping-address-item__padding;
    position: relative;
    margin-bottom: $shipping-address-item__margin-bottom;
    border-radius: $shipping-address-item__border-radius;

    &:hover {
        background: $shipping-address-item-hover__background;
        border: $shipping-address-item-hover__border;
        cursor: pointer;
    }

    &:before {
        border: $form-radio__border-width solid $form-radio__border-color;
        border-radius: $form-radio__border-radius;
        box-shadow: inset 0 0 0.2rem rgba($form-radio__border-color, 0.2);
        content: '';
        display: block;
        height: $form-radio__height;
        left: 2rem;
        position: absolute;
        top: 2rem;
        width: $form-radio__width;
    }

    &.selected-item {
        background: $shipping-address-item-selected__background;
        border: $shipping-address-item-selected__border;

        &:before {
            border-color: $form-radio__border-color--checked;
        }

        &:after {
            background: $form-radio__background-color--checked;
            border-radius: $form-radio__border-radius--inner;
            content: '';
            display: block;
            height: $form-radio__height--inner;
            left: 2.4rem;
            position: absolute;
            top: 2.4rem;
            width: $form-radio__width--inner;
        }
    }
}
