.customer-service {
    &__contact {
        background: $color--white;
        border-radius: $border-radius__default;
        padding: 2.5rem 3.5rem;
    }

    &__block {
        margin-bottom: 3rem;
    }
}

@include min-screen($screen__m) {
    .customer-service {
        &__store-info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}