.message {
    padding: 0;
}

.message.success,
.message.info,
.message.notice,
.message.warning,
.message.error {
    padding: $message__padding;
}

.message.info {
    background: $info__background-color;
    color: $info__color;
}
