body {
    background: $layout__background;
}

@mixin page-alignment() {
    margin-left: auto;
    margin-right: auto;
    max-width: $layout__max-width;
    padding-left: $layout__padding-inline;
    padding-right: $layout__padding-inline;
    width: 100%;

    @include min-screen($screen__m) {
        padding-left: $layout__padding-inline--desktop;
        padding-right: $layout__padding-inline--desktop;
    }
}

@mixin reset-page-alignment() {
    max-width: inherit;
    padding-left: inherit;
    padding-right: inherit;
}

.breadcrumbs,
.page-header .header.panel,
.header.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.page-main,
.footer.content {
    @include page-alignment();
}

@if $layout__homepage-full-width == true {
    .cms-index-index .page-main {
        @include reset-page-alignment();

        margin-bottom: 0;
        margin-top: 0;
    }
}

.page-main {
    margin-bottom: $spacing__s;
    margin-top: $spacing__s;
    min-height: 60rem;
}

.columns {
    display: flex;
    flex-direction: column;
}

.page-layout-1column {
    .column.main {
        width: 100%;
    }
}

.page-layout-2columns-left {
    .sidebar-main {
        order: 1;
    }

    .sidebar-additional {
        display: none;
    }

    .column.main {
        order: 2;
    }
}

.breadcrumbs {
    margin-bottom: $spacing__s;
    margin-top: $spacing__s;
}

@include min-screen($screen__m) {
    .page-main {
        margin-bottom: $spacing__m;
        margin-top: $spacing__m;
    }

    .page-layout-2columns-left .columns {
        display: grid;
        grid-template-columns: $layout__sidebar-width 1fr;
        grid-gap: $spacing__m;
    }

    .breadcrumbs {
        margin-top: $spacing__m;
    }
}
