.footer {
    &__block {
        h4,
        .h4 {
            font-size: 1.8rem;
            margin: 0.5rem 0 1.5rem 0;
        }
    }
    &__block ul li {
        span {
            display: inline-block;
        }
    }

    &__usps {
        padding-left: 0;
        padding-right: 0;
    }

    &__bottom {
        @include page-alignment();
        display: flex;
        flex-wrap: wrap;
        padding: 1.8rem 2rem;
    }

    &__payment {
        justify-content: flex-start;
        line-height: 4rem;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    &__shipping {
        display: flex;
        justify-content: flex-start;
        line-height: 4rem;
        width: 100%;

        .shipping-icons__item {
            display: inline-block;
            margin: 0 2rem;

            &:first-child {
                margin: 0 2rem 0 0;
            }
        }

        .shipping-icons__icon {
            height: 2rem;
            width: 6rem;
            &.shipping-icons__icon--postnl {
                height: 2rem;
                width: auto;
            }
        }
    }
}

@include max-screen($screen__m) {
    .footer {
        &__blocks {
            padding: 0.5rem 1rem 1rem 1rem;
        }
        &__shipping {
            justify-content: center;
        }

        .payment-icons {
            justify-content: center;

            &__item {
                margin: 0 2rem 0 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer {
        &__block {
            h4,
            .h4 {
                margin: $footer-block-title__margin--desktop;
            }
        }
        &__payment {
            width: 50%;
        }
        &__shipping {
            width: 50%;
        }

        &__top {
            display: none;
        }
    }
}
