$cart-discount--border-bottom: 1px solid $color--light-grey !default;
$cart-discount--border-top: 1px solid $color--light-grey !default;
$cart-discount--margin: 0 0 2rem 0 !default;
$cart-discount__padding: 0 !default;
$cart-discount__background: transparent !default;

$cart-discount__toggle--color: $color__default !default;
$cart-discount__toggle--margin: 2rem 0 2rem 0 !default;
$cart-discount__toggle--display: flex !default;
$cart-discount__toggle--align-items: center !default;
$cart-discount__toggle--font-weight: $font-weight__regular !default;

$cart-discount-toggle-icon__color: $color--white !default;
$cart-discount-toggle-icon__background-color: $color--green !default;
$cart-discount-toggle-icon__border-radius: 50% !default;
$cart-discount-toggle-icon__height: 2rem !default;
$cart-discount-toggle-icon__width: 2rem !default;
$cart-discount-toggle-icon__margin-left: 0.7rem !default;
$cart-discount-toggle-icon__margin-right: 0 !default;

$cart-discount-fieldset__align-items: center !default;
$cart-discount-fieldset__display: flex !default;
$cart-discount-fieldset__justify-content: space-between !default;
$cart-discount-fieldset__padding: 0rem 0 2rem !default;

$cart-discount-input__max-width: 100% !default;
$cart-discount-input__margin-right: 2rem !default;
$cart-discount-input__margin-right--desktop: 2rem !default;
