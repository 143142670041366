.sidedrawer-is-open {
    overflow-y: hidden;

    @supports (-webkit-overflow-scrolling: touch) {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

.sidedrawer {
    $self: &;

    background: $sidedrawer__background;
    display: block;
    height: 100%;
    left: calc(-100% + #{$sidedrawer__spacing});
    overflow-x: hidden;
    overflow-y: scroll;
    padding: $sidedrawer__padding;
    position: fixed;
    top: 0;
    transition: transform 500ms ease-in-out;
    width: calc(100% - #{$sidedrawer__spacing});
    z-index: 20;

    .sidedrawer-is-open & {
        transform: translateX(100%);
    }

    &__header {
        background: $sidedrawer-header__background;
        color: $sidedrawer-header__color;
        display: flex;
        font-size: $sidedrawer-header__font-size;
        font-weight: $sidedrawer-header__font-weight;
        height: $sidedrawer-header__height;
        justify-content: space-between;
        padding: $sidedrawer-header__padding;
    }

    &__icon-wrapper {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: $sidedrawer-header__height;
        justify-content: center;
        right: 0;
        top: 0;
        width: $sidedrawer-header__height;
    }

    &__header-icon {
        fill: $sidedrawer-header-icon__color;
        height: $sidedrawer-header-icon__size;
        width: auto;

        &--hamburger {
            height: $sidedrawer-header-icon__size--hamburger;
        }
    }

    &__previous {
        overflow: hidden;
    }

    &__previous-items {
        transition: transform $sidedrawer__transition;

        #{$self}[data-current-level='3'] &,
        #{$self}[data-current-level='4'] & {
            transform: translateY(-#{$sidedrawer-header__height});
        }
    }

    &__header--pages {
        @if $sidedrawer-header__show-pages-header == false {
            display: none;
        }

        background: $sidedrawer-header-pages__background;
        color: $sidedrawer-header-pages__color;
        font-size: $sidedrawer-header-pages__font-size;
        font-weight: $sidedrawer-header-pages__font-weight;
        height: $sidedrawer-header-pages__height;
        padding: $sidedrawer-header-pages__padding;
        transition: opacity $sidedrawer__transition, transform $sidedrawer__transition;

        #{$self}[data-current-level='2'] & {
            transform: translateX(0);
        }

        #{$self}[data-current-level='3'] &,
        #{$self}[data-current-level='4'] & {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    &__title {
        align-self: flex-start;
        flex: 1 0 auto;
        text-align: center;
    }

    &__titles {
        overflow: hidden;
        transition: transform $sidedrawer__transition;

        #{$self}[data-current-level='2'] & {
            transform: translateY(0);
        }

        #{$self}[data-current-level='3'] & {
            transform: translateY(-#{$sidedrawer-header__height});
        }

        $calc: $sidedrawer-header__height * 2;

        #{$self}[data-current-level='4'] & {
            transform: translateY(-#{$calc});
        }
    }

    &__titles-item {
        align-items: center;
        display: flex;
        height: $sidedrawer-header__height;
        justify-content: center;
    }

    &__categories {
        display: grid;
        grid-template-columns: repeat(3, 100%);
        transition: transform $sidedrawer__transition;

        #{$self}[data-current-level='2'] & {
            transform: translateX(0);
        }

        #{$self}[data-current-level='3'] & {
            transform: translateX(-100%);
        }

        #{$self}[data-current-level='4'] & {
            transform: translateX(-200%);
        }
    }

    &__category-list {
        list-style: none;
        padding: $sidedrawer-category-list__padding;
    }

    &__category-item {
        display: none;

        &.is-active,
        #{$self}__category-list[data-category-level='2'] & {
            display: block;
        }

        &--show-all {
            display: block;
            font-weight: $sidedrawer-category-item-show-all__font-weight;
        }
    }

    &__category-link {
        align-items: center;
        border-bottom: 1px solid $color--light-grey;
        color: $sidedrawer-category-link__color;
        display: flex;
        justify-content: space-between;
        padding: $sidedrawer-category-link__padding;
        width: 100%;

        &--has-child {
            &:hover,
            &:focus,
            &:active {
                color: $sidedrawer-category-link__color;
                text-decoration: none;
            }
        }

        &--no-child {
            color: $text__color-link;
        }
    }

    &__category-arrow {
        fill: $sidebar-category-arrow__fill;
        height: 1.6rem;
        width: auto;
    }

    &__pages-list {
        background: $sidedrawer-pages-list__background;
        list-style: none;
        margin: $sidedrawer-pages-list__margin;
        padding: $sidedrawer-pages-list__padding;
        transition: opacity $sidedrawer__transition, transform $sidedrawer__transition;

        #{$self}[data-current-level='2'] & {
            transform: translateX(0);
        }

        #{$self}[data-current-level='3'] &,
        #{$self}[data-current-level='4'] & {
            opacity: 0;
            transform: translateX(-100%);
        }
    }

    &__pages-link {
        align-items: center;
        border-bottom: $sidedrawer-pages-item__border;
        color: $sidedrawer-pages-item__color;
        display: flex;
        padding: $sidedrawer-pages-item__padding;
        width: 100%;

        #{$self}__pages-item:last-child & {
            border: 0;
        }
    }

    &__pages-link-icon {
        fill: $sidedrawer-pages-icon__fill;
        height: $sidedrawer-pages-icon__height;
        margin-right: $sidedrawer-pages-icon__margin-right;
        width: auto;

        #{$self}__pages-link:hover & {
            fill: $sidedrawer-pages-icon__fill--hover;
        }
    }
}

@include min-screen($screen__m) {
    .sidedrawer {
        display: none;

        &__cross {
            display: none;
        }
    }
}
