$pagination__background-color--hover: $color__default;
$pagination__border: 1px solid $color--light-grey;
$pagination__border-radius: 0.2rem;
$pagination__color: $color--medium-grey;
$pagination__font-size: $font-size__xl;

$pagination__height: 3rem;
$pagination__width: 3rem;
$pagination__icon-height: 1.6rem;
$pagination__icon-width: 1.6rem;
