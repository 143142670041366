.banner {
    color: $banner__color;
    height: $banner__height;

    &__item {
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    &__image--tablet,
    &__image--desktop {
        display: none;
    }

    &__content {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__content-inner {
        align-items: $banner-content__align-items;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: $banner-content__justify-content;
        margin-left: auto;
        margin-right: auto;
        max-width: $banner-content__max-width;
        padding-left: $banner-content__padding-left;
        padding-right: $banner-content__padding-right;
    }

    &__title {
        font-family: $banner-title__font-family;
        font-size: $banner-title__font-size;
        font-weight: $banner-title__font-weight;
        padding-bottom: $banner-title__spacing;
    }

    &__paragraph {
        font-family: $banner-paragraph__font-family;
        font-size: $banner-paragraph__font-size;
        font-weight: $banner-paragraph__font-weight;
        padding-bottom: $banner-content__spacing;
    }

    &__link {
        display: block;
    }

    &__image,
    &__image--desktop,
    &__image--tablet {
        filter: $banner-image__filter;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
 }

@include min-screen($screen__m) {
    .banner {
        height: $banner__height--desktop;

        &__image,
        &__image--desktop {
            display: none;
        }

        &__image--tablet {
            display: block;
        }

        &__content-inner {
            padding-left: $banner-content__padding-left--desktop;
            padding-right: $banner-content__padding-right--desktop;
        }

        &__title {
            font-size: $banner-title__font-size--desktop;
            font-weight: $banner-title__font-weight--desktop;
            padding-bottom: $banner-title__spacing--desktop;
        }

        &__paragraph {
            font-size: $banner-paragraph__font-size--desktop;
            font-weight: $banner-paragraph__font-weight--desktop;
            max-width: $banner-paragraph__max-width;
            padding-bottom: $banner-content__spacing--desktop;
        }
    }
}

@include min-screen($screen__l) {
    .banner {
        &__image,
        &__image--tablet {
            display: none;
        }

        &__image--desktop {
            display: block;
        }
    }
}
