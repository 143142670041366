.opening-hours {
    &__title {
        line-height: 3rem;
        margin: 0;
    }

    &__item {
        display: grid;
        grid-template-columns: 12rem auto;
    }
}