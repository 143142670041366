$product-card__border: 0 solid transparent !default;
$product-card__border-bottom--mobile: 1px solid $color--light-grey !default;
$product-card__background: none !default;
$product-card-hover__border: 0 solid transparent !default;
$product-card__border-radius: 0 !default;
$product-card__padding: 0rem !default;
$product-card__padding--mobile: 1rem !default;

$product-card-image__background: $color--light-grey !default;
$product-card-image__padding: 1rem !default;
$product-card-image__margin-bottom: 2rem !default;
$product-card-image__padding--desktop: 2rem !default;
$product-card-image__max-width--mobile: 11rem !default;
$product-card-image__max-width: 100% !default;

$product-card-info__align-center: false !default; // Only on desktop
$product-card-info__padding: 1rem !default;
$product-card-info__padding-desktop: 0 !default;

$product-card-swatches__margin-bottom: 1rem !default;
$product-card-swatches__margin-bottom--desktop: 1rem !default;

$product-card-name__color: $text__color-link !default;
$product-card-name__font-size: $font-size__default !default;
$product-card-name__font-weight: $font-weight__default !default;
$product-card-name__line-height: $line-height__default !default;
$product-card-name__margin-bottom: 0 !default;
$product-card-name__margin-bottom--desktop: 0 !default;

$product-card-price__margin: 0 1rem 0 0 !default;

$product-card-button__enabled: true !default;
$product-card-button-icon__margin-right: 0.8rem !default;
$product-card-button__padding: 0 1.5rem 0 2.5rem !default;
$product-card-button__min-width: 8rem !default;
$product-card-button__justify-content: space-between !default;
$product-card-button__font-weight: $font-weight__bold !default;
$product-card-button__font-size: 2rem !default;

/* Swatches */
$swatches__justify-content: flex-start !default;
$swatches__justify-content--desktop: center !default;

$swatches-item__spacing: 1rem !default;
$swatches-item__border-radius: 50% !default;
$swatches-item__height: 1.6rem !default;
$swatches-item__width: 1.6rem !default;

$product-card-name-desc__padding-top--swatches: 1rem !default;
$product-card-swatches__top--swatches: 0 !default;

$product-card-wishlist-visual__order: 0 !default;
$product-card-wishlist-product-item-name__order: 1 !default;
$product-card-wishlist-option-list__order: 2 !default;
$product-card-wishlist-price-box__order: 3 !default;
$product-card-wishlist-product-item-inner__order: 4 !default;

$product-card-horizontal__padding: 1rem !default;
$product-card-horizontal-info__flex: inherit !default;
$product-card-horizontal-info__padding: 0 0 0 1.5rem !default;
$product-card-horizontal-info__width: 100% !default;
$product-card-horizontal-visual__max-width: 9rem !default;
$product-card-horizontal-addtocart__display: block !default;

$product-card-horizontal-shop__align-items: flex-end !default;
$product-card-horizontal-shop__align-items--mobile: flex-start !default;
$product-card-horizontal-shop__flex-direction: row !default;
$product-card-horizontal-shop__flex-direction--mobile: column !default;
$product-card-horizontal-button__margin: 0 !default;
$product-card-horizontal-button__margin--mobile: 0.5rem 0 0 0 !default;
