$modal__z-index: 3 !default;

$modal-overlay__background: rgba($color--deep-black, 0.75) !default;

$modal__background-color: $color--white !default;
$modal__border-radius: $border-radius__default !default;
$modal__margin: 10vh 1rem !default;
$modal__max-width: 80rem !default;
$modal__padding: 3rem !default;
$modal__transition: $transition__default !default;

$modal-title__color: $color--default !default;
$modal-title__font-family: $font-family__default !default;
$modal-title__font-size: 2rem !default;
$modal-title__margin: 0 0 2rem 0 !default;

$modal-close__right: 0 !default;
$modal-close__top: -3rem !default;
$modal-close-icon__fill: $color--white !default;
$modal-close-icon__height: 1.6rem !default;
$modal-close-label__show: false !default;
$modal-close-label__color: $color--light-grey !default;

$modal-sidedrawer__max-width: calc(100% - 5rem) !default;
$modal-sidedrawer__width: 40rem !default;
