$category-overview-block__margin: 0 0 4rem 0;

$category-description-link-arrow__color: $text__color-link;
$category-description-link-arrow__margin: 0 0 0 0.5rem;
$category-description-link__text-decoration: underline;

$category-overview__margin-bottom: 2rem;
$category-overview__padding: 0 2rem;
$category-overview__max-width: 128rem;
$category-overview__grid-columns: repeat(auto-fit, minmax(20rem, 1fr));
$category-overview__grid-gap: 2rem;

$category-overview-item__color: $color--white;
$category-overview-item__radius: 0.5rem;
$category-overview-item__height: inherit;
$category-overview-item__height--mobile: 5rem;
$category-overview-item__margin: 0;
$category-overview-item__margin--mobile: 0 0 .5rem 0;
$category-overview-item__box-shadow--hover: 0px 2px 3px 0px rgba(0,0,0,0.3);

$category-overview-arrow__height: 2rem;
$category-overview-arrow__width: 2rem;

$category-overview-link__width: 100%;
$category-overview-link__width--mobile: calc(100% - 50px);

$category-overview-image-link__width: inherit;
$category-overview-image-link__width--mobile: 5rem;
$category-overview-image-link__padding: 1rem 1.5rem;
$category-overview-image-link__padding--mobile: 1.5rem;

$category-overview-image__height: 100%;
$category-overview-image__width: 100%;

$overview-product-list-product-list__margin: 0 0 2rem 0;
$overview-product-list-action__justify-content: center;