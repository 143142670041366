.cart-items {
    &__remove-icon {
        fill: $color--black;
    }

    &__title {
        font-weight: $font-weight__bold;
        font-size: 1.6rem;
        word-break: break-word;
    }

    &__qty {
        display: flex;
        align-items: flex-end;
    }

    &__item {
        &:first-child {
            border-top: 0.1rem solid $color--grey;
            padding: 2rem 0;
        }

        &:last-child {
            padding: 2rem 0;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__price-excl {
        font-size: $font-size__xxxl;
        font-weight: $font-weight__bold;
        margin: 0 0 2rem 0;
    }

    &__price-incl {
        display: none;
        font-size: $font-size__s;
        line-height: $line-height__s;
    }

    &__tax-msg {
        font-size: 0.8rem;
        line-height: 1.1rem;
    }
}

@include min-screen($screen__m) {
    .cart-items {
        &__price-incl {
            display: block;
        }

        &__item {
            padding: 3rem 0;

            &:first-child {
                border-top: 0.1rem solid $color--grey;
                padding: 3rem 0;
            }

            &:last-child {
                padding: 3rem 0;
            }
        }
    }
}

@include min-screen(900px) {
    .cart-items {
        &__item {
            grid-template-columns: 20rem auto auto;
        }

        &__image {
            img {
                width: 20rem;
                height: 20rem;
            }
        }
    }
}

@include max-screen($screen__m) {
    .cart-items {
        &__qty {
            grid-column-end: 3;
        }

        &__price {
            grid-column-start: 3;
            grid-row-start: 2;
        }

        &__image-wrapper {
            margin: 0 0 3rem 0;
        }
    }
}
