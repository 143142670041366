/**
 * @deprecated
 * Use: molecules/_modal.scss
 **/
.popup-is-open {
    overflow-y: hidden;

    @supports (-webkit-overflow-scrolling: touch) {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

.popup {
    $self: &;

    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $popup__z-index;

    &__overlay {
        background: $popup-overlay__background;
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        transition: opacity $transition__default;
        width: 100%;
    }

    &__modal {
        background: $popup-modal__background-color;
        border-radius: $popup-modal__border-radius;
        margin: $popup-modal__margin;
        max-width: $popup-modal__max-width;
        opacity: 0;
        padding: $popup-modal__padding;
        position: relative;
        pointer-events: none;
        transform: translateY(-10vh);
        transition: opacity $popup-modal__transition, transform $popup-modal__transition;
        z-index: $popup__z-index + 1;
    }

    &__content {
        display: none;
    }

    &__title {
        color: $popup-title__color;
        font-size: $popup-title__font-size;
        margin: $popup-title__margin;
    }

    &.is-open {
        opacity: 1;
        overflow-y: auto;
        pointer-events: inherit;

        #{$self}__overlay {
            opacity: 1;
            pointer-events: inherit;
        }

        #{$self}__modal {
            opacity: 1;
            pointer-events: inherit;
            transform: translateY(0);
        }

        #{$self}__content {
            display: block;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: $popup-close-icon__right;
        top: $popup-close-icon__top;
    }

    &__icon-close {
        fill: $popup-close-icon__fill;
        height: $popup-close-icon__height;
        width: auto;
    }

    img {
        height: auto;
        max-width: 100%;
    }
}
