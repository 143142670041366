.product-card {
    $self: &;

    &.product-card--wishlist {
        display: grid;
        grid-template-columns: 11rem auto;

        .field.qty {
            display: none;
        }

        .price-as-configured {
            margin: 0;
        }

        #{$self}__name-desc {
            grid-column: 2 / 2;
            grid-row: 1 / 1;
        }

        #{$self}__shop {
            display: grid;
            grid-column: 2 / 2;
            grid-gap: 1rem;
            grid-row: 2 / 2;
            grid-template-columns: 1fr;
        }

        #{$self}__visual {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            order: $product-card-wishlist-visual__order;
        }

        .product-item-name {
            order: $product-card-wishlist-product-item-name__order;
        }

        .wishlist-options-list {
            order: $product-card-wishlist-option-list__order;
        }

        .price-box {
            order: $product-card-wishlist-price-box__order;
        }

        .product-item-inner {
            align-items: center;
            display: flex;
            grid-gap: 2rem;
            order: $product-card-wishlist-product-item-inner__order;
        }
    }
}

@include min-screen($screen__s) {
    .product-card {
        $self: &;

        &.product-card--wishlist {
            display: flex;
        }
    }
}
