.password-strength-meter {
    color: $password-strength-meter__color;
    padding-top: 1rem;
    position: relative;

    &:before {
        background: $password-strength-meter__background--default;
        border-radius: $password-strength-meter__border-radius;
        content: '';
        height: $password-strength-meter__height;
        left: 0;
        position: absolute;
        top: 0.3rem;
        width: 100%;
    }

    &:after {
        border-radius: $password-strength-meter__border-radius;
        content: '';
        height: $password-strength-meter__height;
        left: 0;
        position: absolute;
        transition: width $password-strength-meter__transition, background-color $password-strength-meter__transition;
        top: 0.3rem;
    }

    .password-none & {
        &:after {
            background-color: $password-strength-meter__background--default;
            width: 100%;
        }
    }

    .password-weak & {
        &:after {
            background-color: $password-strength-meter__background--weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:after {
            background: linear-gradient(
                to right,
                $password-strength-meter__background--weak 0%,
                $password-strength-meter__background--medium 100%
            );
            width: 50%;
        }
    }

    .password-strong & {
        &:after {
            background: linear-gradient(
                to right,
                $password-strength-meter__background--weak 0%,
                $password-strength-meter__background--medium 50%,
                $password-strength-meter__background--strong 100%
            );
            width: 75%;
        }
    }

    .password-very-strong & {
        &:after {
            background: linear-gradient(
                to right,
                $password-strength-meter__background--weak 0%,
                $password-strength-meter__background--medium 25%,
                $password-strength-meter__background--strong 50%,
                $password-strength-meter__background--very-strong 100%
            );
            width: 100%;
        }
    }
}
