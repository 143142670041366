.sidedrawer-toggle {
    -webkit-tap-highlight-color: transparent;
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon {
        fill: $navigation-toggle-icon__fill;
        height: $navigation-toggle-icon__height;
        width: auto;
    }
}

@include min-screen($screen__m) {
    .sidedrawer-toggle {
        display: none;
    }
}
