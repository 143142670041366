.customer-service-faq {
    $self: &;

    &__question {
        cursor: pointer;
        margin-bottom: 0.5rem;
        position: relative;

        &:hover {
            text-decoration: underline;
        }
    }

    &__toggle-icon {
        left: 0;
        position: absolute;
        top: 0.4rem;
        transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    }

    &__plus-icon {
        #{$self} .is-active & {
            opacity: 0;
            transform: rotate(90deg);
        }
    }

    &__min-icon {
        opacity: 0;
        transform: rotate(-90deg);

        #{$self} .is-active & {
            opacity: 1;
            transform: rotate(0);
        }
    }

    &__question-label {
        display: block;
        padding-left: 3rem;
    }

    &__answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 250ms ease-out;

        #{$self} .is-active & {
            margin-bottom: 1rem;
            max-height: 50rem;
            transition: max-height 500ms ease-in;
        }
    }

    &__answer-inner {
        background: $color--white;

        padding: 2.5rem 3.5rem;
    }
}