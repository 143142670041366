// Color definitions
$color--white: rgb(255, 255, 255);

$color--bright-red: rgb(241, 83, 90); // Should not be used in this project
$color--light-red: rgba($color--red, 0.18); // Should not be used in this project

$color--bright-green: rgb(78, 186, 110); // Should not be used in this project
$color--green: rgb(19, 162, 61); // Should not be used in this project
$color--light-green: rgba($color--green, 0.18); // Should not be used in this project
$color--dark-green: rgb(11, 115, 6); // Should not be used in this project

$color--bright-yellow: rgb(253, 231, 100); // Should not be used in this project
$color--yellow: rgb(255, 205, 32); // Should not be used in this project
$color--light-yellow: rgba($color--yellow, 0.25); // Should not be used in this project
$color--dark-yellow: rgb(144, 106, 0); // Should not be used in this project

$color--orange: rgb(243, 90, 24); // Accent color like Ácties'
$color--dark-orange: rgb(194, 72, 19); // Accent color like Ácties'
$color--medium-orange: rgb(235, 207, 191); // Accent color like Ácties'
$color--grey: rgb(216, 216, 216); // Should not be used in this project

$color--light-blue: rgb(236, 247, 252); // block backgrounds
$color--blue-grey: rgb(186, 198, 211); // Should not be used in this project
$color--bright-blue: rgb(8, 98, 204); // Should not be used in this project
$color--blue: rgb(0, 139, 193); // Default blue logo
$color--dark-blue: rgb(42, 59, 142); // Dark blue logo

$color--light-purple: rgb(208, 171, 217); // Should not be used in this project
$color--purple: rgb(134, 53, 170); // Should not be used in this project
$color--dark-purple: rgb(87, 32, 110); // Should not be used in this project

$color--black: rgb(81, 90, 93); // Default text color
$color--deep-black: rgb(0, 0, 0); // Should only be used for shadows and opacity

$color--light-grey: rgb(216, 216, 216);
$color--medium-grey: rgb(148, 160, 164);
$color--grey: rgba($color--black, 0.3);
$color--dark-grey: rgba($color--black, 0.5);

// Default colors
$color__primary: $color--blue;
$color__primary-dark: $color--dark-blue;
$color__default: $color--black;

// Typography
$text__color: $color__default;
$text__color-link: $color--blue;
$text__color-link--hover: $color--blue;
$text__color-link--active: $color--blue;
$text__color-selection: $color--white;
$text__color-secondary: $color--dark-grey;

// Success, warning and errors
$success__color: $color--dark-green;
$success__border-color: $color--green;
$success__background-color: $color--light-green;

$warning__color: $color--dark-yellow;
$warning__border-color: $color--yellow;
$warning__background-color: $color--light-yellow;

$error__color: $color--dark-red;
$error__border-color: $color--red;
$error__background-color: $color--light-red;
