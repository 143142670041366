.filter-options {
    $self: &;
    border-bottom: 1px solid $color--light-grey;
    position: relative;

    ol.items {
        list-style-type: none;
        padding-left: 0;

        li.item {
            display: flex;
            padding-bottom: 0.8rem;

            input[type='radio'],
            input[type='checkbox'] {
                clip-path: none;
                display: block;
                height: 2rem;
                margin: 0 0.5rem;
                position: relative;
                top: 0.5rem;
                width: 2.3rem;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 0 1rem;

        .range {
            justify-content: space-around;
            padding-bottom: $spacing__m;
        }

        .am-filter-price {
            font-size: $font-size__default;
            height: 3.6rem;
            max-width: 7.7rem !important;
            width: 7.7rem !important;
        }

        .am-filter-items-attr_price {
            max-width: $layout__sidebar-width;
        }

        .am-filter-go {
            height: 3.6rem;
        }

        .am-ranges {
            order: 1;
        }

        .am-show-more {
            margin-top: 0;
            order: 0;
            padding: 0;

            .am-counter {
                opacity: 1;
            }

            &.-disabled {
                display: none;
            }

            &:after,
            &:before {
                display: none;
            }
        }
    }

    .amshopby-slider-container {
        margin: $slider-container__margin;
        padding-bottom: $spacing__m;

        .amshopby-slider-display {
            padding-bottom: $spacing__m;
        }

        .ui-slider-horizontal {
            background: $color--light-grey;
            border-radius: $ui-slider__height;
            height: $ui-slider__height;
            position: relative;
        }

        .ui-slider-range {
            background: $color--blue;
            border-radius: $ui-slider__height;
            height: $ui-slider__height;
        }

        .am-slider {
            .ui-slider-handle {
                background: $color--blue;
                border-radius: 50%;
                bottom: -1.1rem;
                height: $ui-slider-handle__height;
                margin-left: calc(#{$ui-slider-handle__height} - (#{$ui-slider-handle__height} * 1.5));
                width: $ui-slider-handle__height;
                z-index: 0;

                &:hover {
                    background: $color--orange;
                }
            }
        }
    }

    &__heading {
        &.is-open + .filter-options-content {
            display: flex;
            margin: 0 0 1rem;
        }

        + .filter-options-content {
            display: none;
        }
        &.is-open {
            #{$self}__title:after {
                @include icon-arrow(
                    $color--default,
                    $width: $icon__default-height,
                    $height: $icon__default-height,
                    $rotation: 270deg
                );
                right: 0;
            }
        }
    }

    &__title {
        cursor: pointer;
        font-size: $font-size__default;
        margin-top: 0.4rem;
    }

    &-content {
        .label,
        .count {
            color: $color__default;

            .filter-count-label {
                display: none;
            }
        }

        .count {
            &:before {
                content: '(';
            }
            &:after {
                content: ')';
            }
        }
    }
}

.am-fromto-widget {
    display: flex;
}
