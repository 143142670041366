.is-hidden {
    display: none;
}

[x-cloak] { display: none !important; }

@include max-screen($screen__m - 1) {
    .is-hidden {
        &--mobile {
            display: none !important;
        }
    }
}

@include min-screen($screen__m) {
    .is-hidden {
        &--desktop {
            display: none !important;
        }
    }
}
