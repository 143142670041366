.product-attributes {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    margin: $product-attributes--margin;
    line-height: $product-attributes--line-height;

    &__item {
        &:nth-child(odd) {
            background: $product-attributes--background-color;
        }
    }

    &__title {
        font-size: $product-attributes-title--font-size;
        padding: $product-attributes-title--padding;
        font-weight: $product-attributes-title--font-weight;
        text-align: $product-attributes-title--text-align;
        width: $product-attributes-title--width;
    }

    &__value {
        font-size: $product-attributes-value--font-size;
        padding: $product-attributes-value--padding;
        font-weight: $product-attributes-value--font-weight;
        text-align: $product-attributes-value--text-align;
        width: $product-attributes-value--width;
    }
}
