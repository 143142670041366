$summary__background-color: $color--light-grey !default;
$summary__border: 0 !default;
$summary__border--desktop: 0 !default;
$summary__border-radius: 0 !default;
$summary__padding: 2rem !default;
$summary__padding--desktop: 2rem !default;

$summary-title__font-size: $font-size__xl !default;
$summary-title__margin: 0 0 2rem 0 !default;
$summary-title__margin--desktop: 0 0 2rem 0 !default;

$summary-proceed-to-checkout__font-weight: $font-weight__medium !default;
$summary-proceed-to-checkout__width: 100% !default;
