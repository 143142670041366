.opc-wrapper {
    .form-login {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }

    .note {
        color: $color--dark-grey;
        clear: both;
        font-size: $font-size__s;
    }

    .control._with-tooltip .note {
        display: block;
    }

    .form-login .actions-toolbar {
        align-items: center;
        display: flex;
    }

    .form-login .actions-toolbar .primary {
        margin-right: 1rem;
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 1rem;

        > span {
            &:before {
                content: '+';
                padding-right: 1rem;
            }
        }
    }
}

@include min-screen($screen__m) {
    .opc-wrapper {
        .form-shipping-address,
        #customer-email-fieldset {
            max-width: 50rem;
        }
    }
    .table-checkout-shipping-method {
        width: auto;
    }
}
