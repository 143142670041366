.featured-category-list {
    &__category-list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        background: $featured-category-item__color;
        border-radius: $featured-category-item__radius;
        display: flex;
        height: $featured-category-item__height--mobile;
        margin: $featured-category-item__margin--mobile;
        overflow: hidden;
    }

    &__image-link {
        display: block;
        line-height: 0;
        width: $featured-category-image-link__width--mobile;
    }

    &__image {
        height: $featured-category-image__width;
    }

    &__link {
        align-items: center;
        color: $color--default;
        display: flex;
        justify-content: space-between;
        padding: $featured-category-image-link__padding--mobile;
        width: $featured-category-link__width--mobile;

        &:hover {
            color: $color--default;
            text-decoration: none;
        }
    }

    &__arrow {
        height: $featured-category-arrow__height;
        min-width: $featured-category-arrow__width;
    }
}

@media screen and (min-width: $screen__s) {
    .featured-category-list {
        &__category-list {
            display: grid;
            grid-gap: $featured-category-list__grid-gap;
            grid-template-columns: $featured-category-list__grid-columns;
        }

        &__item {
            display: block;
            height: $featured-category-item__height;
            margin: $featured-category-item__margin;

            &:hover {
                box-shadow: $featured-category-item__box-shadow--hover;
            }
        }

        &__image {
            width: $featured-category-image__width;
        }

        &__link {
            padding: $featured-category-image-link__padding;
            width: $featured-category-link__width;
        }

        &__image-link {
            width: $featured-category-image-link__width;
        }
    }
}
