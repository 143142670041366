$account-page-before-login-block__background: $color--white !default;
$account-page-before-login-block__border: 1px solid $color--black !default;
$account-page-before-login-block__border-radius: $border-radius__default !default;
$account-page-before-login-block__padding: 2rem 1.5rem !default;
$account-page-before-login-block__padding--desktop: 2.5rem 2rem !default;
$account-page-before-login-block__spacing: 3rem !default;
$account-page-before-login-block__max-width--desktop: 50rem !default;
$account-page-before-login-block__margin-desktop: 0 0 0 $spacing__m !default;

$account-page-before-login-block__padding--register-usps: 0 !default;

$account-page-before-login__flex-direction--desktop: row !default;
$account-page-before-login__justify-content--desktop: center !default;

$account-page-before-login-title__text-align: left !default;
$account-page-before-login-title__text-align--desktop: center !default;
