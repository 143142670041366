.review-detail {
    $self: &;

    &__title {
        font-weight: $font-weight__bold;
        margin-bottom: 1rem;
    }

    &__info {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1rem;
    }

    &__ratings {
        margin-right: 1rem;
        padding-right: 1rem;
        position: relative;

        &:after {
            background: $review-detail__separator-color;
            content: '';
            display: block;
            height: 2rem;
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
        }
    }

    &--loading {
        #{$self}__name,
        #{$self}__title,
        #{$self}__review:before,
        #{$self}__review:after {
            background: $review-detail__loading-color;
            height: 2rem;
        }

        #{$self}__name {
            width: 10%;
        }

        #{$self}__title {
            max-width: 40rem;
            width: 60%;
        }

        #{$self}__review {
            &:before,
            &:after {
                content: '';
                display: block;
                margin-bottom: 1rem;
            }

            &:before {
                max-width: 90rem;
                width: 90%;
            }

            &:after {
                max-width: 80rem;
                width: 80%;
            }
        }
    }
}
