.cookie-consent-modal {
  align-content: flex-start;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  inset: 0;
  justify-items: center;
  overflow-y: scroll;
  position: fixed;
  z-index: 50;

  &.blurred {
    backdrop-filter: blur(4px);
  }

  &__inner {
    background: #fff;
    border-radius: 8px;
    margin: 1rem 1rem 1rem 0;
    max-width: 48rem;
    padding: 2rem;
    position: absolute;
    top: 33.33%;

    @include min-screen($screen__m) {
      margin: 0;
    }
  }

  &__description {
    margin-bottom: 1.5rem;
  }

  &__preferences {
    display: flex;
    gap: 1rem;
  }

  &__customize {
    background: none;
    color: black;
    display: block;
    font-weight: 400;
    padding: 0;
    text-decoration: underline;

    &:hover {
      background: none;
      color: black;
      text-decoration: none;
    }
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}