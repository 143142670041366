.continue-shopping {
    border-top: $continue-shopping__border;
    margin: $continue-shopping__margin;
    padding: $continue-shopping__padding;
    color: $continue-shopping__color;

    &__icon {
        fill: $continue-shopping-icon__fill;
        margin: $continue-shopping-icon__margin;
        height: $continue-shopping-icon__height;
    }
}
