$icon__default-height: 1.6rem;
$icon__default-width: 1.6rem;

.icon {
    height: 1.6rem;
    width: auto;
}

/**
 * Icons as an element (like a span or :before)
 */
@mixin icon-arrow($fill, $width: $icon__default-width, $height: $icon__default-height, $rotation: 0) {
    $icon-fill: str-replace(#{$fill}, '#', '%23');

    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#{$icon-fill}"><path d="M6.49 20.13l1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z"/></svg>');
    background-size: $width $height;
    background-repeat: no-repeat;
    height: $height;
    transform: rotate(#{$rotation});
    width: $width;
}

@mixin icon-search($fill, $width: $icon__default-width, $height: $icon__default-height, $rotation: 0) {
    $icon-fill: str-replace(#{$fill}, '#', '%23');

    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.49 17.49" fill="#{$icon-fill}"><path d="M12.5,11h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,5L17.49,16Zm-6,0A4.5,4.5,0,1,1,11,6.5,4.49,4.49,0,0,1,6.5,11Z"/></svg>');
    background-size: $width $height;
    background-repeat: no-repeat;
    height: $height;
    transform: rotate(#{$rotation});
    width: $width;
}

/**
 * Icons as a background
 */

@mixin icon-bg-arrow-down($fill, $width: $icon__default-width, $height: $icon__default-height) {
    $icon-fill: str-replace(#{$fill}, '#', '%23');

    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.8 11.67" fill="#{$icon-fill}"><path d="M1.77,0,0,1.77l9.9,9.9,9.9-9.9L18,0,9.9,8.13Z"/></svg>');
    background-size: $width $height;
}

@mixin icon-bg-cross($fill, $width: $icon__default-width, $height: $icon__default-height) {
    $icon-fill: str-replace(#{$fill}, '#', '%23');

    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="#{$icon-fill}"><path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"/></svg>');
    background-size: $width $height;
}

@mixin icon-home($fill, $width: $icon__default-width, $height: $icon__default-height) {
    $icon-fill: str-replace(#{$fill}, '#', '%23');

    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 17" id="home" xmlns="http://www.w3.org/2000/svg"><path d="M8 17v-6h4v6h5V9h3L10 0 0 9h3v8z"/></svg>');
    background-size: $width $height;
    background-repeat: no-repeat;
    height: $height;
    width: $width;
}
