.cms-links {
    font-size: $cms-links__font-size;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        margin: $cms-links-item__margin;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        color: $cms-links-link__color;

        &:hover {
            color: $cms-links-link__color--hover;
        }

        &.is-current {
            color: $cms-links-link__color--current;
            font-weight: $cms-links-link__font-weight--current;
        }
    }

    &__icon {
        fill: currentColor;
        height: 0.8em;
        width: auto;
    }
}
