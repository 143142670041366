.products-grid {
    &.wishlist {
        border-left: 1px solid $color--grey;
        margin-left: -3rem;

        button.tocart {
            height: 4rem;
        }

        .product-item-name {
            flex: 1;
            font-weight: $font-weight__light;
            padding-bottom: 1.5rem;

            a {
                color: $color__default;
            }
        }

        .price-as-configured {
            display: flex;
            justify-content: center;
            width: 100%;

            .price-configured_price {
                display: flex;
                flex-direction: column;
            }

            .price-including-tax {
                .price,
                .tax {
                    font-size: 1.5rem;
                    color: $color--default;
                    font-weight: $font-weight__default;
                }

                .tax {
                    font-size: 1.1rem;
                }
            }

            .old-price {
                .price {
                    color: $color--default;
                    font-size: 1.75rem;
                }
            }

            .price {
                color: $color--orange;
                font-size: 2.5rem;
                font-weight: $font-weight__extra-bold;
            }
        }
    }
}

@include max-screen($screen__m) {
    .products-grid {
        &.wishlist {
            .product-card {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 0;
                padding: 0 1rem;
            }
        }
    }
}
