.product-block {
    $self: &;
    &__title {
        margin: 0;
        border-bottom: $product-block__border;
    }
    &__title h2 {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 3.5rem;
    }
}
