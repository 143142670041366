$progress-traingle-border-width: $progress__height * 0.5;

.progress {
    $self: &;

    background: $progress__background-color;
    border-radius: $progress__border-radius;
    display: flex;
    overflow: hidden;

    &__item {
        align-items: center;
        display: flex;
        color: $progress-item__color;
        cursor: default;
        flex: 0 0 calc(100% / 3);
        font-size: $progress-item__font-size;
        height: $progress__height;
        justify-content: center;
        position: relative;

        &--active {
            &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: $progress-traingle-border-width 0 $progress-traingle-border-width
                    $progress-traingle-border-width;
                border-color: $progress__background-color $progress__background-color $progress__background-color
                    $progress-item__background-color--active;
            }
        }

        &--complete {
            background: $progress-item__background-color--complete;
            color: $progress-item__color--complete;
            cursor: pointer;

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: $progress-traingle-border-width 0 $progress-traingle-border-width
                    $progress-traingle-border-width;
                border-color: $progress-item__background-color--active $progress-item__background-color--active
                    $progress-item__background-color--active $progress-item__background-color--complete;
            }
        }

        &--with-checkmark {
            #{$self}__label {
                display: block;
                position: relative;
                padding-left: 0.5em;
            }
        }
    }

    &__title {
        align-items: center;
        display: flex;
        flex: 1 0 auto;
        height: $progress__height;
        justify-content: center;
        text-align: center;

        #{$self}__item--active & {
            background: $progress-item__background-color--active;
            color: $progress-item__color--active;
        }

        #{$self}__item--complete & {
            background: $progress-item__background-color--complete;
            color: $progress-item__color--complete;
        }
    }

    &__icon-checkmark {
        display: block;
        fill: $progress-item__color;
        height: $progress-icon__height;
        width: auto;
    }

    &--two-steps {
        #{$self}__item {
            flex: 0 0 calc(100% / 2);
        }
    }
}

@include min-screen($screen__m) {
    .progress {
        &__item {
            font-size: $progress-item__font-size--desktop;
        }
    }
}
