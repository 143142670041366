/* Top links */
$links__font-size: $font-size__default;
$links__color: $text__color-link;
$links__color--hover: $text__color-link--hover;
$links__spacing: 2rem;
$links__white-space: nowrap;

/* Navigation */
$primary-navigation__height: 5rem;
$primary-navigation__link-color: $color--default;
$primary-navigation__font-size: $font-size__default;

$primary-navigation__background--active: $color--light-grey;
$primary-navigation__font-weight--active: $font-weight__default;
$primary-navigation__color--active: $color--default;

$primary-navigation-link__padding: 0 2rem;

$primary-navigation-link__background--after--active: $color--black;
$primary-navigation-link__bottom--after--active: 0;
$primary-navigation-link__height--after--active: 4px;
$primary-navigation-link__display--after--active: block;
$primary-navigation-link__width--after--active: calc(100% - 4rem);
$primary-navigation-link__z-index--after--active: 2;

$second-level__width: 100%;
$secondary-wrapper__background: $color--light-grey;
$secondary-wrapper__border-radius: 0;
$secondary-wrapper__padding: 2rem;
$secondary-wrapper__top: $primary-navigation__height;

$secondary-list__grid-gap: 4rem 0;
$secondary-list__grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

$secondary-navigation-link__color: $color--default;
$secondary-navigation-link__font-weight: $font-weight__bold;
$secondary-navigation-link__color--active: $color--default;
$secondary-navigation-link__font-weight--active: $font-weight__bold;
$secondary-navigation-link__font-weight: $font-weight__bold;
$secondary-navigation-link__padding-bottom: 0.5rem;

$third-navigation-link__color: $text__color-link;
$third-navigation-link__color--active: $text__color-link--active;

$third-list__line-height: inherit;

$navigation-overlay__color: $color--black;
$navigation-overlay__opacity: 0.7;
$navigation-overlay__z-index: 0;

/* Product navigation */
$product-navigation__show-on-mobile: true;

$product-navigation-item__padding: 0 1rem 0 0;
$product-navigation-item__padding--m: 0 1.5rem 0 0;
$product-navigation-item__padding--xl: 0 3rem 0 0;

$product-navigation__height: 3.4rem;

$product-navigation-link__background: $color--light-grey;
$product-navigation-link__border-radius: $border-radius__default;
$product-navigation-link__color: $color--default;
$product-navigation-link__padding: 0 1rem;
$product-navigation-link__color--hover: $color--default;

/* Sidedrawer */
$navigation-toggle-icon__fill: $color--default;
$navigation-toggle-icon__height: 2rem;

$sidedrawer-overlay__background: rgba($color--deep-black, 0.75);

$sidedrawer__background: $color--white;
$sidedrawer__padding: 0;
$sidedrawer__spacing: 5rem;
$sidedrawer__transition: 500ms ease-in-out;

$sidedrawer-header__background: $color--black;
$sidedrawer-header__color: $color--white;
$sidedrawer-header__font-size: $font-size__xl;
$sidedrawer-header__font-weight: $font-weight__default;
$sidedrawer-header__height: 5.5rem;
$sidedrawer-header__padding: 0;

$sidedrawer-header__show-pages-header: true;

$sidedrawer-header-pages__background: $sidedrawer-header__background;
$sidedrawer-header-pages__color: $sidedrawer-header__color;
$sidedrawer-header-pages__font-size: $sidedrawer-header__font-size;
$sidedrawer-header-pages__font-weight: $sidedrawer-header__font-weight;
$sidedrawer-header-pages__height: $sidedrawer-header__height;
$sidedrawer-header-pages__padding: $sidedrawer-header__padding;

$sidedrawer-header__padding--pages: 0 2rem;

$sidedrawer-header-icon__color: $color--white;
$sidedrawer-header-icon__size: 2rem;
$sidedrawer-header-icon__size--hamburger: 2.8rem;

$sidedrawer-category-list__padding: 0 2rem;

$sidedrawer-category-item-show-all__font-weight: $font-weight__bold;

$sidedrawer-category-link__border-bottom: 1px solid $color--light-grey;
$sidedrawer-category-link__color: $color--default;
$sidedrawer-category-link__padding: 1rem 0;

$sidebar-category-arrow__fill: $color--default;
$sidebar-category-arrow__size: 1.6rem;

$sidedrawer-pages-list__background: $color--light-grey;
$sidedrawer-pages-list__margin: 0;
$sidedrawer-pages-list__padding: 1rem 2rem 2rem 2rem;

$sidedrawer-pages-item__border: 1px solid $color--grey;
$sidedrawer-pages-item__color: $color--default;
$sidedrawer-pages-item__padding: 1rem 0;

$sidedrawer-pages-icon__margin-right: 1rem;
$sidedrawer-pages-icon__height: 1.6rem;
$sidedrawer-pages-icon__fill: $text__color-link;
$sidedrawer-pages-icon__fill--hover: $text__color-link--hover;

$cms-links__font-size: $font-size__default;
$cms-links-item__margin: 0 0 0.5rem 0;
$cms-links-link__color: $text__color-link;
$cms-links-link__color--hover: $text__color-link--hover;
$cms-links-link__color--current: $color--default;
$cms-links-link__font-weight--current: $font-weight__bold;
$two-col-left-nav-sidebar__padding: 1em 0 0 0;

$two-col-left-nav-sidebar__width: 28rem;
$two-col-left-nav__grid-gap: 2rem;
