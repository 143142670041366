.navigation-overlay {
    background-color: $navigation-overlay__color;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $navigation-overlay__z-index;

    &.is-active {
        display: block;
        opacity: $navigation-overlay__opacity;
    }
}
