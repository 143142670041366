$product-page__grid-gap: 3rem;
$product-page__grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);

$product-page-summary__grid-row: 2;

$product-page__add-to-cart--margin: 0 0 1rem 0;
$product-page__add-to-cart--pading: 0;

$product-page__attachment-item-margin-top: 1.5rem;

$product-page__attachment-title-margin: 0 0 1.5rem 0;
$product-page__attachment-title-line-height: 4.5rem;

$product-page__attachment-icon--height: 3rem;

$product-page__attachment-link-label-margin-left: 0.5rem;

$product-block__border: 1px solid rgba(81, 90, 93, 0.3);
