.checkout-product {
    display: flex;
    padding: $checkout-product__padding;

    &__details {
        margin-left: $checkout-product-details__margin-left;
    }

    &__name-qty {
        margin-bottom: $checkout-product-name-qty__margin-bottom;
    }

    .price {
        color: $checkout-product-price__color;
        font-size: $checkout-product-price__font-size;
        font-weight: $checkout-product-price__font-weight;
    }

    &__options {
        margin: $checkout-product-options__margin;
    }

    &__option {
        margin: $checkout-product-option__margin;

        .label:after {
            content: $checkout-product-option-label__after-content;
        }
    }

    .product-image-container {
        flex: 1 0 $checkout-product-image__width;
    }
}
