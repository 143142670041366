.register-usps {
    height: 100%;
    position: relative;
    width: 100%;

    &__usps-wrapper {
        background: $register-usps__background;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}