$brand-list__background: transparent;
$brand-list__padding: 0;
$brand-list__border-radius: $border-radius__default;

$brand-list-container__background: transparent;
$brand-list-container__margin: 0;
$brand-list-container__padding: 0;
$brand-list-container__border-radius: $border-radius__default;

$brand-list-list__padding: 2rem 0 2rem 0;
$brand-list-list__padding--desktop: 4rem 0 2rem 0;

$brand-list-list__align-items: center;
$brand-list-list__display: flex;
$brand-list-list__justify-content: flex-start;

$brand-list-item__align-items: center;
$brand-list-item__display: flex;
$brand-list-item__flex: 0 0 auto;
$brand-list-item__flex--desktop: 0 0 20%;
$brand-list-item__justify-content: flex-start;
$brand-list-item__justify-content--desktop: center;
$brand-list-item__margin-left: 5rem;
$brand-list-item__margin-left--desktop: 0rem;
$brand-list-item__padding-left--desktop: 4rem;

$brands-list-button-wrapper__justify-content: center;