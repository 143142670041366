.authentication-wrapper {
    position: relative;
}

.authentication {
    &__modal {
        max-width: 40rem;
        width: 80%;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__actions {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
