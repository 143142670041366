$password-strength-meter__background--default: $color--light-grey !default;
$password-strength-meter__border-radius: $border-radius__default !default;
$password-strength-meter__color: $color--dark-grey !default;
$password-strength-meter__height: 0.5rem !default;
$password-strength-meter__transition: $transition__default !default;

$password-strength-meter__background--weak: $color--dark-red !default;
$password-strength-meter__background--medium: $color--yellow !default;
$password-strength-meter__background--strong: $color--green !default;
$password-strength-meter__background--very-strong: $color--dark-green !default;
