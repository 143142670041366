@include max-screen($screen__m) {
    .sort-by {
        &__label {
            flex: 1 0 9rem;
            align-self: center;
            max-width: 9rem;
        }

        &__options {
            max-width: 11rem;
        }
    }
}
