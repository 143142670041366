.review-vote {
    $self: &;

    display: flex;
    flex-direction: row-reverse; /* Stars are printed in reversed order */
    justify-content: flex-end;

    input[type='radio'] + label:before,
    input[type='radio'] + label:after,
    input[type='radio'] + label span {
        display: none;
    }

    input[type='radio'] + label {
        padding: 0.4rem;
    }

    &__star {
        fill: $review-vote__star-color--empty;
        height: $review-vote__star-size;
        transition: fill $transition__default;
    }

    &:hover input[type='radio']:checked + label #{$self}__star,
    &:hover input[type='radio']:checked + label ~ label #{$self}__star {
        fill: $review-vote__star-color--empty;
    }

    input[type='radio']:checked + label #{$self}__star, /* checked star */
    input[type='radio']:checked + label:hover #{$self}__star, /* hover current checked star */
    input[type='radio']:checked + label ~ label #{$self}__star {
        /* previous stars from checked star */
        fill: $review-vote__star-color--full;
    }

    input[type='radio'] + label:hover #{$self}__star, /* hover current star */
    input[type='radio'] + label:hover ~ label #{$self}__star {
        /* hover previous stars in list */
        fill: $review-vote__star-color--full;
    }
}
