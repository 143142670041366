.block {
    $self: &;

    .block-title {
        color: $block-title__color;
        font-size: $block-title__font-size;
        font-style: normal;
        font-weight: $block-title__font-weight;
        line-height: 3.4rem;
        text-transform: none;

        a {
            color: $color--orange;
            font-weight: $font-weight__regular;
            font-size: $font-size__l;
        }
    }

    &--highlight {
        padding-bottom: 1rem;
        padding-top: 1rem;
        .print {
            margin-right: 1rem;
        }
    }

    .box-content {
        .content {
            margin-left: 0;
        }

        dl {
            margin: 0;
        }
    }
}

@include min-screen($screen__m) {
    .block {
        $self: &;

        &-addresses-default {
            #{$self}-content {
                display: flex;

                .box {
                    flex: 1 0 50%;
                }
            }
        }

        &-order-details-view {
            #{$self}-content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .box {
                    flex: 1 0 50%;
                    flex-wrap: wrap;

                    &:nth-child(2) {
                        order: 3;
                    }

                    &:nth-child(4) {
                        order: 4;
                    }
                }
            }
        }
    }
}
