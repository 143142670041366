.page-title {
    &--catalog-category-view {
        margin: $page-title--category-view__margin;
    }

    &--catalog-product-view {
        margin: $page-title--product-view__margin;
    }

    &--wishlist-index-index {
        margin: $page-title--wishlist-index__margin;
    }
}
