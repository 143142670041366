@mixin vertical-product-card($block) {
    border: $product-card__border;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    #{$block}:hover {
        border: $product-card-hover__border;
    }

    #{$block}__visual {
        margin-bottom: $product-card-image__margin-bottom;
        margin-right: 0;
    }

    #{$block}__info {
        flex: 1 0 auto;
    }
}

.product-card {
    $self: &;

    background: $product-card__background;
    border-bottom: $product-card__border-bottom--mobile;
    border-radius: $product-card__border-radius;
    display: flex;
    overflow: hidden;
    padding: $product-card__padding--mobile;

    &__visual {
        background: $product-card-image__background;
        flex: 0 0 25%;
        margin-right: $spacing__s;
        max-width: $product-card-image__max-width--mobile;
        padding: $product-card-image__padding;
    }

    &__image-link {
        display: block;
        line-height: 0;
    }

    .product-image-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .product-image-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__info {
        display: flex;
        flex-direction: column;
        flex: 1 0 calc(75% - #{$spacing__s});
        justify-content: space-between;
        padding: $product-card-info__padding;
    }

    &__swatches {
        margin-bottom: $product-card-swatches__margin-bottom;
    }

    &__name {
        color: $product-card-name__color;
        display: inline-block;
        font-size: $product-card-name__font-size;
        font-weight: $product-card-name__font-weight;
        line-height: $product-card-name__line-height;
        margin-bottom: $product-card-name__margin-bottom;
    }

    &__shop {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .price-box {
        margin: $product-card-price__margin;
    }

    @if $product-card-button__enabled == false {
        &__addtocart-form {
            display: none;
        }
    }

    &__button {
        font-size: $product-card-button__font-size;
        font-weight: $product-card-button__font-weight;
        justify-content: $product-card-button__justify-content;
        min-width: $product-card-button__min-width;
        padding: $product-card-button__padding;
    }

    &__icon-cart {
        margin-right: $product-card-button-icon__margin-right;
    }

    &__button-label {
        display: none;
    }

    &--vertical {
        @include vertical-product-card($self);
    }

    &--horizontal {
        flex-direction: row;
    }
}

@include min-screen($screen__s) {
    .product-card {
        $self: &;
        @include vertical-product-card($self);
        padding: $product-card__padding;

        &--horizontal {
            flex-direction: row;
        }

        &__visual {
            padding: $product-card-image__padding--desktop;
        }

        &__info {
            padding: $product-card-info__padding-desktop;
        }

        &--swatches {
            #{$self}__info {
                position: relative;
            }

            #{$self}__swatches {
                order: 1;
            }

            #{$self}__name-desc {
                order: 2;
                padding-top: $product-card-name-desc__padding-top--swatches;
            }

            #{$self}__shop {
                order: 3;
            }

            #{$self}__swatches {
                margin-bottom: $product-card-swatches__margin-bottom--desktop;
                position: absolute;
                top: $product-card-swatches__top--swatches;
                width: 100%;
            }
        }

        &__name {
            margin-bottom: $product-card-name__margin-bottom--desktop;
        }

        &__visual {
            max-width: $product-card-image__max-width;
        }

        @if $product-card-info__align-center == true {
            &__info {
                text-align: center;
            }

            &__shop {
                align-items: center;
                flex-direction: column;
            }

            .price-box {
                margin-right: 0;
            }
        }
    }
}
