$sort-by--border: 0 !default;
$sort-by--color: $color--default !default;
$sort-by-icon--background: none !default;
$sort-by-icon--radius: 0 !default;
$sort-by-icon--fill: $color--default !default;
$sort-by-icon--height: initial !default;

$sort-direction-label--display: none !default;
$sort-direction--display: flex !default;

$sort-by-label--display--mobile: none !default;
$sort-by-label--display: none !default;
$sort-by-label--columns: 1fr 4rem !default;
$sort-by-label--line-height: inherit !default;
$sort-by-label--margin: 0 !default;
$sort-by-label--color: $color--default !default;
