$breadcrumbs__color: $color--black !default;
$breadcrumbs__fill-icon: $color--black !default;
$breadcrumbs--icon-arrow__height: 1.2rem !default;
$breadcrumbs__font-size: $font-size--m !default;

$breadcrumbs-show-home-icon: true !default;

$breadcrumbs-icon-home__fill: currentColor !default;
$breadcrumbs-icon-home__height: 3rem !default;

$breadcrumbs__color--current: $color--grey !default;
$breadcrumbs__font-weight--current: $font-weight__default !default;

$breadcrumbs__font-weight--mobile: $font-weight__default !default;
$breadcrumbs-link__color--mobile: $text__color-link !default;
$breadcrumbs-icon-arrow__fill--mobile: $text__color-link !default;
$breadcrumbs-separator__margin: 0 0.6rem 0 0 !default;
