.checkout-payment-method {
    .payment-method._active .payment-method-content.payment-method-content--multisafepay {
        display: grid !important; //style override for _buckaroo.scss in FUN
        grid-gap: 1rem;
    }

    .payment-method-content {
        .billing-address-same-as-shipping-block,
        select {
            margin: 2rem 0 0 0;
        }

        br {
            display: none;
        }

        .billing-address-details br {
            display: block;
        }
    }

    .field-select-billing,
    .billing-address-form {
        max-width: 61rem;
    }

    .checkout-agreements .field.choice {
        display: grid;
    }
}

@include min-screen($screen__l) {
    .checkout-payment-method {
        .payment-method._active .payment-method-content.payment-method-content--multisafepay {
            grid-template-columns: repeat(4, 1fr);

            > .messages,
            > .select,
            > .payment-method-billing-address--multisafepay {
                grid-column: span 4;
            }

            > .checkout-agreements-block {
                grid-column: span 3;
            }
        }
    }
}
