/**
 * Example for loading animation...
 */
@keyframes loading-bar {
    from {
        -webkit-transform: translateX(0%) scaleX(0.3);
        transform: translateX(0%) scaleX(0.3);
    }
    40% {
        -webkit-transform: translateX(100%) scaleX(0.5);
        transform: translateX(100%) scaleX(0.5);
    }
    95% {
        -webkit-transform: translateX(200%) scaleX(0.6);
        transform: translateX(200%) scaleX(0.6);
    }
    to {
        -webkit-transform: translateX(200%) scaleX(0.3);
        transform: translateX(200%) scaleX(0.3);
    }
}
