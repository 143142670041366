.price-box {
    flex-direction: column;

    .decimal {
        bottom: 0.4em;
        font-size: 0.65em;
        position: relative;
    }

    .old-price {
        color: $color--default;
        padding: 0.5rem 0;

        .price {
            color: $color--default;
            font-size: $old-price__font-size;
            font-weight: $font-weight__bold;
            opacity: 0.3;
        }

        .price-including-tax {
            display: none;
        }
    }

    .price-final_price {
        color: $regular-price__color;
        display: flex;
        flex-direction: column;
        font-size: $final-price__font-size;

        .price-including-tax {
            color: $color--default;
            font-size: 0.57em;
            font-weight: $font-weight__default;
        }

        .tax {
            font-size: 0.65em;
        }
    }

    .minimal-price {
        .price-label {
            display: block;
        }

        .price-wrapper {
            .price {
                color: $regular-price__color;
                display: flex;
                flex-direction: column;
                font-size: $final-price__font-size;
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper.price-including-tax {
            display: none;
        }

        .price-including-tax {
            color: $color--default;
            font-weight: $font-weight__default;

            .price {
                font-size: 1.425rem;
            }
            .tax {
                font-size: 0.92625rem;
            }
        }
    }
}

@include min-screen($screen__m) {
    .price-box {
        flex-direction: row;
        flex-wrap: wrap;

        .price-final_price {
            font-size: $final-price__font-size--desktop;
        }

        .minimal-price {
            .price-excluding-tax {
                .price {
                    font-size: $final-price__font-size--desktop;
                }
            }
        }
    }
}
