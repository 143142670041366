$usps__color--default: $color--white;
$usps__background--default: $color--primary;
$usps__padding--default: 0 0 1.5rem 0;
$usps__justify-content--default: space-between;

$usps-item__margin--default: 0 1rem;

$usps-icon__fill--default: $color--white;
$usps-icon__height: 2.4rem;
$usps-icon__margin--default: 0 1rem 0 0;

$usps__padding--checkout: 0;
$usps__background--account: $color--white;
$usps-icon__fill--account: $color--blue;
$usps__padding--account: 2rem 2rem 2rem 0;
$usps-icon__margin--account: 0 1rem 0 0;
