.checkout-payment-method {
    .payment-method {
        border: $checkout-payment-method__border;
        border-radius: $border-radius__default;
        padding: 1.5rem;
        margin: $checkout-payment-method__margin;

        &:last-of-type {
            margin: $checkout-payment-method__margin--last;
        }

        &._active {
            border: $checkout-payment-method__border--active;
        }

        &._active .payment-method-content {
            display: block;
        }
    }

    .payment-method-title,
    .payment-method-title .label {
        margin-bottom: 0;
    }

    .payment-icon {
        display: inline-block;
        margin-right: 1rem;
        vertical-align: middle;
    }

    .payment-method-content {
        display: none;
        padding: $payment-method-content__padding;
    }

    .payment-method-billing-address {
        padding: $payment-method-billing-address__padding;
    }

    .payment-method-billing-address .actions-toolbar {
        display: flex;
    }

    .field-select-billing,
    .billing-address-form {
        max-width: 50rem;
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .field-select-billing > .label,
    .payments .legend {
        display: none;
    }

    .checkout-agreements .field.choice {
        display: flex;
    }

    .checkout-agreements .field.choice input[type='checkbox'] + label {
        display: block;
    }
}
