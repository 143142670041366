// Sidebar is not conform standards
.opc-sidebar.opc-summary-wrapper {
    .modal-inner-wrap {
        background: $sidebar__background;
    }

    .modal-custom-overlay {
        background: none;
    }

    .modal-header {
        background: $sidebar-header__background;
        display: none;
        color: $sidebar-header__color;
        height: $sidebar-header__height;
    }

    &.modal-custom .action-close:before {
        display: none;
    }

    &.modal-custom .action-close {
        margin: 0.7rem 0 0 2rem;
        position: relative;
    }

    &.modal-custom .action-close:after {
        @include icon-bg-cross($sidebar-close-icon__fill, $sidebar-close-icon__size, $sidebar-close-icon__size);

        content: '';
        display: block;
        height: $sidebar-close-icon__size;
        width: $sidebar-close-icon__size;
    }

    &._show {
        + .modal-custom-overlay {
            background: $sidebar-overlay__background;
        }

        .modal-header {
            display: block;
        }

        .modal-content {
            background: $sidebar-modal__background;
            padding: $sidebar-modal__padding;
        }
    }
}

@include min-screen($screen__m) {
    .opc-sidebar.opc-summary-wrapper {
        .modal-inner-wrap {
            background: transparent;
        }

        .action-close,
        .modal-header,
        + .modal-custom-overlay {
            display: none;
        }

        .modal-content {
            background: transparent;
            padding: 0;
        }
    }
}
