$newsletter-input__width--mobile: 100%;
$newsletter-input__width--desktop: 100%;

$newsletter-input__height--desktop: 3.5rem;
$newsletter-input__height--mobile: 3.5rem;

$newsletter-input__margin--desktop: 0 1rem 1.2rem 0;

$newsletter-control__margin--desktop: 0;
$newsletter-control__margin--mobile: 0;
