.breadcrumbs {
    $self: &;

    li {
        margin: 0;
    }

    .items, //PDP
    &__items {
        display: none;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        &--mobile {
            display: flex;
            font-weight: $breadcrumbs__font-weight--mobile;

            #{$self}__link {
                color: $breadcrumbs-link__color--mobile;
            }

            #{$self}__separator {
                margin: $breadcrumbs-separator__margin;
            }

            #{$self}__icon-arrow {
                fill: $breadcrumbs-icon-arrow__fill--mobile;
            }
        }
    }

    .item, //PDP
    &__item {
        display: flex;
        font-size: $breadcrumbs__font-size;
    }

    a, //PDP
    &__link {
        color: $breadcrumbs__color;
    }

    &__link,
    &__current {
        align-items: center;
        display: flex;
    }

    &__separator {
        align-items: center;
        display: flex;
        margin: 0 1rem;
    }

    &__icon-arrow {
        fill: $breadcrumbs__fill-icon;
        height: $breadcrumbs--icon-arrow__height;
        position: relative;
        width: auto;
    }

    &__icon-home {
        display: none;
        fill: $breadcrumbs-icon-home__fill;
        height: $breadcrumbs-icon-home__height;
    }

    /* Checks if home icon is enabled, enabled the icon and disables the label for "home" */
    @if $breadcrumbs-show-home-icon == true {
        .item.home {
            a {
                color: rgba(0, 0, 0, 0);
                display: flex;
                font-size: 0;

                &:before {
                    @include icon-home(currentColor, 1.6rem, 3rem);

                    display: flex;
                    content: '';
                }
            }
        }

        &__item.home {
            #{$self}__link,
            #{$self}__icon-home {
                display: flex;
            }

            #{$self}__link-label {
                display: none;
            }
        }
    }

    .product strong, //PDP
    &__current {
        color: $breadcrumbs__color--current;
        font-weight: $breadcrumbs__font-weight--current;
    }

    /* Specific for breadcrumb on PDP */
    .item {
        align-items: center;
    }

    .item:not(:last-child):after {
        @include icon-arrow(currentColor, 1.3rem, 2rem);

        content: '';
        display: inline-block;
        height: 2rem;
        margin: 0 0.8rem 0 0.7rem;
        width: 1.3rem;
    }
}

@include min-screen($screen__m) {
    .breadcrumbs {
        $self: &;

        .items, //PDP
        &__items {
            display: flex;

            &--mobile {
                display: none;
            }
        }
    }
}
