@mixin button() {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    transition: background-color color $transition__default;
    white-space: nowrap;
    font-weight: $button__font-weight;

    &:hover,
    &:active,
    &:focus {
        outline: 0;
        text-decoration: none;
    }

    .icon {
        fill: currentColor;
        height: $button__height * 0.5;
        margin-right: $button__height * 0.2;
        position: relative;
        top: 0.1rem;
        width: auto;
    }

    &.is-loading {
        cursor: default;
        opacity: 0.5;
        overflow: hidden;
        pointer-events: none;
        position: relative;

        &:hover,
        &:active,
        &:focus {
        }

        &:before {
            animation: loading-bar 2s linear infinite;
            background-color: rgba($color--deep-black, 0.25);
            content: ' ';
            height: 4px;
            left: -100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    & .disabled,
    &.disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
}

button,
a.action.primary {
    @include button();

    background-color: $button__background;
    border: $button__border;
    border-radius: $button__border-radius;
    color: $button__color;
    height: $button__height;
    padding: $button__padding;
    font-family: $button__font;
    font-size: $button__font-size;
    font-weight: $button__font-weight;

    @if $button__shadow != '' {
        border-bottom: $button__shadow;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $button-hover__background;
        color: $button-hover__color;

        @if $button-hover__shadow != '' {
            border-bottom: $button-hover__shadow;
        }
    }
}

button.action.secondary,
a.action.secondary {
    @include button();

    background-color: $button-secondary__background;
    border: $button-secondary__border;
    border-radius: $button-secondary__border-radius;
    color: $button-secondary__color;
    height: $button-secondary__height;
    padding: $button-secondary__padding;
    font-family: $button-secondary__font;
    font-size: $button-secondary__font-size;
    font-weight: $button-secondary__font-weight;

    @if $button-secondary__shadow != '' {
        border-bottom: $button-secondary__shadow;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $button-secondary-hover__background;
        color: $button-secondary-hover__color;

        @if $button-secondary-hover__shadow != '' {
            border-bottom: $button-secondary-hover__shadow;
        }
    }
}

button.alt,
a.action.alt {
    @include button();

    background-color: $button-alt__background;
    border: $button-alt__border;
    border-radius: $button-alt__border-radius;
    color: $button-alt__color;
    font-family: $button-alt__font;
    font-size: $button-alt__font-size;
    font-weight: $button-alt__font-weight;
    height: $button-alt__height;

    &:hover,
    &:active,
    &:focus {
        background-color: $button-alt-hover__background;
        color: $button-alt-hover__color;
    }
}

@if $button-ghost__enabled == true {
    button.ghost,
    a.action.ghost {
        @include button();

        background-color: $button-ghost__background;
        border: $button-ghost__border;
        border-radius: $button-ghost__border-radius;
        color: $button-ghost__color;
        font-family: $button-ghost__font;
        font-size: $button-ghost__font-size;
        font-weight: $button-ghost__font-weight;
        height: $button-ghost__height;
        padding: $button-ghost__padding;

        &:hover,
        &:active,
        &:focus {
            background-color: $button-ghost-hover__background;
            color: $button-ghost-hover__color;
        }
    }
}

@if $button-ghost-alt__enabled == true {
    button.ghost-alt,
    a.action.ghost-alt {
        @include button();

        background-color: $button-ghost-alt__background;
        border: $button-ghost-alt__border;
        border-radius: $button-ghost-alt__border-radius;
        color: $button-ghost-alt__color;
        font-family: $button-ghost-alt__font;
        font-size: $button-ghost-alt__font-size;
        font-weight: $button-ghost-alt__font-weight;
        height: $button-ghost-alt__height;
        padding: $button-ghost-alt__padding;

        &:hover,
        &:active,
        &:focus {
            background-color: $button-ghost-alt-hover__background;
            color: $button-ghost-alt-hover__color;
        }
    }
}
