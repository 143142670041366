$add-to-link__color: $color--dark-grey !default;

$add-to-icon__fill: $color--dark-grey !default;
$add-to-icon__height: 1.6rem !default;
$add-to-icon__top: 0.2rem !default;

$add-to-label__padding: 0 0 0 0.8rem !default;

$add-to-link-hover__color--wishlist: $color--red !default;
$add-to-icon-hover__fill--wishlist: $color--red !default;
$add-to-link-hover__color--compare: $color--blue !default;
$add-to-icon-hover__fill--compare: $color--blue !default;
