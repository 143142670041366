.checkout-container {
    .opc-wrapper ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    /* address, shipping and payment containers */
    .opc-wrapper .checkout-shipping-address,
    .opc-wrapper #opc-shipping_method,
    .opc-wrapper .checkout-payment-method {
        background: $checkout-container-block__background;
        border: $checkout-container-block__border;
        border-radius: $checkout-container-block__border-radius;
        margin-bottom: $checkout-container-block__margin-bottom;
        padding: $checkout-container-block__padding;
    }

    /* sidebar containers */
    .opc-block-summary,
    .shipping-information {
        background: $checkout-container-block-sidebar__background;
        border: $checkout-container-block-sidebar__border;
        border-radius: $checkout-container-block-sidebar__border-radius;
        margin-bottom: $checkout-container-block-sidebar__margin-bottom;
        padding: $checkout-container-block-sidebar__padding;
    }

    .step-title,
    .opc-block-summary .title {
        font-size: $checkout-container-title__font-size;
        font-weight: $checkout-container-title__font-weight;
        line-height: $checkout-container-title__line-height;
        margin: $checkout-container-title__margin;
    }

    .opc-sidebar:focus {
        outline: none;
    }

    .actions-toolbar button {
        width: 100%;
    }

    .form-login {
        border-bottom: $checkout-container-form-login__border-bottom;
    }

    @if $checkout-tooltip__enable == false {
        .field-tooltip {
            display: none;
        }
    }
}

@include min-screen($screen__m) {
    .checkout-container {
        display: flex;
        flex-wrap: wrap;

        .opc-progress-bar,
        .opc-estimated-wrapper,
        .messages {
            width: calc(100% - (#{$checkout-sidebar__width} + #{$checkout-container-block__spacing--desktop}));
        }

        .opc-wrapper {
            margin: $checkout-container-block__spacing--desktop $checkout-container-block__spacing--desktop 0 0;
            width: calc(100% - (#{$checkout-sidebar__width} + #{$checkout-container-block__spacing--desktop}));
        }

        .opc-sidebar {
            margin: $checkout-sidebar__margin;
            width: $checkout-sidebar__width;
        }

        .opc-wrapper .checkout-shipping-address,
        .opc-wrapper #opc-shipping_method,
        .opc-wrapper .checkout-payment-method {
            margin-bottom: $checkout-container-block__spacing--desktop;
            padding: $checkout-container-block__padding--desktop;
        }

        .opc-block-summary,
        .shipping-information {
            background: $checkout-container-block-sidebar__background--desktop;
            border: $checkout-container-block-sidebar__border--desktop;
            border-radius: $checkout-container-block-sidebar__border-radius--desktop;
            margin-bottom: $checkout-container-block-sidebar__margin-bottom--desktop;
            padding: $checkout-container-block-sidebar__padding--desktop;
        }

        .actions-toolbar {
            text-align: right;
        }

        .actions-toolbar button {
            width: inherit;
        }
    }
}
