.price-box {
    align-items: $price-box__align-items;
    display: flex;
    flex-wrap: wrap;

    .price-label {
        display: none;
    }

    & > .price-container,
    & > .price-as-configured,
    .normal-price .price-container,
    .regular-price .price-container {
        color: $regular-price__color;
        font-size: $regular-price__font-size;
        font-weight: $regular-price__font-weight;
        line-height: $regular-price__line-height;
    }

    .old-price {
        color: $old-price__color;
        font-size: $old-price__font-size;
        font-weight: $old-price__font-weight;
        line-height: $old-price__line-height;
        margin-right: 1rem;
        order: 1;
        text-decoration: line-through;
    }

    .special-price {
        color: $special-price__color;
        font-size: $special-price__font-size;
        font-weight: $special-price__font-weight;
        line-height: $special-price__line-height;
        order: 2;
    }
}
