/**
 * Intentionally default Magento structure,
 * because a module like Algolia is using the same structure, but are difficult to change.
 */
.pages {
    align-items: center;
    display: flex;
    justify-content: center;

    .label,
    .next span,
    .previous span {
        display: none;
    }

    .items {
        align-items: center;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .item a,
    .item.current {
        align-items: center;
        background: $pagination__background-color;
        border: $pagination__border;
        border-radius: $pagination__border-radius;
        color: $pagination__color;
        display: flex;
        font-size: $pagination__font-size;
        line-height: $pagination__line-height;
        height: $pagination__height;
        justify-content: center;
        margin-right: $pagination__spacing;
        text-decoration: none;
        transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
        width: $pagination__width;
        font-weight: $pagination__font-weight;
    }

    .item.a:last-child {
        margin-right: 0;
    }

    .item a:hover {
        background-color: $pagination__background-color--hover;
        border: $pagination__border--current;
        color: $pagination__color--hover;
    }

    .item.current {
        background-color: $pagination__background-color--current;
        border: $pagination__border--current;
        color: $pagination__color--current;
        cursor: default;
    }

    .item .next,
    .item .previous {
        position: relative;
    }

    .item .next:after,
    .item .previous:after {
        @include icon-arrow($pagination__icon-color, $pagination__icon-width, $pagination__icon-height);

        content: '';
        display: block;
        height: 100%;
        left: calc(50% - #{$pagination__icon-width * 0.5});
        position: absolute;
        top: calc(50% - #{$pagination__icon-height * 0.5});
        width: 100%;
    }

    .item .next:hover:after,
    .item .previous:hover:after {
        @include icon-arrow($pagination__icon-color--hover, $pagination__icon-width, $pagination__icon-height);
    }

    .item .previous {
        transform: rotate(180deg);
    }
}
