.category-overview {
    &__category-list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        background: $category-overview-item__color;
        border-radius: $category-overview-item__radius;
        overflow: hidden;
        display: flex;
        height: $category-overview-item__height--mobile;
        margin: $category-overview-item__margin--mobile;
    }

    &__image-link {
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        position: relative;
        width: $category-overview-image-link__width--mobile;
    }

    &__image {
        height: auto;
        left: 0;
        position: absolute;
        top: 0;
        width: $category-overview-image__width;

        img {
            width: $category-overview-image__width;
        }
    }

    &__link {
        width: $category-overview-link__width--mobile;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color--default;
        padding: $category-overview-image-link__padding--mobile;

        &:hover {
            color: $color--default;
            text-decoration: none;
        }
    }

    &__arrow {
        height: $category-overview-arrow__height;
        min-width: $category-overview-arrow__width;
    }
}

@media screen and (min-width: $screen__s) {
    .category-overview {
        &__category-list {
            display: grid;
            grid-template-columns: $category-overview__grid-columns;
            grid-gap: $category-overview__grid-gap;
        }

        &__item {
            display: block;
            height: $category-overview-item__height;
            margin: $category-overview-item__margin;

            &:hover {
                box-shadow: $category-overview-item__box-shadow--hover;
            }
        }

        &__link {
            width: $category-overview-link__width;
            padding: $category-overview-image-link__padding;
        }

        &__image-link {
            width: $category-overview-image-link__width;
        }
    }
}
