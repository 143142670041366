.product-card {
    $self: &;

    &--horizontal {
        padding: $product-card-horizontal__padding;

        #{$self}__info {
            flex: $product-card-horizontal-info__flex;
            padding: $product-card-horizontal-info__padding;
            width: $product-card-horizontal-info__width;
        }

        #{$self}__visual {
            max-width: $product-card-horizontal-visual__max-width;
        }

        #{$self}__addtocart-form {
            display: $product-card-horizontal-addtocart__display;
        }

        #{$self}__shop {
            align-items: $product-card-horizontal-shop__align-items--mobile;
            flex-direction: $product-card-horizontal-shop__flex-direction--mobile;
        }

        #{$self}__button {
            margin: $product-card-horizontal-button__margin--mobile;
        }
    }
}

@include min-screen($screen__s) {
    .product-card {
        $self: &;

        &--horizontal {
            #{$self}__shop {
                align-items: $product-card-horizontal-shop__align-items;
                flex-direction: $product-card-horizontal-shop__flex-direction;
            }

            #{$self}__button {
                margin: $product-card-horizontal-button__margin;
            }
        }
    }
}
