$success-page__max-width: 62rem !default;
$success-page__margin: 0 !default;

$success-page-intro__margin: 0 !default;
$success-page-intro__text-align: left !default;

$success-page-steps__background-color: $color-white !default;
$success-page-steps__margin: 0 !default;

$success-page-contact__background: $color-white !default;
$success-page-contact__padding: 2rem !default;
$success-page-contact__radius: 0.5rem !default;
$success-page-contact__margin: 0 !default;

$success-page-step-title__margin: 0 0 2rem !default;

$success-page-step__display: flex !default;
$success-page-step__align-items: flex-start !default;
$success-page-step__line-height: 3rem !default;
$success-page-step__margin: 0 0 2rem !default;

$success-page-icon-wrapper__padding: 0.3rem 1.5rem 0 0 !default;

$success-page-step-icon__height: 1.9rem !default;
$success-page-step-icon__min-width: 1.9rem !default;

$success-page-contact__margin: 0 0 2rem !default;

$success-page-button__padding: 0 2rem !default;
$success-page-button__width: inherit !default;
$success-page-button__max-width: 100% !default;
$success-page-button__margin: 0 !default;

$success-page-content__margin: 0 !default;