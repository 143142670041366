.header-inner {
    @include page-alignment();

    align-items: center;
    display: flex;
    padding: 1rem 2rem;
}

.header-top {
    $self: &;

    background: $header-top__background;
    color: $header-top__color;
    position: relative;
    z-index: $header-top__z-index;

    &__inner {
        justify-content: $header-top__justify-content;
    }

    .icon {
        fill: currentColor;
    }

    &--checkout {
        #{$self}__inner {
            justify-content: $header-top__justify-content--checkout;
        }
    }
}
.header-primary {
    background: $header-primary__background;
    position: relative;
    z-index: $header-primary__z-index;

    @if $header-primary__is-sticky == true {
        position: sticky;
        top: $header-primary__top;
        z-index: $header-primary__z-index;
    }

    &__inner {
        justify-content: space-between;
        width: 100%;
    }

    .header-logo {
        flex: 1 1 auto;
        line-height: $header-logo__line-height;
        margin: $header-logo__margin;
        text-align: center;
    }

    &__actions {
        display: flex;
    }

    &__action {
        margin-left: 4rem;
    }

    &--checkout {
        border-bottom: $header-primary__bottom--checkout;

        .header-logo {
            flex: 0 0 auto;
        }
    }
}

.header-navigation {
    background: $header-navigation__background;
    border-bottom: $header-navigation__border-bottom;
    border-top: $header-navigation__border-top;
    display: none;
    position: relative;
    z-index: $header-navigation__z-index;

    &__inner {
        justify-content: flex-start;
        padding: $header-navigation__padding;
    }
}

.header-bottom {
    background: $header-bottom__background;
    border-bottom: $header-bottom__border-bottom;

    &__inner {
        justify-content: center;
    }
}

.header-search {
    border-bottom: $header-bottom__border-bottom;
    padding: $header-search__padding;

    @if $header-search__is-sticky == true {
        position: sticky;
        top: $header-search__top;
        z-index: 1;
    }
}

@include min-screen($screen__m) {
    .header-primary {
        &__inner {
            padding-bottom: 2rem;
            padding-top: 2rem;
        }

        .header-logo {
            flex: inherit;
        }

        .search {
            display: $header-primary-search__display;
            flex: $header-primary-search__flex;
            justify-content: $header-primary-search__justify-content;
        }
    }

    .header-navigation {
        display: block;
    }

    .header-search {
        display: none;
    }
}
