.text-block {
    $self: &;

    &__inner {
        display: grid;
        grid-gap: 6.9rem;
        grid-template-columns: 1fr 1fr;
    }

    &__subtitle {
        margin-bottom: $text-block-subtitle__margin-bottom;
    }

    &__image {
        display: block;
        max-width: 35rem;
        margin: 0 auto;
    }

    h1 {
        display: none;
    }

    @media screen and (max-width: 768px) {
        &__inner {
            display: block;
        }

        &__image {
            max-width: 75%;
            margin: 3.6rem auto;
        }
    }
}
