.review-stars {
    $self: &;

    align-items: center;
    display: flex;
    height: $review-stars__width;
    position: relative;
    width: calc(#{$review-stars__width} * 5);

    &__stars {
        overflow: hidden;
    }

    &__stars-wrapper {
        display: flex;
        width: calc(#{$review-stars__width} * 5);

        #{$self}__star-icon:last-child {
            margin-right: 0;
        }
    }

    &__star-icon {
        margin-right: 0.5rem;
    }

    &__stars--empty {
        #{$self}__star-icon {
            fill: $review-stars__color--empty;
        }
    }

    &__stars--full {
        position: absolute;

        #{$self}__star-icon {
            fill: $review-stars__color--full;
        }
    }

    &__stars--loading {
        #{$self}__star-icon {
            fill: $review-stars__color--loading;
        }
    }
}
