.cart-qty {
    display: flex;
    border: 0.1rem solid $color--dark-grey;
    border-radius: 0.3rem;
    height: 6rem;
    margin: 0 0.5em 0 0;
    position: relative;

    &__input {
        border: 0;
        height: 80%;
        text-align: center;
        font-size: $font-size__l;
        font-weight: bold;
        padding: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    &__button {
        height: 100%;
        min-width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        cursor: pointer;
        user-select: none;
    }

    #qty-error {
        bottom: 5rem;
        color: $color--orange;
        margin: 0 0 1rem;
        position: absolute;
        width: 58rem;
    }

    input.mage-error {
        background-color: rgba($color--orange, 0.2);
        border: 1px solid $color--orange;
        color: $color--orange;
    }
}
