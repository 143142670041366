.sort-by {
    &__label {
        display: $sort-by-label--display--mobile;
        line-height: $sort-by-label--line-height;
        margin: $sort-by-label--margin;
        color: $sort-by-label--color;
    }

    &__direction-label {
        display: $sort-direction-label--display;
    }

    &__options {
        margin-right: 1rem;
        border: $sort-by--border;
        color: $sort-by--color;
    }

    &__direction {
        align-items: center;
        display: $sort-direction--display;
        height: 4rem;
        justify-content: center;
        width: 4rem;
        background: $sort-by-icon--background;
        border-radius: $sort-by-icon--radius;

        &:hover {
            background: $color--primary-dark;
        }
    }

    &__direction-icon {
        fill: $sort-by-icon--fill;
        height: $sort-by-icon--height;
    }
}

@include min-screen($screen__s) {
    .sort-by {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: $sort-by-label--columns;

        &__label {
            display: $sort-by-label--display;
        }
    }
}
