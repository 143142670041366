.message {
    border: $message__border;
    border-radius: $message__border-radius;
    margin: $message__margin;
    padding: $message__padding;
    font-weight: $message__font-weight;
    font-size: $message__font-size;
    line-height: $message__line-height;

    a {
        color: currentColor;
        text-decoration: underline;
    }
}

.message.success {
    background: $success__background-color;
    border: $success__border;
    color: $success__color;
}

.message.info,
.message.notice,
.message.warning {
    background: $warning__background-color;
    border: $warning__border;
    color: $warning__color;
}

.message.error {
    background: $error__background-color;
    border: $error__border;
    color: $error__color;
}
