.add-to-cart {
    &__qty {
        display: none;
    }

    &__button {
        width: 100%;
    }

    &__button-label {
        display: inline;
    }
}

@include min-screen($screen__m) {
    .add-to-cart {
        &__button {
            max-width: $add-to-cart-button__max-width;
        }
    }
}
