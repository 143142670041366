.banner {
    $self: &;

    &__title {
        line-height: 1.1;
        text-align: center;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    }

    &__content-inner {
        a.secondary {
            background: $color--orange;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 1);

            &:hover {
                background: $color--dark-orange;
            }
        }
    }
}
