.customer-account-login,
.customer-account-forgotpassword,
.customer-account-create,
.customer-account-createpassword {
    .page-title-wrapper {
        text-align: $account-page-before-login-title__text-align;
    }
}

.account-page-before-login {
    &__block {
        background: $account-page-before-login-block__background;
        border: $account-page-before-login-block__border;
        border-radius: $account-page-before-login-block__border-radius;
        overflow: hidden;
        padding: $account-page-before-login-block__padding;
        width: 100%;
    }

    &__block + &__block {
        margin-top: $account-page-before-login-block__spacing;
    }
}

@include min-screen($screen__m) {
    .customer-account-login,
    .customer-account-forgotpassword,
    .customer-account-create,
    .customer-account-createpassword {
        .page-title-wrapper {
            text-align: $account-page-before-login-title__text-align--desktop;
        }
    }

    .account-page-before-login {
        display: flex;
        flex-direction: $account-page-before-login__flex-direction--desktop;
        justify-content: $account-page-before-login__justify-content--desktop;

        &__block {
            max-width: $account-page-before-login-block__max-width--desktop;
            padding: $account-page-before-login-block__padding--desktop;
        }

        &__block + &__block {
            margin: $account-page-before-login-block__margin-desktop;
        }

        &__block--register-usps {
            padding: $account-page-before-login-block__padding--register-usps;
        }
    }
}
