$account-nav__background: transparent !default;
$account-nav__border: 1px solid $color--grey !default;
$account-nav__border-radius: $border-radius__default !default;
$account-nav__margin: 0 !default;
$account-nav__padding: 0 !default;

$account-nav__background--desktop: transparent !default;
$account-nav__border--desktop: 1px solid $color--grey !default;
$account-nav__border-radius--desktop: $border-radius__default !default;
$account-nav__margin--desktop: 0 !default;
$account-nav__padding--desktop: 0 !default;

$account-nav-item__background--current: transparent !default;
$account-nav-item__border: 1px solid $color--light-grey !default;
$account-nav-item__border--desktop: 1px solid $color--light-grey !default;
$account-nav-item__color: $text__color-link !default;
$account-nav-item__color--hover: $text__color-link--hover !default;
$account-nav-item__color--current: $color--black !default;
$account-nav-item__font-weight--current: $font-weight__bold !default;
$account-nav-item__padding: 1rem !default;
$account-nav-item__padding--desktop: 1rem !default;
$account-nav-item__text-decoration--hover: underline !default;

$account-nav-item__color--current: $color--default !default;

$account-nav-icon__enabled: true !default;
$account-nav-icon__enabled--desktop: true !default;
$account-nav-icon__size: 1.6rem !default;
$account-nav-icon__fill: $color--black !default;
$account-nav-icon__top: 1.2rem !default;
$account-nav-icon__right: 1rem !default;
