$checkout-container-block__background: transparent !default;
$checkout-container-block__border: 1px solid $color--black !default;
$checkout-container-block__border-radius: $border-radius__default !default;
$checkout-container-block__margin-bottom: $spacing__s !default;
$checkout-container-block__padding: 2rem !default;

$checkout-container-block__spacing--desktop: $spacing__m !default;
$checkout-container-block__padding--desktop: 3rem !default;

$checkout-container-block-sidebar__background: $color--white !default;
$checkout-container-block-sidebar__border: 0 !default;
$checkout-container-block-sidebar__border-radius: 0 !default;
$checkout-container-block-sidebar__margin-bottom: $spacing__s !default;
$checkout-container-block-sidebar__padding: 2rem !default;

$checkout-container-block-sidebar__background--desktop: transparent !default;
$checkout-container-block-sidebar__border--desktop: 1px solid $color--black !default;
$checkout-container-block-sidebar__border-radius--desktop: $border-radius__default !default;
$checkout-container-block-sidebar__margin-bottom--desktop: $spacing__s !default;
$checkout-container-block-sidebar__padding--desktop: 2rem !default;

$checkout-container-title__font-size: $font-size__xl !default;
$checkout-container-title__font-weight: $font-weight__bold !default;
$checkout-container-title__line-height: $line-height__xl !default;
$checkout-container-title__margin: $margin__xl !default;

$checkout-container-form-login__border-bottom: 1px solid $color--light-grey !default;

$checkout-sidebar__width: 38.5rem !default;
$checkout-sidebar__margin: -5rem 0 0 0 !default;

$checkout-tooltip__enable: false !default;
