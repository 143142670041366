@include min-screen($screen__m) {
    .catalogsearch-result-index {
        .filters {
            &__categories {
                border-bottom: 1px solid $color--grey;
                margin-top: 7.6rem;
            }
        }
    }
}
