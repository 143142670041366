.success-page {
    margin: $success-page__margin;
    max-width: $success-page__max-width;

    &__intro {
        margin: $success-page-intro__margin;
        text-align: $success-page-intro__text-align;
    }

    &__contact {
        background: $success-page-contact__background;
        border-radius: $success-page-contact__radius;
        margin: $success-page-contact__margin;
        padding: $success-page-contact__padding;
    }

    &__step-title {
        margin: $success-page-step-title__margin;
    }

    &__steps {
        background-color: $success-page-steps__background-color;
        margin: $success-page-steps__margin;
    }

    &__step {
        align-items: $success-page-step__align-items;
        display: $success-page-step__display;
        line-height: $success-page-step__line-height;
        margin: $success-page-step__margin;
    }

    &__icon-wrapper {
        padding: $success-page-icon-wrapper__padding;
    }

    &__step-icon {
        height: $success-page-step-icon__height;
        min-width: $success-page-step-icon__min-width;
    }

    &__button {
        padding: $success-page-button__padding;
        margin: $success-page-button__margin;
        max-width: $success-page-button__max-width;
        width: $success-page-button__width;
    }

    &__content {
        margin: $success-page-content__margin;
    }
}