@mixin button() {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
    background-color: $color--orange;
    border: 0;
    border-radius: 0.3rem;
    color: white;
    height: 6rem;
    padding: 0 5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    &:hover {
        color: $color--white;
        background: darken($color--orange, 15%);
    }
}

.success-page {
    &__intro-paragraph {
        line-height: 1.3rem;
        margin: 4.5rem 0 0.5rem 0;
    }

    &__step-icon {
        fill: $color--primary;
    }

    &__button {
        @include button();
        padding: 0 8.8rem;
    }
}

@include min-screen($screen__m) {
    .success-page {
        &__title {
            line-height: 3rem;
        }
    }
}
