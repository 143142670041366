.read-more-block {
    &__full {
        max-height: $read-more-block-full__max-height;
        overflow: hidden;
        transition: $read-more-block-full__transition;
    }

    &.is-open &__full {
        max-height: $read-more-block-full__max-height--is-open;
    }

    &__toggle {
        color: $read-more-block-toggle__color;

        &:hover {
            color: $read-more-block-toggle__color--hover;
            cursor: pointer;
        }
    }
    &__read-more,
    &__read-less {
        text-decoration: $read-more__text-decoration;
    }

    &.is-open &__read-more,
    &__read-less {
        display: none;
    }

    &__read-more,
    &.is-open &__read-less {
        display: flex;
        align-items: center;
    }

    &__content-large-screen {
        display: none;
    }

    &__icon {
        fill: $read-more-icon__color;
        margin: $read-more-icon__margin;
    }
}