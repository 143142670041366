//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

.form-address-edit {
    #region_id {
        display: none;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

@include max-screen($screen__m) {
    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }
}

@include min-screen($screen__m) {
    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}
