$social__padding: 0;

$social-title__color: $color--default;
$social-title__font-weight: $font-weight__bold;
$social-title__font-size: $font-size__default;
$social-title__margin: 0 0 0.5rem 0;
$social-title__line-height: $line-height__default;
$social-title__text-align: center;
$social-title__text-align--desktop: left;

$social-item__background: $color--light-grey;
$social-item__height: 5rem;
$social-item__width: 5rem;
$social-item__border-radius: 50%;
$social-item__spacing: 1rem;
$social-item__position: relative;

$social-item__background--hover: $color--black;

$social-icon__fill: $color--black;
$social-icon__size: 50%;
$social-icon__fill--hover: $color--white;
$social-icon__position: relative;
$social-icon__bottom: 0;

$social-list__justify-content: center;
$social-list__justify-content--desktop: left;
