$sidebar__background: $color--light-grey !default;

$sidebar-header__background: $color--black !default;
$sidebar-header__color: $color--white !default;
$sidebar-header__height: 5.5rem !default;

$sidebar-close-icon__fill: $color--white !default;
$sidebar-close-icon__size: 2rem !default;
$sidebar-close-icon__margin: 0.7rem 0 0 2rem !default;

$sidebar-modal__background: $color--light-grey !default;
$sidebar-modal__padding: 2rem !default;

$sidebar-overlay__background: rgba($color--deep-black, 0.4) !default;
