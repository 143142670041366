$usps__background: $color--light-grey !default;
$usps__color: $color--default !default;
$usps__flex-direction: row !default;
$usps__height: inherit !default;

$usps-item__margin: 0 1rem 0 0 !default;
$usps-item__margin-bottom--column: 1rem !default;

$usps-title__color: $color--default !default;
$usps-title__display: block !default;
$usps-title__margin: $margin__xl !default;
$usps-title__width: 100% !default;
$usps-title__text-align: left !default;
$usps-title__font-size: inherit !default;
$usps-title__font-weight: inherit !default;

$usps-link__color: $color--default !default;
$usps-link__color--active: $color--default !default;

$usps-icon__fill: $color--default !default;
$usps-icon__height: 1.6rem !default;
$usps-icon__margin: 0 0.5rem 0 0 !default;

/* Default */
$usps__flex-direction--default: row !default;
$usps__align-items--default: center !default;
$usps__justify-content--default: center !default;
$usps__background--default: $usps__background !default;
$usps__padding--default: 2rem !default;
$usps__height--default: $usps__height !default;
$usps__color--default: $usps__color !default;
$usps__text-align--default: center !default;

$usps-item__margin--default: $usps-item__margin !default;

$usps-icon__fill--default: $color--default !default;
$usps-icon__margin--default: $usps-icon__margin !default;

/* Cart */
$usps__flex-direction--cart: column !default;
$usps__align-items--cart: flex-start !default;
$usps__justify-content--cart: flex-start !default;
$usps__background--cart: $usps__background !default;
$usps__padding--cart: 2rem !default;
$usps__height--cart: $usps__height !default;
$usps__color--cart: $usps__color !default;
$usps__margin--cart: 0 !default;
$usps__border--cart: 0 !default;
$usps__border-radius--cart: 0 !default;

$usps-item__margin--cart: $usps-item__margin !default;

$usps-icon__fill--cart: $usps-icon__fill !default;
$usps-icon__margin--cart: $usps-icon__margin !default;

/* PDP */
$usps__flex-direction--pdp: column !default;
$usps__align-items--pdp: flex-start !default;
$usps__justify-content--pdp: flex-start !default;
$usps__background--pdp: $usps__background !default;
$usps__padding--pdp: 2rem !default;
$usps__height--pdp: $usps__height !default;
$usps__color--pdp: $usps__color !default;

$usps-item__margin--pdp: $usps-item__margin !default;

$usps-icon__fill--pdp: $usps-icon__fill !default;
$usps-icon__margin--pdp: $usps-icon__margin !default;

/* Category */
$usps__flex-direction--category: column !default;
$usps__background--category: $color--white !default;
$usps__padding--category: 1rem !default;
$usps__font-weight--category: 600 !default;
$usps-item__margin--category: 0 0 1rem 0 !default;

/* Homepage */
$usps__flex-direction--homepage: column !default;
$usps__background--homepage: $color--white !default;
$usps__padding--homepage: 1rem !default;
$usps__font-weight--homepage: 600 !default;
$usps-item__margin--homepage: 0 0 1rem 0 !default;

/* Checkout */
$usps__flex-direction--checkout: column !default;
$usps__align-items--checkout: flex-start !default;
$usps__justify-content--checkout: flex-start !default;
$usps__background--checkout: $usps__background !default;
$usps__padding--checkout: 2rem !default;
$usps__height--checkout: $usps__height !default;
$usps__color--checkout: $usps__color !default;
$usps__margin--checkout: 0 !default;
$usps__border--checkout: 0 !default;
$usps__border-radius--checkout: 0 !default;

$usps-item__margin--checkout: $usps-item__margin !default;

$usps-icon__fill--checkout: $usps-icon__fill !default;
$usps-icon__margin--checkout: $usps-icon__margin !default;

/* Account */
$usps__flex-direction--account: column !default;
$usps__align-items--account: flex-start !default;
$usps__background--account: $usps__background !default;
$usps__justify-content--account: flex-start !default;
$usps__padding--account: 2rem !default;
$usps__height--account: $usps__height !default;
$usps__color--account: $usps__color !default;

$usps-item__margin--account: $usps-item__margin !default;
$usps-item__color--account: currentColor !default;

$usps-icon__fill--account: $color--default !default;
$usps-icon__margin--account: $usps-icon__margin !default;

/* Register */
$usps-title__color--register: $color--white !default;

$usps__flex-direction--register: column !default;
$usps__align-items--register: flex-start !default;
$usps__background--register: none !default;
$usps__justify-content--register: flex-start !default;
$usps__padding--register: 5rem !default;
$usps__height--register: $usps__height !default;
$usps__color--register: $color--white !default;

$usps-item__margin--register: $usps-item__margin !default;
$usps-item__color--register: $color--white !default;

$usps-icon__fill--register: $color--white !default;
$usps-icon__margin--register: $usps-icon__margin !default;

/* Register USP's block */
$register-usps__background: rgba($color--deep-black, 0.25) !default;
