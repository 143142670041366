$block__background: $color--white !default;
$block__border: 1px solid $color--grey !default;
$block__border-radius: $border-radius__default !default;
$block__margin-bottom: $spacing__m !default;
$block__padding: $spacing__m !default;

$block-title__margin-bottom: $spacing__m !default;
$block-title__font-size: $font-size__xl !default;
$block-title__font-weight: $font-weight__bold !default;
$block-title__line-height: $line-height__xl !default;

$block__background--highlight: $color--grey !default;
$block__border--highlight: 0 !default;
$block__color--highlight: $color--black !default;
$block-link__margin--highlight: 0 0 0 1rem !default;

$block-field__margin: $form-field__margin !default;

$block-box__border-bottom: 1px solid $color--light-grey !default;
$block-box__margin-bottom: $spacing__m !default;
$block-box__padding-bottom: $spacing__m !default;

$block-action__margin-top: $spacing__s !default;

$block-action-separator__border: 1px solid $color--grey !default;
$block-action-separator__spacing: $spacing__s !default;
$block-action-delete__color: $color--dark-red !default;

$block-back__margin-top: $spacing__m !default;
