.checkout-header-action {
    $self: &;

    color: $checkout-header-action__color;

    &__link {
        align-items: center;
        color: currentColor;
        display: flex;
    }

    &__icon {
        fill: $checkout-header-action-icon__fill;
        height: $checkout-header-action-icon__height;
        margin-right: $checkout-header-action-icon__margin-right;
        width: auto;

        #{$self}__link:hover & {
            fill: $checkout-header-action-icon-hover__fill;
        }
    }

    &__label {
        display: none;
    }
}

@include min-screen($screen__m) {
    .checkout-header-action {
        &__label {
            display: inline-block;
        }
    }
}
