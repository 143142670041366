.account-form {
    $self: &;

    &__link {
        color: $color--orange;
    }
}

@include min-screen($screen__m) {
    .account-form {
        &__button {
            max-width: 50%;
        }

        &--new-customer {
            margin-left: 12rem;
        }
    }
}
