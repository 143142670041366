.cart-qty {
    max-width: $cart-qty__max-width;
    width: $cart-qty__width;
    padding: $cart-qty__padding;

    select {
        margin: $cart-qty-select__margin;
    }

    button {
        max-width: $cart-qty-button__max-width;
    }

    &__hidden {
        display: none;

        &.is-active {
            display: block;
        }
    }
}
