@include min-screen($screen__m) {
    .page-layout-2columns-left-navigation {
        .page-title {
            margin-left: $two-col-left-nav-sidebar__width + $two-col-left-nav__grid-gap;
        }

        .columns {
            display: grid;
            grid-template-columns: $two-col-left-nav-sidebar__width auto;
            grid-gap: $two-col-left-nav__grid-gap;
        }

        .column.main {
            order: 2;
        }

        .sidebar-additional {
            order: 1;
            padding: $two-col-left-nav-sidebar__padding;
        }
    }
}
