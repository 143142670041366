@mixin slider($itemWidth) {
    $self: '.slider';

    #{$self}__wrapper {
        position: relative;

        &:after {
            background: $color--white;
            bottom: 0;
            content: '';
            display: block;
            height: 2rem;
            position: absolute;
            width: 100%;
        }
    }

    #{$self}__scroll {
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 0 2rem 0;
        scroll-behavior: smooth;
        scroll-snap-stop: always;
        scroll-snap-type: x mandatory;
    }

    #{$self}__items {
        display: grid;
        grid-auto-columns: $itemWidth;
        grid-auto-flow: column;
        grid-gap: 1rem;
    }

    #{$self}__item {
        min-height: 1rem;
        scroll-snap-align: start;
    }

    #{$self}__nav-wrapper {
        display: flex;
        justify-content: center;
    }

    #{$self}__nav {
        align-items: center;
        display: block;
        height: 4rem;
        justify-content: center;
        position: absolute;
        top: 40%;
        transition: opacity 250ms ease-in-out;
        width: 4rem;
        z-index: 1;
        &.is-disabled {
            opacity: 0.2;
        }
        svg {
            fill: $color--dark-grey;
        }

        &:hover svg:not(.is-disabled) {
            fill: $color__primary;
        }

        &.slider__nav--prev {
            left: 0;
        }

        &.slider__nav--next {
            right: 0;
        }
    }

    #{$self}__bullets {
        align-items: center;
        display: flex;
        height: 4rem;
        justify-content: center;
        z-index: 1;
    }

    #{$self}__bullet {
        background: $color--grey;
        border-radius: 50%;
        display: block;
        height: 1rem;
        margin: 0 0.5rem;
        width: 1rem;

        &.is-active {
            background: $color--black;
        }

        &.is-hidden {
            display: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.slider--all-devices {
    @include slider(calc((100% - 2rem) / 5));
}

@include max-screen($screen__m) {
    .slider--mobile {
        @include slider(95vw);
    }
    .slider__brands {
        @include slider(calc((100% - 2rem) / 3));
    }
}

@include min-screen($screen__m) {
    .slider--mobile {
        &__nav {
            display: none;
        }
    }
}
