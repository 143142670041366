.cart {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: $cart--margin-mobile;

    &__continue-shopping {
        align-items: center;
        display: flex;
        margin: $cart-continue-shopping--margin;
        width: $cart-continue-shopping--width;
    }

    &__items {
        width: 100%;
        align-self: flex-start;
    }

    &__summary-container {
        width: 100%;
        align-self: flex-start;
        position: $cart-summary-container--position;
        top: $cart-summary-container--top;
        margin: $cart-summary-container--margin-mobile;
    }

    &__crosssell {
        margin: $cart-crosssell--margin-mobile;
    }
}

@include min-screen($screen__m) {
    .cart {
        flex-direction: row;
        margin: $cart--margin;

        &__items {
            width: $cart-items--width;
        }

        &__summary-container {
            width: $cart-summary-container--width;
            margin: $cart-summary-container--margin;
        }

        &__crosssell {
            margin: $cart-crosssell--margin;
        }
    }
}

@include min-screen($screen__xl) {
    .cart {
        &__items {
            width: $cart-items--width--xl;
        }

        &__summary-container {
            width: $cart-summary-container--width--xl;
        }
    }
}
