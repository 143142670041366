@include max-screen($screen__m) {
    /**
     * Reset the sidebar styling, so we can style on the filters
     */
    .catalog-category-view .sidebar-main,
    .catalogsearch-result-index .sidebar-main {
        flex: inherit;
        margin: inherit;
        order: inherit;
    }

    .filter-is-open {
        overflow-y: hidden;

        @supports (-webkit-overflow-scrolling: touch) {
            height: 100%;
            overflow: hidden;
            position: fixed;
            width: 100%;
        }
    }

    .filters {
        $self: &;

        position: relative;

        &__content {
            background: $filters__background;
            display: block;
            height: 100%;
            left: calc(-100% + #{$filters__spacing-right});
            overflow-y: scroll;
            position: fixed;
            top: 0;
            transition: transform $filters__animation;
            width: calc(100% - #{$filters__spacing-right});
            z-index: 4;

            .filter-is-open & {
                transform: translateX(100%);
            }
        }

        &__header {
            align-items: center;
            background: $filters-header__background;
            color: $filters-header__color;
            display: grid;
            height: $filters-header__height;
            grid-template-columns: $filters-header__grid-template-columns;
            grid-gap: $filters-header__grid-gap;
            padding: $filters-header__padding;

            @if $filters__header-enabled != true {
                display: none;
            }
        }

        &__header-title {
            font-weight: $filters-header-title__font-weight;
        }

        &__header-reset-link {
            color: $filters-header-link__color;
            text-decoration: $filters-header-link__text-decoration;
        }

        &__header-close {
            align-items: center;
            display: flex;
        }

        &__close-icon {
            fill: $filters-close__fill;
            height: $filters-close__height;
            width: auto;
        }

        &__content-inner {
            padding: $filters-content-inner__padding;
        }

        &__categories {
            margin-bottom: 2rem;
        }

        &__cross {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: #{$filters__spacing-right};
            justify-content: center;
            opacity: 0;
            position: fixed;
            pointer-events: none;
            right: 0;
            top: 0;
            width: #{$filters__spacing-right};
            z-index: 20;

            @if $filters__header-enabled == true {
                display: none;
            }

            .filter-is-open & {
                opacity: 1;
                pointer-events: inherit;
            }
        }

        &__cross-icon {
            fill: $filters__cross-color;
            height: calc(#{$filters__spacing-right} / 2);
            width: auto;
        }

        &__overlay {
            background: $filters-overlay__background;
            display: block;
            height: 100%;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            transition: opacity 500ms ease-in-out;
            width: 100%;
            z-index: 3;

            .filter-is-open & {
                opacity: 1;
                pointer-events: inherit;
            }
        }

        &__show-results {
            background: $filters__background;
            bottom: 0;
            height: $filters-show-results__height;
            left: calc(-100% + #{$filters__spacing-right});
            padding: $filters-show-results__padding;
            position: fixed;
            transition: transform $filters__animation;
            width: calc(100% - #{$filters__spacing-right});
            z-index: $filters-show-results__z-index;

            .filter-is-open & {
                transform: translateX(100%);
            }

            @if $filters-show-results__shadow == true {
                &:before {
                    background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
                    content: '';
                    display: block;
                    height: 10px;
                    left: 0;
                    position: absolute;
                    pointer-events: none;
                    top: -1rem;
                    width: 100%;
                }
            }
        }

        &__show-results-button {
            width: 100%;
        }
    }
}

@include min-screen($screen__m) {
    .filters {
        &__cross,
        &__overlay,
        &__show-results,
        &__header {
            display: none;
        }
    }

    .toggle-filters {
        display: none;
    }
}
