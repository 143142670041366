h1,
.h1 {
    color: $color--blue;
    font-size: $font-size__xxxl;
    font-style: italic;
    font-weight: $font-weight__extra-bold;
    line-height: $line-height__xxxl;
    margin-bottom: 0.2em;
    text-transform: uppercase;
}

h2,
.h2 {
    color: $color--blue;
    font-size: $font-size__xxl;
    font-style: italic;
    font-weight: $font-weight__extra-bold;
    line-height: $line-height__xxl;
    margin-bottom: 0.2em;
    text-transform: uppercase;
}

h3,
.h3 {
    font-size: $font-size__xl;
    font-weight: $font-weight__bold;
    line-height: $line-height__xl;
    margin-bottom: 0.2em;
}

h4,
h5,
.h4,
.h5 {
    font-size: $font-size__l;
    font-weight: $font-weight__bold;
    line-height: $line-height__l;
    margin-bottom: 0.2em;
}

a:hover {
    text-decoration: none;
}

@include min-screen($screen__m) {
    h1,
    .h1 {
        font-size: $font-size__xxxl--desktop;
        line-height: $line-height__xxxl--dekstop;
    }

    h2,
    .h2 {
        font-size: $font-size__xxl--dekstop;
        line-height: $line-height__xxl--desktop;
    }

    h3,
    .h3 {
        font-size: $font-size__xl--desktop;
        line-height: $line-height__xl--desktop;
    }

    h4,
    h5,
    .h4,
    .h5 {
        font-size: $font-size__l--desktop;
        line-height: $line-height__l;
    }
}
