.footer {
    border-top: $footer__border-top;
    font-size: $footer__font-size;
    line-height: $footer__line-height;

    &__top {
        @include page-alignment();

        background: $footer-top__background;
        display: flex;
        padding: $footer-top__padding;
    }

    &__primary {
        background: $footer-primary__background-color;
        color: $footer-primary__color;
        font-weight: $footer-primary__font-weight;
        padding: $footer-primary__padding;

        a {
            color: $footer-link__color;
            text-decoration: $footer-link__text-decoration;

            &:hover,
            &:active,
            &:focus {
                color: $footer-link__color;
                text-decoration: $footer-link__text-decoration--hover;
            }
        }
    }

    &__blocks {
        @include page-alignment();

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
    }

    &__block {
        margin: $footer-block__margin;
        min-width: $footer-block__min-width;

        h4,
        .h4 {
            font-weight: $footer-block-title__font-weight;
        }
    }

    &__payment {
        @include page-alignment();

        display: flex;
        justify-content: center;
        padding: $footer-payment__padding;
    }

    &__usps {
        @include page-alignment();
    }
}

@include max-screen($screen__m) {
    .footer {
        &__block--collapsible {
            ul,
            p,
            span {
                display: none;
            }

            h4 {
                border-bottom: $footer-block-title__border-bottom;
                cursor: pointer;
                font-size: $footer-block-title__font-size;
                margin: $footer-block-title__margin;
                padding: $footer-block-title__padding;
                position: relative;

                &:after {
                    @include icon-bg-arrow-down($footer-primary__color, 1.6rem, 1.6rem);

                    content: '';
                    display: inline-block;
                    height: 1.6rem;
                    position: absolute;
                    right: 0;
                    top: 0.7rem;
                    transition: transform 150ms ease-in-out;
                    width: 1.6rem;
                }
            }

            &.is-open {
                ul,
                p,
                span {
                    display: block;
                    margin-bottom: 3.5rem;
                }

                h4:after {
                    transform: rotate(-180deg);
                    top: 1rem;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer {
        font-size: $footer__font-size--desktop;

        &__blocks {
            flex-direction: row;
        }

        &__block-container {
            max-width: $footer-block-container__max-width;
        }

        &__block {
            margin: $footer-block__margin--desktop;
            max-width: $footer-block__max-width;

            ul,
            p,
            span {
                display: block;
                margin-bottom: 0;
            }

            h4,
            .h4 {
                border: $footer-block-title__border--desktop;
                font-size: $footer-block-title__font-size--desktop;
                margin: $footer-block-title__margin--desktop;
                padding: $footer-block-title__padding--desktop;

                &:after {
                    display: none;
                }
            }
        }
    }
}
