$newsletter__padding: 0 !default;

$newsletter-title__font-size: $font-size__l !default;
$newsletter-title__font-weight: $font-weight__bold !default;
$newsletter-title__line-height: $line-height__default !default;
$newsletter-title__margin: 0 !default;

$newsletter-input__border--mobile: 0 !default;
$newsletter-input__height--mobile: 5rem !default;
$newsletter-input__margin--mobile: 0 0 1rem 0 !default;
$newsletter-input__width--mobile: inherit !default;
$newsletter-input__max-width--mobile: inherit !default;

$newsletter-input__border--desktop: 0 !default;
$newsletter-input__height--desktop: 5rem !default;
$newsletter-input__margin--desktop: 0 1rem 2rem 0 !default;
$newsletter-input__width--desktop: inherit !default;
$newsletter-input__max-width--desktop: inherit !default;

$newsletter-form__align-items--mobile: stretch !default;
$newsletter-form__flex-direction--mobile: column !default;
$newsletter-form__flex-wrap--mobile: wrap !default;
$newsletter-form__justify-content--mobile: space-between !default;

$newsletter-form__align-items--desktop: flex-start !default;
$newsletter-form__flex-direction--desktop: column !default;
$newsletter-form__flex-wrap--desktop: nowrap !default;
$newsletter-form__justify-content--desktop: space-between !default;

$newsletter-control__flex: 1 0 auto !default;
$newsletter-control__margin--mobile: 0 1rem 0 0 !default;
$newsletter-control__margin--desktop: 0 1rem 0 0 !default;
