@include min-screen($screen__m) {
    .page-layout-2columns-left {
        .column.main {
            flex: 1 0 calc(100% - (#{$layout__sidebar-width} + (2 * #{$spacing__m})));
        }

        .columns {
            display: flex;
            flex-direction: row;
            gap: 2rem;

            .main {
                width: 100%;
            }

            .sidebar {
                width: 29rem;
            }
        }
    }
}
