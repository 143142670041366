.fotorama {
    .fotorama__arr,
    .fotorama__arr:hover {
        background-color: transparent;
    }

    .fotorama__arr--prev .fotorama__arr__arr {
        left: 1rem;
    }
    .fotorama__arr--next .fotorama__arr__arr {
        left: inherit;
        right: 1rem;
    }

    .fotorama__arr--next .fotorama__arr__arr,
    .fotorama__arr--next:hover .fotorama__arr__arr,
    .fotorama__arr--prev .fotorama__arr__arr,
    .fotorama__arr--prev:hover .fotorama__arr__arr {
        background-size: 2.5rem;
        width: 2.2rem;
        height: 3rem;
    }
}

@include min-screen($screen__m) {
    .fotorama {
        .fotorama__stage {
            left: 0 !important;
        }
    }
}
