.review-list {
    $self: &;

    margin-bottom: 2rem;

    &__toolbar {
        display: flex;

        .limiter {
            display: none;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 4rem 0 0;
    }

    &__item {
        border-bottom: 1px solid $color--light-grey;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        &:last-child {
            border: 0;
        }
    }

    &__empty {
        margin-bottom: 2rem;
    }

    &--loading {
        #{$self}__list,
        #{$self}__item {
            border-color: transparent;
        }
    }
}
