$header-top__background: $color--primary !default;
$header-top__color: $color--black !default;
$header-top__justify-content: space-between !default;
$header-top__justify-content--checkout: space-between !default;
$header-top__z-index: 1 !default;

$header-primary__background: $color--white !default;
$header-primary__bottom--checkout: 1px solid $color--primary !default;
$header-primary__is-sticky: false !default;
$header-primary__top: 0 !default;
$header-primary__z-index: 2 !default;

$header-logo__line-height: 1 !default;
$header-logo__margin: 0 !default;

$header-primary-search__display: flex !default;
$header-primary-search__flex: 1 0 auto !default;
$header-primary-search__justify-content: flex-start !default;

$header-search__padding: 0 $spacing__s $spacing__s $spacing__s !default;
$header-search__is-sticky: false !default;
$header-search__top: 7rem !default;

$header-bottom__background: $color--light-grey !default;
$header-bottom__border-bottom: 1px solid $color--primary !default;

$header-navigation__background: $color--white !default;
$header-navigation__border-bottom: 0 !default;
$header-navigation__border-top: 0 !default;
$header-navigation__padding: 1rem 2rem !default;
$header-navigation__z-index: 1 !default;
