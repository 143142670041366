/**
 * Table totals for cart and checkout
 */
$table-totals__spacing: 0.6rem;
$table-totals__border-bottom: 0;
$table-totals__font-weight: $font-weight__default;

$table-totals-price__color: $color__default;

$table-totals-grand-total__border-top: 1px solid $color__default;

$table__border-color: $color--light-grey;
