.category-filter {
    background: $category-filter__background-color;
    border-radius: $category-filter__border-radius;
    overflow: hidden;
    padding: $category-filter__padding;

    &__current {
        color: $category-filter-current__color;
        display: block;
        font-weight: $category-filter-current__font-weight;
        margin-bottom: $category-filter-current__margin;
        padding: $category-filter-current__padding;
        width: 100%;
    }

    &__link {
        color: $category-filter-link__color;
        display: block;
        margin-bottom: $category-filter-link__margin;

        &:hover {
            color: $category-filter-link__color--hover;
        }

        &--parent {
            align-items: center;
            display: flex;
            position: relative;
            width: 100%;

            &:before {
                @include icon-arrow($category-filter-link__color, 1.8rem, 1.8rem, 180deg);

                content: '';
                display: inline-block;
                margin-right: 1rem;
            }
        }

        &--sub {
            padding: $category-filter-sub__padding;
            font-weight: $category-filter-sub__font-weight;
            text-decoration: $category-filter-sub__text-decoration;
        }
    }
}