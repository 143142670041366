$search__border-radius: 0.3rem;
$search__margin: 0 2rem 0 5rem;
$search__width: initial;

$search__padding--mobile: 0;
$search__width--mobile: 100%;

$search-form__margin--mobile: 0;
$search-form__padding--mobile: 0;

$search-form__max-width: 72rem;
$search-form__width: 100%;

$search-input__background: $color--white;
$search-input__border: 0.1rem solid $color--blue;
$search-input__border-width: 0;
$search-input__height: 4rem;
$search-input__padding: 0 5rem 0 1.5rem;
$search-input__margin: 0;
$search-input__max-width: inherit;

$search-submit__background: $color--white;
$search-submit__border: 0.1rem solid $color--blue;
$search-submit__hover-background: $color--blue;
$search-submit__hover-border: 0;
$search-submit__border-radius: 0 3px 3px 0;

$search-autocomplete__border: 1px solid $color--black;
$search-autocomplete__border-radius: 2px;

$submit-icon__height: 2.6rem;
$submit-icon__color: $color--blue;
$submit-icon__hover-color: $color--white;
