$form-field__background-color: $color--white !default;
$form-field__border-color: $color--dark-grey !default;
$form-field__border: 1px solid $form-field__border-color !default;
$form-field__border-color--focus: $color--black !default;
$form-field__box-shadow: inset 0 0 0.2rem rgba($form-field__border-color--focus, 0.2) !default;
$form-field__font: $font-family__default !default;
$font-field__height: 4rem !default;
$form-field__margin: 0 0 2rem 0 !default;
$form-field__max-width: 100% !default;
$form-field__padding: 0 1.5rem !default;
$form-field__placeholder-color: $color--grey !default;
$form-field__select-arrow-fill: $color--dark-grey !default;
$form-field__text-input-radius: 0.4rem !default;
$form-field__text-input-margin: 0 !default;
$form-field__width: 100% !default;

$form-field--label--margin: 0 0 1rem 0 !default;

$form-field-error__background-color: $error__background-color !default;
$form-field-error__border: 1px solid $error__color !default;
$form-field-error__color: $error__color !default;
$form-field-error__font-size: $font-size--s !default;
$form-field-error__margin: 0 0 1rem 0 !default;

$form-field-textarea__min-height: 10rem !default;
$form-field-textarea__padding-top: 1.5rem !default;
$form-field-textarea__padding-bottom: 1.5rem !default;

$form-field-select__background-position: calc(100% - 1.2rem) 1.2rem !default;

/**
 * Checkbox + Radio
 */
$form__use-custom-checkbox-and-radio: true !default;

$form-native-checkbox-radio__height: initial !default;
$form-native-checkbox-radio__width: initial !default;

$form-checkbox__background: $color--white !default;
$form-checkbox__border-width: 1px !default;
$form-checkbox__border-color: $color--dark-grey !default;
$form-checkbox__border-radius: 0.4rem !default;
$form-checkbox__box-shadow: inset 0 0 0.2rem rgba($form-checkbox__border-color, 0.2) !default;
$form-checkbox__background-color--hover: $color--light-grey !default;
$form-checkbox__checkmark-color--hover: transparent !default;
$form-checkbox__border-color--hover: $color--dark-grey !default;
$form-checkbox__checked-border-color: $color--dark-grey !default;
$form-checkbox__checked-background: $color--dark-grey !default;
$form-checkbox__checkmark-color: $color--white !default;
$form-checkbox__checkmark-line-height: 1.6rem !default;
$form-checkbox__checkmark-font-size: 1.6rem !default;
$form-checkbox__height: 2rem !default;
$form-checkbox__left: 0 !default;
$form-checkbox__top: 0 !default;
$form-checkbox__width: 2rem !default;

$form-checkbox__border-radius--inner: 0.2rem !default;
$form-checkbox__height--inner: 1.6rem !default;
$form-checkbox__left--inner: 0.3rem !default;
$form-checkbox__top--inner: 0.3rem !default;
$form-checkbox__width--inner: 1.6rem !default;

$form-radio__background: $color--white !default;
$form-radio__border-width: 1px !default;
$form-radio__border-color: $color--dark-grey !default;
$form-radio__box-shadow: inset 0 0 0.2rem rgba($form-radio__border-color, 0.2) !default;
$form-radio__height: 2rem !default;
$form-radio__width: 2rem !default;
$form-radio__top: 0rem !default;
$form-radio__left: 0rem !default;

$form-radio__border-radius: 50% !default;
$form-radio__border-color--checked: $color--dark-grey !default;
$form-radio__background-color--checked: $color--dark-grey !default;
$form-radio__background-color--hover: $color--light-grey !default;
$form-radio__border-color--hover: $color--dark-grey !default;
$form-radio__border-color--checked: $color--dark-grey !default;
$form-radio__border-radius--inner: 50% !default;
$form-radio__height--inner: 1.4rem !default;
$form-radio__width--inner: 1.4rem !default;
$form-radio__top--inner: 0.4rem !default;
$form-radio__left--inner: 0.4rem !default;
