@include min-screen($screen__m) {
    .page-layout-customer-service {
        .page-title {
            margin-left: $customer-service-sidebar__width + $customer-service__grid-gap;
        }

        .columns {
            display: grid;
            grid-template-columns: $customer-service-sidebar__width auto;
            grid-gap: $customer-service__grid-gap;
        }

        .column.main {
            order: 2;
        }

        .sidebar-additional {
            order: 1;
            padding: $customer-service-sidebar__padding;
        }
    }
}