.product-card {
    $self: &;

    &__button {
        display: none;
    }

    &__name {
        text-align: left;
    }

    &__shop {
        justify-content: flex-start;
        text-align: left;
    }

    &__visual {
        flex: 0 0 50%;
        position: relative;
    }

    .product-card-vertical #{$self}__info,
    &__info {
        flex: 1 0 50%;
    }

    &__label {
        background: $color--orange;
        color: $color--white;
        font-size: $font-size__l;
        font-weight: $font-weight__bold;
        left: -3rem;
        padding: 0 0 0.3rem 0;
        position: absolute;
        text-align: center;
        top: 1rem;
        transform: rotate(-45deg);
        width: 12rem;
        z-index: 1;
    }

    .product-image-wrapper {
        z-index: auto;
    }
}

@include min-screen($screen__m) {
    .product-card {
        &__name {
            text-align: center;
        }

        &__shop {
            justify-content: center;
            text-align: center;
        }

        &__label {
            font-size: $font-size__xxl;
            left: -4rem;
            padding: 0.2rem 0 0.6rem 0;
            top: 2rem;
            width: 16rem;
        }

        &__info {
            align-items: center;
        }
    }
}
