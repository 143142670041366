/** Most of the CSS is copied from default Magento **/

.swatch-attribute {
    margin-bottom: $swatch-attribute__margin-bottom;
}

.swatch-attribute-label {
    display: block;
    font-weight: $swatch-attribute-label__font-weight;
    margin-bottom: $swatch-attribute-label__margin-bottom;
    position: relative;
}

.swatch-attribute-options {
    display: flex;
    flex-wrap: wrap;
    outline: 0;
}

.swatch-attribute-selected-option {
    display: none;
}

.swatch-option {
    cursor: pointer;
    position: relative;
}

/** Text Swatch Options */
.swatch-option.text {
    background: $swatch-option-text__background;
    border: $swatch-option-text__border;
    border-radius: $swatch-option-text__border-radius;
    color: $swatch-option-text__color;
    margin: $swatch-option-text__margin;
    outline: none;
    padding: $swatch-option-text__padding;
    position: relative;

    &:first-child {
        margin-left: 0;
    }

    &:hover {
        border: $swatch-option-text-hover__border;
        color: $swatch-option-text-hover__color;
    }

    &.selected,
    &.selected:hover {
        background: $swatch-option-text-selected__background;
        border: $swatch-option-text-selected__border;
        color: $swatch-option-text-selected__color;
    }

    &.disabled,
    &.disabled:hover {
        border: $swatch-option-text__border;
        color: $swatch-option-text__color;
        cursor: default;
        opacity: $swatch-option-text-disabled__opacity;
    }

    &:before {
        background: $swatch-option-text-radio__background;
        border: $swatch-option-text-radio__border-width solid $swatch-option-text-radio__border-color;
        border-radius: $form-radio__border-radius;
        box-shadow: $swatch-option-text-radio__box-shadow;
        content: '';
        display: block;
        height: $swatch-option-text-radio__height;
        left: $swatch-option-text-radio__left;
        position: absolute;
        top: $swatch-option-text-radio__top;
        width: $swatch-option-text-radio__width;
    }

    &:after {
        background: $swatch-option-text-radio__background-color--hover;
        border-radius: $swatch-option-text-radio__border-radius--inner;
        content: '';
        display: block;
        height: $swatch-option-text-radio__height--inner;
        left: $swatch-option-text-radio__left--inner;
        position: absolute;
        top: $swatch-option-text-radio__top--inner;
        width: $swatch-option-text-radio__width--inner;
    }

    &:hover:before {
        border-color: $swatch-option-text-radio__border-color--hover;
    }

    &.selected:before {
        border-color: $swatch-option-text-radio__border-color--checked;
    }

    &.selected:after {
        background: $swatch-option-text-radio__background-color--checked;
    }
}

/** Size and Manufacturer */
.swatch-attribute.size,
.swatch-attribute.manufacturer {
    .swatch-option {
        background: rgb(240, 240, 240);
        color: rgb(148, 148, 148);
    }

    .swatch-option.selected {
        color: black;
        background: #fff;
        border: 1px solid #fff;
    }
}

/** Image and Color Swatch Options */
.swatch-option.image,
.swatch-option.color {
    border-radius: $swatch-option-visual__border-radius;
    float: left;
    height: 2.3rem;
    left: 0.3rem;
    margin: 0 1rem 0 0;
    outline: 0;
    top: 0.3rem;
    width: 2.3rem;

    &:not(.disabled):hover:after,
    &:not(.disabled):focus:after,
    &.selected:after {
        border: $swatch-option-visual__border--hover;
        border-radius: $swatch-option-visual__border-radius;
        content: '';
        display: block;
        height: 2.7rem;
        left: -0.3rem;
        position: absolute;
        top: -0.3rem;
        width: 2.7rem;
    }

    &.selected:not(.disabled):after,
    &.selected:not(.disabled):focus:after {
        border: $swatch-option-visual__border--selected;
    }
}

.swatch-option.disabled {
    cursor: default;
    opacity: 0.5;
}

.swatch-option-tooltip {
    display: none;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.product-options-wrapper .fieldset {
    outline: 0;
}
